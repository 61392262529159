/**
 * Function to send a configuration message to the Keyword Spotter backend
 * @param {*} client - client that is used for sending the message
 * @param {*} keyword - keyword that is to be detected
 * @param {*} threshold - threshold that is to be used for the keyword classification
 * @param {*} sampleRate - sample rate of the audio that is to be send to the backend
 */
const sendConfigToKWSClient = (client, keyword, threshold, sampleRate) => {
    const keywordToSend = [];

    try {
        const keywords = keyword.split(";");
        for (let i = 0; i < keywords.length; i++) {
            const word = keywords[i].split(".");
            if (word.length <= 1) throw new Error("invalid keyword");
            keywordToSend.push(word);
        }
    } catch (e) {
        /* eslint-disable no-console */
        console.log(e);

        throw new Error("toasts.invalidKeywords");
    }

    // get threshold
    const parsedThreshold = parseFloat(threshold);
    if (Number.isNaN(parsedThreshold)) {
        throw new Error("toasts.invalidThreshold");
    }

    // send config to backend
    // console.log(`using keyword: ${keywordToSend}`);
    // console.log(`using threshold: ${parsedThreshold}`);
    client.send(
        JSON.stringify({
            config: {
                samplerate: sampleRate,
                keywords: keywordToSend,
                threshold: parsedThreshold,
                return_transcript: true,
            },
        })
    );
};

/**
 * Function to connect to the Keyword Spotter websocket, consists of
 * event handlers for onopen, onclose, onmessage, onerror.
 *
 * @param {*} endpoint - websocket address
 * @param {*} keyword - keyword that is to be send in the configuration message
 * @param {*} threshold - threshold that is to be send in the configuration message
 * @param {*} sampleRate - sampleRate that is to be send in the configuration message
 * @param {*} resultProcessingFunction - callback function to propagate the backend response to
 *                                      the calling class
 * @param {*} onClose - function for messages from the Websocket in case of a closing event
 */
const connectKWSClient = (
    endpoint,
    keyword,
    threshold,
    sampleRate,
    resultProcessingFunction,
    onClose = () => {}
) => {
    const kwsClient = new WebSocket(endpoint);

    kwsClient.onmessage = (event) => {
        const msg = JSON.parse(event.data);
        resultProcessingFunction(msg);
    };

    kwsClient.onclose = () => {
        onClose();
        /* eslint-disable no-console */
        console.log("Closed connection to Keyword Spotter backend.");
    };

    return new Promise((resolve, reject) => {
        kwsClient.onopen = () => {
            sendConfigToKWSClient(kwsClient, keyword, threshold, sampleRate);
            /* eslint-disable no-console */
            console.log("Connected to Keyword Spotter client.");
            resolve(kwsClient);
        };

        kwsClient.onerror = (event) => {
            /* eslint-disable no-console */
            console.log(event);
            // WebSocket API does not provide more specific information
            // on the error, so display this generic error message:
            reject(event);
        };
    });
};

/**
 * Function to send PCM audio to the Keyword Spotter backend.
 *
 * @param {*} client - client that is used for sending the message
 * @param {*} data - PCM32 chunk that is to be send to the backend
 */
const sendPCMToKWSClient = (client, data) => {
    if (client != null && client.readyState === WebSocket.OPEN) {
        client.send(
            JSON.stringify({
                audio: {
                    samples: btoa(String.fromCharCode.apply(null, new Uint8Array(data.buffer))),
                },
            })
        );
    }
};

export { connectKWSClient, sendPCMToKWSClient };
