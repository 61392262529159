import { Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import PrimaryButton from "../../shared/components/PrimaryButton";

function AddButton({ text, onClick }) {
    return (
        <PrimaryButton onClick={onClick}>
            <Stack direction="row" gap={1}>
                <AddIcon />
                <Typography fontSize={16} fontWeight={500}>
                    {text}
                </Typography>
            </Stack>
        </PrimaryButton>
    );
}

AddButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default AddButton;
