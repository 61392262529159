import PropTypes from "prop-types";
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useTranslation } from "react-i18next";

function UploadFile({ fileUploadName, onUpload, isJSON, filenameKey }) {
    const { t } = useTranslation();
    const helperText = isJSON ? t("sr.jsonFileHint") : t("sr.audioFileHint");
    const placeHolder = isJSON ? t("sr.browseJsonFile") : t("sr.browseFile");
    return (
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item xs={8} sm={8}>
                <TextField
                    type="text"
                    fullWidth
                    disabled
                    value={fileUploadName}
                    helperText={helperText}
                    placeholder={placeHolder}
                />
            </Grid>
            <Grid item xs={4} sm={4}>
                <Button
                    sx={{
                        border: "none",
                        color: "rgba(51, 51, 51, 0.87)",
                        textTransform: "inherit",
                    }}
                    component="label"
                >
                    <Stack direction="row" gap={1}>
                        <FileUploadOutlinedIcon />
                        <Typography fontSize={16} fontWeight={500}>
                            {t("sr.importFile")}
                        </Typography>
                    </Stack>
                    <input type="file" onChange={onUpload} key={filenameKey} hidden />
                </Button>
            </Grid>
        </Grid>
    );
}

UploadFile.propTypes = {
    fileUploadName: PropTypes.string.isRequired,
    onUpload: PropTypes.func.isRequired,
    isJSON: PropTypes.bool.isRequired,
    filenameKey: PropTypes.number.isRequired,
};

export default UploadFile;
