import { Grid, Box, Stack, Typography } from "@mui/material";

import PropTypes from "prop-types";

/**
 * React functional component for displaying the components in a uniform format
 * @param {*} props
 * @returns JSX of a container that displays components uniformly
 */
function ComponentWrapper(props) {
    const { leftMainCol, rightMainCol, title, children } = props;

    return (
        <Box sx={{ marginLeft: "1%", marginRight: "1%" }}>
            <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">{title}</Typography>
                </Stack>

                {children}

                {/* div is VERY important here, otherwise the entire Grid will be indented and
                therefore not left-aligned with the surrounding content */}
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            {leftMainCol}
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            {rightMainCol}
                        </Grid>
                    </Grid>
                </div>
            </Stack>
        </Box>
    );
}

export default ComponentWrapper;

ComponentWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    leftMainCol: PropTypes.node,
    rightMainCol: PropTypes.node,
};

ComponentWrapper.defaultProps = {
    leftMainCol: null,
    rightMainCol: null,
};
