/* eslint-disable no-console */
import axios from "axios";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import keycloak from "../keycloak";
import { AudioParameters } from "../shared/constants";

// Function to generate the authorization headers with the token required to connect to the backend.
function generateAuthorizationHeaders() {
    const bearer = `Bearer ${keycloak.token}`;
    return {
        "Content-Type": "application/json",
        Authorization: bearer,
    };
}

/**
 * Function to make a TTS request and fetch the response audio url
 * @param {*} text - user input text to be sent to TTS
 * @param {*} language - selected language
 * @param {*} voice - selected voice
 * @param {*} speechRate - selected speed i.e speech rate for the response
 * @param {*} selectedEnv - selected environment where the request has to be made i.e prod, staging etc.
 * @returns a promise
 */
export function ttsRequest(text, language, voice, speechRate, selectedEnv, flavor, debug = false) {
    const endpoint = `https://${selectedEnv}${process.env.REACT_APP_TTS_URL}`;
    return `${endpoint}?${new URLSearchParams({
        text,
        flavor,
        language,
        voice,
        encoding: AudioParameters.WAV,
        samplerate: 22050,
        speech_rate: speechRate,
        access_token: keycloak.token,
        debug,
    })}`;
}

/**
 * Function to make a DM request and fetch the response text
 * @param {*} text - user input text to be sent to DM
 * @param {*} flavor - selected DM flavor to which the connection has to be made
 * @param {*} selectedEnv - selected environment where the request has to be made i.e prod, staging etc.
 * @param {*} tracker - string to keep track of the communication sequence
 * @returns a promise
 */
export async function dmRequest(text, flavor, selectedEnv, tracker = "", debug = false) {
    const headers = generateAuthorizationHeaders();

    const data = {
        dialogueId: flavor,
        tracker,
        text,
        debug,
    };

    const endpoint = `https://${selectedEnv}${process.env.REACT_APP_DM_URL}`;
    const response = await axios.post(endpoint, data, { headers });
    return response.data;
}

// Function to connect to the selected ASR flavor, return a websocket connection.
export function getASRWebsocketConnection(flavor, selectedEnv) {
    const endpoint = `wss://${selectedEnv}${process.env.REACT_APP_ASR_URL}`;
    return new W3CWebSocket(
        `${endpoint}?access_token=${keycloak.token}
		&service=${flavor}`
    );
}

/**
 * Create an HTTP request for the Speaker Recognition backend.
 * @param {*} config config message that is sent as first message to the backend.
 * @param {*} audioData audio whose classification/embedding is requested.
 * @param {*} endpoint endpoint url to connect to the SR backend.
 * @returns a promise
 */
export async function sendSRRequest(config, audioData, endpoint) {
    const headers = generateAuthorizationHeaders();
    const data = {
        config,
        audio: audioData,
    };

    const response = await axios.post(endpoint, data, { headers });
    return response.data;
}

/**
 * Function to get all the services across all the components (ASR, DM and TTS) for the given environment.
 * @param {*} env - selected environment to enumerate
 * @returns - a dictionary containing services from ASR, DM and TTS.
 */
export async function getServicesFromAllComponents(env) {
    const headers = generateAuthorizationHeaders();
    let asrServices = [];
    let dmServices = [];
    let ttsServices = [];

    // fetch ASR services
    try {
        const asrResponse = await axios.get(
            `https://${env.servicesEndpoint}${process.env.REACT_APP_ASR_ENUMERATE}`,
            { headers }
        );
        asrServices = asrResponse.data;
    } catch (e) {
        console.log("Error while fetching ASR services:", e);
        asrServices = [];
    }

    // fetch DM services
    try {
        const dmResponse = await axios.get(
            `https://${env.servicesEndpoint}${process.env.REACT_APP_DM_ENUMERATE}`,
            { headers }
        );
        dmServices = dmResponse.data;
    } catch (e) {
        console.log("Error while fetching DM services:", e);
        dmServices = [];
    }

    // fetch TTS services
    try {
        const ttsResponse = await axios.get(
            `https://${env.servicesEndpoint}${process.env.REACT_APP_TTS_ENUMERATE}`,
            { headers }
        );
        ttsServices = ttsResponse.data;
    } catch (e) {
        console.log("Error while fetching TTS services:", e);
        ttsServices = [];
    }
    return {
        asrServices,
        dmServices,
        ttsServices,
    };
}

// Function to get the deployments from DE
export async function getDEDeployments() {
    const headers = generateAuthorizationHeaders();
    const endpoint = `${process.env.REACT_APP_DE_URL}/deployment/`;
    let result = [];
    try {
        const response = await axios.get(endpoint, { headers });
        result = response.data;
    } catch (e) {
        console.log("Error while fetching DE deployments:", e);
    }
    return result;
}

// Function to get the user preferences stored in DE db
export async function fetchUserPreferences() {
    const headers = generateAuthorizationHeaders();
    const endpoint = `${process.env.REACT_APP_DE_URL}/user_preferences/`;
    let preferences = { avatar: "" };
    try {
        const response = await axios.get(endpoint, {
            headers,
        });
        preferences = response.data;
    } catch (e) {
        console.log("Error while fetching user preferences:", e);
    }
    return preferences;
}

// Function to update the newly selected avatar for the user to the DE db
export async function updateUserAvatarPreferences(userPreferences) {
    const headers = generateAuthorizationHeaders();
    const { avatar, language, username } = userPreferences;
    const endpoint = `${process.env.REACT_APP_DE_URL}/user_preferences/`;
    try {
        await axios.patch(
            endpoint,
            { avatar, language, username },
            {
                headers,
            }
        );
    } catch (e) {
        console.log("Error while updating user preferences:", e);
    }
}
