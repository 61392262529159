import React from "react";
import { withTranslation } from "react-i18next";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import * as actions from "../../store/action-creators";
import AudioController from "../../services/audio/audio-controller";
import getAudioStreamFromMicrophone from "../../services/audio/microphone";
import getRecordedBuffer from "../../services/audio/datastructure-handling";
import encodeAudioRecordingToAudioFormat from "../../services/audio/audio-encoder";
import {
    AudioParameters,
    carDashboardControls,
    envProps,
    componentServiceProps,
    pipelineOptions,
    AssistantState,
    DefaultEnvironmentName,
} from "../../shared/constants";
import LanguageDropdown from "../../shared/components/LanguageDropdown";
import { connectKWSClient, sendPCMToKWSClient } from "../../services/kws/keyword-spotter-client";
import Dashboard from "../../shared/components/Dashboard";
import keycloak from "../../keycloak";
import {
    getCommonLanguages,
    onServiceChange,
    isServicesUpdated,
    getLanguage,
    getVoice,
} from "../../services/service-handling";
import { isDashboardAllowed } from "../../services/dashboard";
import { saveRequests, saveResponses } from "../../services/history";
import AssistantMessenger from "./AssistantMessenger";
import { playJingle, updateDialogState } from "../../services/utils";
import { dmRequest, getASRWebsocketConnection, ttsRequest } from "../../services/requests";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import getEnrollmentsToAppend from "../../services/kws/kws-utils";
import KWSSettings from "./Settings/KWSSettings";
import ASRSettings from "./Settings/ASRSettings";
import TTSSettings from "./Settings/TTSSettings";
import DMSettings from "./Settings/DMSettings";
import { PipelineDropdown, PipelineVisuals } from "./Pipeline";
import NoValuesDropdown from "../../shared/components/NoValuesDropdown";
import CustomTooltip from "../../shared/components/CustomTooltip";
import TertiaryButton from "../../shared/components/TertiaryButton";
import ConfigurationDialog from "../../shared/components/ConfigurationDialog";

Object.freeze(AssistantState);

class Assistant extends React.Component {
    constructor(props) {
        super(props);

        const initEnv =
            props.accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0] ||
            props.accessibleEnvs[0];

        const initialASRService = props.asrServices?.[initEnv?.name]?.[0];
        const initialDMService = props.dmServices?.[initEnv?.name]?.[0];
        const initialTTSService = props.ttsServices?.[initEnv?.name]?.[0];
        const initialLanguages =
            initialASRService && initialTTSService
                ? getCommonLanguages(initialASRService, initialTTSService)
                : initialASRService?.languages || initialTTSService?.languages || [];

        // Assume we have only German and English languages
        const initVoice = initialTTSService?.voiceCollection?.find(
            (element) => element.language === initialLanguages[0]
        ).voices[0];

        const sampleKeyword = {
            name: "Sophia",
            audioUrls: ["-"], // pass audiourls as a hyphen to indicate there is no audio
            keyword: "S.OW.F.IY.AH;S.AH.F.IH.AH;S.AH. .F.IY.R;S.IH.F.Y.UW.R;S.AH. .V.IY.R",
            threshold: "0.00005",
        };

        const kwsEndpoints = this.getUpdatedKWSEndpoint(initEnv);

        this.state = {
            currentState: AssistantState.IDLE,
            asrSelectedService: initialASRService,
            dmSelectedService: initialDMService,
            ttsSelectedService: initialTTSService,
            commonLanguages: initialLanguages,
            selectedLanguage: initialLanguages[0] || "",
            selectedVoice: initVoice,

            messages: [],
            shouldShowDashboard: initialDMService ? isDashboardAllowed(initialDMService) : false,

            /* dashboardControls: each key has a list of 2 values :
            - control value
            - classname of CSS to highlight when there's a change
             */
            dashboardControls: carDashboardControls,
            audioRequests: [],
            audioResponses: [],
            kwsPort: "8888",
            kwsEndpoint: kwsEndpoints.kwsEndpoint,
            kwsEnrollEndpoint: kwsEndpoints.kwsEnrollEndpoint,
            tracker: "",
            speechRate: 1.0,
            sampleRate: 16000,
            // set the default env(from the .env file) as the selected env on load
            asrSelectedEnv: initEnv,
            dmSelectedEnv: initEnv,
            ttsSelectedEnv: initEnv,
            kwsSelectedEnv: initEnv,
            shouldShowSettings: false,
            configTable: [sampleKeyword],
            selectedKeyword: sampleKeyword,
            // dictionary to keep track of repeated keywords when importing config from a JSON file
            repeatedKeyCounter: {},
            selectedPipeline: pipelineOptions[0],
            showErrorToast: false,
            showInfoToast: false,
            errorToastMessage: "",
            infoToastMessage: "",
            // this is to disable the text field and the send button if there's no service
            shouldDisableInputControls: this.shouldDisableInputControlsOnLoad(
                initialASRService,
                initialDMService,
                initialTTSService,
                pipelineOptions[0]
            ),
        };
        this.ttsPlayer = null;
        this.asrClient = null;
        this.kwsClient = null;
        // counter used to update same ASR response bubble multiple times
        this.asrResponseCounter = 0;
        // audio vars
        this.numberOfChannels = AudioParameters.mono;
        this.bufferSize = 240;

        /* The array for the current audio recording consists of arrays that consist of an a
        number of Int16Array, where this number is equal to the number of recorded channels */
        this.currentAudioRecording = [];
        this.recordingLength = 0;

        this.messagesCounter = 0;

        const { sampleRate } = this.state;
        this.audioController = new AudioController(
            this.processPcmOutput,
            sampleRate,
            this.changeSampleRate,
            this.bufferSize,
            this.numberOfChannels
        );
    }

    componentDidMount() {
        const { asrSelectedService, dmSelectedService, ttsSelectedService } = this.state;
        // if no service is available then set defaults
        if (!asrSelectedService && !dmSelectedService && !ttsSelectedService)
            this.setState({
                asrSelectedService: {},
                dmSelectedService: {},
                ttsSelectedService: {},
                selectedLanguage: "",
                commonLanguages: [],
                selectedVoice: "",
            });
    }

    /**
     * On load of the component, store the services sent from the homepage
     * and filter the common languages available between ASR and TTS
     */
    componentDidUpdate(prevProps) {
        const { asrServices, ttsServices, dmServices, accessibleEnvs } = this.props;

        if (prevProps.accessibleEnvs !== accessibleEnvs) {
            this.setDefaultEnv();
        }

        if (isServicesUpdated(prevProps.asrServices, asrServices)) {
            this.initASRServices();
        }

        if (isServicesUpdated(prevProps.dmServices, dmServices)) {
            this.initDMServices();
        }
        if (isServicesUpdated(prevProps.ttsServices, ttsServices)) {
            this.initTTSServices();
        }
    }

    componentWillUnmount() {
        // close audio nodes
        this.stopAssistant();
        // fix to stop the error "Can't perform a React state update on an unmounted component."
        this.setState = () => {};
    }

    shouldDisableInputControlsOnLoad = (asrService, dmService, ttsService, selectedPipeline) => {
        const pipelineValue = selectedPipeline.value;
        if (pipelineValue.ASR && (!asrService || Object.keys(asrService).length === 0))
            return true;
        if (pipelineValue.DM && (!dmService || Object.keys(dmService).length === 0)) return true;
        if (pipelineValue.TTS && (!ttsService || Object.keys(ttsService).length === 0))
            return true;
        return false;
    };

    setDefaultEnv = () => {
        const { accessibleEnvs } = this.props;
        const selectedEnv =
            accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0] ||
            accessibleEnvs[0];
        const kwsEndpoints = this.getUpdatedKWSEndpoint(selectedEnv);
        this.setState({
            asrSelectedEnv: selectedEnv,
            dmSelectedEnv: selectedEnv,
            ttsSelectedEnv: selectedEnv,
            kwsSelectedEnv: selectedEnv,
            kwsEndpoint: kwsEndpoints.kwsEndpoint,
            kwsEnrollEndpoint: kwsEndpoints.kwsEnrollEndpoint,
        });
    };

    /** Start and prepare all audio recording, both for sending the audio to the backend.
     */
    startRecording = () => {
        getAudioStreamFromMicrophone()
            .then(this.establishConnections)
            .catch(() => {
                const { t } = this.props;
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("toasts.micPermission"),
                });
            });
    };

    /** Stop the Assistant, i.e. the audio processing, the Websocket Client,
     * and the KWS. */
    stopAssistant = () => {
        // stop the audioPlayer that play the TTS response
        if (this.ttsPlayer) this.ttsPlayer.pause();

        this.audioController.shutdown();

        // close Websocket adapter client
        if (this.asrClient) this.asrClient.close();
        // stop Keyword Spotter client
        if (this.kwsClient) this.kwsClient.close();

        this.setState({ currentState: AssistantState.IDLE });
    };

    resumeASR = async () => {
        // If the ASR WebSocket connection was closed, reestablish it
        // before resuming with audio transmission. This might happen
        // if the ASR connection is idle for too long.
        if (this.asrClient.readyState !== W3CWebSocket.OPEN) {
            await this.connectToASR();
        }

        this.setState({ currentState: AssistantState.RECORDING_ASR });
        this.audioController.resume();
    };

    /**
     * Checks if the received used audio message is the keyword.
     *
     * @param {String} msg - user audio message in text format
     */
    onKWSProcessed = (msg) => {
        if (msg.is_keyword) {
            const { t } = this.props;
            this.setState({
                showInfoToast: true,
                infoToastMessage: t("toasts.keywordSpotted"),
            });
            playJingle().then(this.resumeASR);
        }
    };

    /**
     * Function to update the selected ASR service and display a message to notify
     * the user about the change in service
     * @param {*} newASRService the newly selected ASR service
     */
    onASRServiceChange = (newASRService) => {
        const {
            currentState,
            messages,
            asrSelectedService,
            ttsSelectedService,
            selectedLanguage,
        } = this.state;
        const { t } = this.props;

        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidServiceChange"),
            });
        } else {
            const response = onServiceChange(
                messages,
                asrSelectedService,
                newASRService,
                this.messagesCounter++,
                "ASR"
            );

            // update common languages
            const commonLanguages = getCommonLanguages(newASRService, ttsSelectedService);

            this.setState({
                asrSelectedService: newASRService,
                messages: response,
                selectedLanguage: getLanguage(commonLanguages, selectedLanguage),
                commonLanguages,
            });
        }
    };

    /**
     * Function to update the selected DM service and display a message to notify
     * the user about the change in service
     * @param {*} newDmService the selected DM service
     */
    onDMServiceChange = (newDmService) => {
        const { currentState, messages, dmSelectedService } = this.state;
        const { t } = this.props;

        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidServiceChange"),
            });
        } else {
            const updatedMessages = onServiceChange(
                messages,
                dmSelectedService,
                newDmService,
                this.messagesCounter++,
                "DM"
            );

            // show dashboard if selected service namespace contains "demonstrator"
            const shouldShowDashboard = isDashboardAllowed(newDmService);

            this.setState({
                dmSelectedService: newDmService,
                messages: updatedMessages,
                tracker: "",
                shouldShowDashboard,
            });
        }
    };

    /**
     * Function to update the selected TTS service and display a message to notify
     * the user about the change in service
     * @param {*} newTTSService the newly selected TTS service
     */
    onTTSServiceChange = (newTTSService) => {
        const {
            currentState,
            messages,
            asrSelectedService,
            ttsSelectedService,
            selectedLanguage,
            selectedVoice,
        } = this.state;
        const { t } = this.props;

        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidServiceChange"),
            });
        } else {
            const response = onServiceChange(
                messages,
                ttsSelectedService,
                newTTSService,
                this.messagesCounter++,
                "TTS"
            );

            // update common languages
            const commonLanguages = getCommonLanguages(asrSelectedService, newTTSService);
            const newLanguage = getLanguage(commonLanguages, selectedLanguage);

            this.setState({
                ttsSelectedService: newTTSService,
                messages: response,
                selectedLanguage: newLanguage,
                commonLanguages,
                selectedVoice: getVoice(newTTSService, newLanguage, selectedVoice),
            });
        }
    };

    /**
     * Function to update the state with the selected language
     * It also updates the voice based on the newly selected language
     * @param {*} newLanguage - object with name and value of the selected language
     */
    onLanguageChange = (newLanguage) => {
        const { currentState, selectedVoice, ttsSelectedService } = this.state;
        const { t } = this.props;
        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidLanguageChange"),
            });
        } else {
            this.setState({
                selectedLanguage: newLanguage.value,
                selectedVoice: getVoice(ttsSelectedService, newLanguage.value, selectedVoice),
            });
        }
    };

    /**
     * Function to update the selected voice
     * @param {Number} newVoice the newly selected voice
     */
    onVoiceChange = (newVoice) => {
        const { currentState } = this.state;
        const { t } = this.props;
        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidVoiceChange"),
            });
        } else {
            this.setState({ selectedVoice: newVoice });
        }
    };

    /**
     * Function to update the state with the selected environment for ASR
     * @param {*} env - newly selected environment
     */
    onASREnvChange = (env) => {
        const { t, asrServices } = this.props;
        const {
            selectedLanguage,
            currentState,
            ttsSelectedService,
            asrSelectedService,
            selectedVoice,
            selectedPipeline,
            dmSelectedService,
        } = this.state;
        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidEnvChange"),
            });
        } else {
            const newServices = asrServices[env.name];
            let newAsrSelectedService = newServices?.[0] || {};

            if (newServices.length > 0) {
                newServices.forEach((service) => {
                    if (service.flavor === asrSelectedService?.flavor) {
                        newAsrSelectedService = service;
                    }
                });
            }

            if (Object.keys(newAsrSelectedService).length === 0)
                this.setState({
                    asrSelectedEnv: env,
                    asrSelectedService: newAsrSelectedService,
                    commonLanguages: [],
                    selectedLanguage: "",
                    showErrorToast: true, // set this to show the snackbar informing the user that there are no services
                    // available for the selected env
                    errorToastMessage: `ASR: ${t("toasts.noServicesFound", {
                        value: env?.name || "",
                    })}`,
                    shouldDisableInputControls: selectedPipeline.value.ASR, // if it's true then disable
                });
            else {
                const commonLanguages =
                    Object.keys(ttsSelectedService).length > 0
                        ? getCommonLanguages(newAsrSelectedService, ttsSelectedService)
                        : newAsrSelectedService.languages;
                const newLanguage = getLanguage(commonLanguages, selectedLanguage);
                this.setState({
                    asrSelectedEnv: env,
                    asrSelectedService: newAsrSelectedService,
                    commonLanguages,
                    selectedLanguage: newLanguage,
                    selectedVoice: getVoice(ttsSelectedService, newLanguage, selectedVoice),
                    showErrorToast: false,
                    shouldDisableInputControls: this.shouldDisableInputControlsOnLoad(
                        newAsrSelectedService,
                        dmSelectedService,
                        ttsSelectedService,
                        selectedPipeline
                    ),
                });
            }
        }
    };

    /**
     * Function to update the state with the selected environment for DM
     * @param {*} env - newly selected environment
     */
    onDMEnvChange = (env) => {
        const { t, dmServices } = this.props;
        const {
            currentState,
            dmSelectedService,
            selectedPipeline,
            asrSelectedService,
            ttsSelectedService,
        } = this.state;
        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidEnvChange"),
            });
        } else {
            // check if previously selected service's flavor exists in the new one
            const newServices = dmServices[env.name];
            let newDmSelectedService = newServices?.[0] || {};

            if (newServices.length > 0) {
                newServices.forEach((service) => {
                    if (service.flavor === dmSelectedService?.flavor) {
                        newDmSelectedService = service;
                    }
                });
            }

            if (Object.keys(newDmSelectedService).length === 0)
                this.setState({
                    dmSelectedEnv: env,
                    dmSelectedService: newDmSelectedService,
                    showErrorToast: true, // set this to show the snackbar informing the user that there are no services
                    // available for the selected env
                    errorToastMessage: `DM: ${t("toasts.noServicesFound", {
                        value: env?.name || "",
                    })}`,
                    shouldShowDashboard: false, // close dashboard, if open if there are no services
                    shouldDisableInputControls: selectedPipeline.value.DM, // if it's true then disable
                });
            else
                this.setState({
                    dmSelectedEnv: env,
                    dmSelectedService: newDmSelectedService,
                    showErrorToast: false,
                    shouldDisableInputControls: this.shouldDisableInputControlsOnLoad(
                        asrSelectedService,
                        newDmSelectedService,
                        ttsSelectedService,
                        selectedPipeline
                    ),
                });
        }
    };

    /**
     * Function to update the state with the selected environment for TTS
     * @param {*} env - newly selected environment
     */
    onTTSEnvChange = (env) => {
        const { t, ttsServices } = this.props;
        const {
            currentState,
            asrSelectedService,
            ttsSelectedService,
            selectedLanguage,
            selectedVoice,
            selectedPipeline,
            dmSelectedService,
        } = this.state;
        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidEnvChange"),
            });
        } else {
            // check if previously selected service's flavor exists in the new one
            const newServices = ttsServices[env.name];
            let newSelectedService = newServices?.[0] || {};

            if (newServices.length > 0) {
                newServices.forEach((service) => {
                    if (service.flavor === ttsSelectedService?.flavor) {
                        newSelectedService = service;
                    }
                });
            }

            if (Object.keys(newSelectedService).length === 0)
                this.setState({
                    ttsSelectedEnv: env,
                    ttsSelectedService: newSelectedService,
                    commonLanguages: [],
                    selectedLanguage: "",
                    showErrorToast: true, // set this to show the snackbar informing the user that there are no services
                    // available for the selected env
                    errorToastMessage: `TTS: ${t("toasts.noServicesFound", {
                        value: env?.name || "",
                    })}`,
                    shouldDisableInputControls: selectedPipeline.value.TTS, // if it's true then disable
                });
            else {
                const commonLanguages =
                    Object.keys(asrSelectedService).length > 0
                        ? getCommonLanguages(newSelectedService, asrSelectedService)
                        : newSelectedService.languages;

                const newSelectedLanguage = getLanguage(commonLanguages, selectedLanguage);

                this.setState({
                    ttsSelectedEnv: env,
                    ttsSelectedService: newSelectedService,
                    commonLanguages,
                    selectedLanguage: newSelectedLanguage,
                    selectedVoice: getVoice(
                        newSelectedService,
                        newSelectedLanguage,
                        selectedVoice
                    ),
                    showErrorToast: false,
                    shouldDisableInputControls: this.shouldDisableInputControlsOnLoad(
                        asrSelectedService,
                        dmSelectedService,
                        newSelectedService,
                        selectedPipeline
                    ),
                });
            }
        }
    };

    getUpdatedKWSEndpoint = (env) => {
        if (env)
            return {
                kwsEndpoint: `wss://${env.kwsEndpoint}${process.env.REACT_APP_KWS_URL}/spot?jwt=${keycloak.token}`,
                kwsEnrollEndpoint: `https://${env.kwsEndpoint}${process.env.REACT_APP_KWS_URL}/enroll`,
            };
        return {
            kwsEndpoint: "",
            kwsEnrollEndpoint: "",
        };
    };

    /**
     * Function to update the state with the selected environment for KWS
     * @param {*} env - newly selected environment
     */
    onKWSEnvChange = (env) => {
        const { t } = this.props;
        const { currentState } = this.state;
        if (currentState !== AssistantState.IDLE) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidEnvChange"),
            });
        } else {
            const newEndpoints = this.getUpdatedKWSEndpoint(env);
            this.setState({
                kwsSelectedEnv: env,
                kwsEndpoint: newEndpoints.kwsEndpoint,
                kwsEnrollEndpoint: newEndpoints.kwsEnrollEndpoint,
            });
        }
    };

    // Function to get the TTS response as a separate HTTP call.
    performTTS = (text, thenResumeRecording) => {
        const {
            ttsSelectedService,
            selectedLanguage,
            selectedVoice,
            speechRate,
            audioResponses,
            selectedPipeline,
            ttsSelectedEnv,
        } = this.state;
        const { t } = this.props;

        const ttsURL = ttsRequest(
            text,
            selectedLanguage,
            selectedVoice,
            speechRate,
            ttsSelectedEnv.servicesEndpoint,
            ttsSelectedService.flavor
        );

        // add URL to the response list immediately (instead of after playback)
        // save the url and user text to view/play it later
        const updatedResponses = saveResponses(ttsURL, text, audioResponses);
        this.setState({ audioResponses: updatedResponses });

        this.ttsPlayer = new Audio(ttsURL);

        // after playing the TTS response, restart the websocket connection
        // if stop is not clicked or if the component is not unmounted
        this.ttsPlayer.onended = () => {
            const { currentState } = this.state;

            // check if Assistant was not stopped by the user while playing the TTS response
            if (currentState === AssistantState.PLAYING_RESPONSE && thenResumeRecording) {
                if (selectedPipeline.value.KWS) this.resumeKWS();
                else this.resumeASR();
            } else {
                this.stopAssistant();
            }
        };

        this.ttsPlayer.onerror = (err) => {
            /* eslint-disable no-console */
            console.log(err);
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.ttsConnectError"),
            });
        };

        this.ttsPlayer.play();

        this.setState({ currentState: AssistantState.PLAYING_RESPONSE });
    };

    /**
     * Function to update the selected speech rate
     * @param {Float} value  - newly selected speech rate
     */
    setSpeechRate = (speechRate) => {
        this.setState({ speechRate });
    };

    // Function to send user message to DM server and get TTS response
    sendMessage = async (text) => {
        const msg = { sender: "user", text, id: this.messagesCounter++ };
        const { messages } = this.state;
        this.setState({ messages: [...messages, msg] });

        this.sendToDM(text);
    };

    updateKWSEndpoint = (endpoint) => {
        const { kwsPort, kwsSelectedEnv } = this.state;
        if (endpoint === "local")
            this.setState({
                kwsEndpoint: `ws://localhost:${kwsPort}/spot`,
                kwsEnrollEndpoint: `http://localhost:${kwsPort}/enroll`,
            });
        else {
            const newEndpoints = this.getUpdatedKWSEndpoint(kwsSelectedEnv);
            this.setState({
                kwsEndpoint: newEndpoints.kwsEndpoint,
                kwsEnrollEndpoint: newEndpoints.kwsEnrollEndpoint,
            });
        }
    };

    /**
     * Take the array of audio of the current recording, create an AudioBlob in PCM/WAV
     * from them and add the URL of the newly created AudioBlob to the list of recorded
     * audio requests.
     *
     * The recorded AudioBuffers are encoded in PCM16.
     */
    addRecordingAsAudioRequestEntry = (userAudioText) => {
        const { sampleRate, audioRequests } = this.state;
        const format = AudioParameters.WAV;
        const audioBlob = encodeAudioRecordingToAudioFormat(
            format,
            this.currentAudioRecording,
            this.recordingLength,
            this.numberOfChannels,
            this.bufferSize,
            sampleRate
        );
        this.currentAudioRecording = [];
        const audioURL = URL.createObjectURL(audioBlob);
        const updatedAudioRequests = saveRequests(audioURL, userAudioText, audioRequests);

        this.setState({ audioRequests: updatedAudioRequests });
    };

    /**
     * Function to setup KWS. Set up the audio nodes that are connected to the backend and
     * send the audio to the KWS and the ASR.
     *
     * @param {MediaStream} stream - refers to the user audio stream
     */
    establishConnections = async (stream) => {
        const { selectedPipeline } = this.state;
        const { t } = this.props;

        // set up audio nodes
        await this.audioController.initAudio(stream);
        this.currentAudioRecording = [];
        this.recordingLength = 0;
        this.micStream = stream;

        const asrConnected = this.connectToASR();

        if (selectedPipeline.value.KWS) {
            const { kwsEndpoint, selectedKeyword, sampleRate } = this.state;
            try {
                this.kwsClient = await connectKWSClient(
                    kwsEndpoint,
                    selectedKeyword.keyword,
                    selectedKeyword.threshold,
                    sampleRate,
                    this.onKWSProcessed
                );
                this.resumeKWS();
            } catch (err) {
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("toasts.kwsConnectError"),
                });
            }
        } else {
            asrConnected.then(this.resumeASR);
        }
    };

    resumeKWS = () => {
        const { t } = this.props;
        this.setState({
            showInfoToast: true,
            infoToastMessage: t("toasts.waitingForKeyword"),
        });

        this.setState({ currentState: AssistantState.WAITING_FOR_KEYWORD });
        this.audioController.resume();
    };

    /**
     * The output of the PCM processor is collected to be later on changed into an audio file
     * that can be listened to or downloaded by the users.
     *
     * The function adds an ArrayBuffer to an array of ArrayBuffers.
     * The new length of the collected audio is stored in a variable, that is incremented
     * by the size of the newly added buffer. The buffers can consist of several channels.
     *
     * @param {Int16Array} data - Int16Array containing an ArrayBuffer of PCM16 encoded audio
     */
    collectDataForAudioFile = (data) => {
        this.currentAudioRecording.push(getRecordedBuffer(data, this.numberOfChannels));
        this.recordingLength += this.bufferSize;
    };

    /**
     * Establishes the connection to the ASR WebSocket backend, using the ASR service
     * selected by the user. Registers event handlers to handle incoming messages
     * and connection issues.
     * @returns a Promise that resolves when the connection is ready and is rejected
     * if there is an error while connecting
     */
    connectToASR = () => {
        console.log("Connecting to ASR Websocket client");
        const { asrSelectedService, selectedLanguage, sampleRate, asrSelectedEnv } = this.state;
        const { t } = this.props;

        this.asrClient = getASRWebsocketConnection(
            asrSelectedService.flavor,
            asrSelectedEnv.servicesEndpoint
        );

        this.asrClient.onmessage = (message) => {
            const response = JSON.parse(message.data);

            this.processASRResponse(response);
        };

        this.asrClient.onclose = () => {
            /* eslint-disable no-console */
            console.log("Closing connection to ASR Websocket backend.");

            // check if connection was canceled by the server (for some reason)
            const { currentState } = this.state;
            if (currentState !== AssistantState.IDLE) {
                console.log(
                    "My connection to the ASR backend was closed unexpectedly.",
                    "This probably happened because it was idle for too long.",
                    "I will try to reconnect when I need the ASR again."
                );
            }
        };

        return new Promise((resolve, reject) => {
            this.asrClient.onopen = () => {
                const config = {
                    language: selectedLanguage,
                    intermediateResults: true,
                    multiple_utterances: true,
                    audioFormat: {
                        encoding: AudioParameters.PCM16,
                        samplerate: sampleRate,
                    },
                    debug: false,
                };
                this.asrClient.send(
                    JSON.stringify({
                        config,
                    })
                );
                /* eslint-disable no-console */
                console.log("Connected to ASR Websocket client");

                resolve();
            };

            this.asrClient.onerror = (err) => {
                // the API does not provide more specific information
                // on the error, so display a generic error message:
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("toasts.asrConnectError"),
                });
                /* eslint-disable no-console */
                console.log("Error connecting to server: ", err);

                reject();
            };
        });
    };

    processASRResponse = (asrResponseData) => {
        const { messages } = this.state;
        const copyOfMessages = [...messages];
        let msg = null;

        if (this.asrResponseCounter > 0) {
            // check if ASR response already added then just update
            // the same bubble
            msg = copyOfMessages.pop();
            msg.text = asrResponseData.text;
        } else {
            msg = {
                id: this.messagesCounter++,
                sender: "asrIntermediate",
                text: asrResponseData.text,
            };
        }
        this.asrResponseCounter++;
        copyOfMessages.push(msg);
        this.setState({ messages: copyOfMessages });

        // when responses from ASR are done, reset counter
        if (asrResponseData.utteranceFinished) {
            // pause recording
            this.audioController.pause();
            // add recorded audio as wav file
            // take the last response as user audio text
            this.addRecordingAsAudioRequestEntry(msg.text);

            // send utterance to DM
            this.sendToDM(msg.text);

            // if utterance finished, update the sender
            // so the message bubble color of the last message changes
            msg.sender = "asr";
            this.asrResponseCounter = 0;
        }
    };

    sendToDM = (text) => {
        const { t } = this.props;
        const { dmSelectedService, tracker, currentState, dmSelectedEnv } = this.state;
        const thenResumeRecording = currentState !== AssistantState.IDLE;

        // if de deployment exists then use that id else flavor
        const flavor = dmSelectedService.deDeploymentId || dmSelectedService.flavor;
        dmRequest(text, flavor, dmSelectedEnv.servicesEndpoint, tracker)
            .then((response) => this.processDMResponse(response, thenResumeRecording))
            .catch((err) => {
                console.log(err);
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("toasts.dmConnectError"),
                });
            });

        this.setState({ currentState: AssistantState.WAITING_FOR_DM_RESPONSE });
    };

    processDMResponse = (dmResponseData, thenResumeRecording) => {
        const { messages, dashboardControls, selectedPipeline } = this.state;

        const dmEvents = dmResponseData.events;

        const updatedDialogState = updateDialogState(
            dmEvents,
            messages,
            this.messagesCounter,
            dashboardControls
        );

        // update message counter
        this.messagesCounter += updatedDialogState.messages.length - messages.length;

        this.setState({
            ...updatedDialogState,
            tracker: dmResponseData.tracker,
        });

        if (selectedPipeline.value.TTS) {
            const responseTexts = dmEvents
                .filter((dmEvt) => dmEvt.text)
                .map((dmEvt) => dmEvt.text);
            const ttsResponseText = responseTexts.join(" ");
            this.performTTS(ttsResponseText, thenResumeRecording);
        } else if (thenResumeRecording) {
            if (selectedPipeline.value.KWS) this.resumeKWS();
            else this.resumeASR();
        } else {
            this.stopAssistant();
        }
    };

    /**
     * Function that processes the PCM processor output.
     * @param {MessageEvent} event - event that includes an array of the PCM data in two formats:
     *                      [PCM16, PCM32]. For both the ASR service and the creation of a audio file,
     *                      PCM16 is used. For the KWS service, PCM32 is used.
     */
    processPcmOutput = (event) => {
        const { currentState } = this.state;
        const pcm16Data = event.data[0];

        switch (currentState) {
            case AssistantState.WAITING_FOR_KEYWORD:
                sendPCMToKWSClient(this.kwsClient, event.data[1]);
                break;
            case AssistantState.RECORDING_ASR:
                this.asrClient.send(
                    JSON.stringify({
                        audio: btoa(
                            String.fromCharCode.apply(null, new Uint8Array(pcm16Data.buffer))
                        ),
                    })
                );
                // collect the audio for creating an audio file it later on
                this.collectDataForAudioFile(pcm16Data);
                break;
            default:
                console.log("Attempted to send audio in invalid state. this should not happen.");
        }
    };

    changeSampleRate = (sampleRate) => {
        this.setState({ sampleRate });
    };

    initASRServices = () => {
        const { asrSelectedEnv, ttsSelectedService, selectedPipeline } = this.state;
        const { asrServices } = this.props;
        if (asrSelectedEnv) {
            const initialASRService = asrServices[asrSelectedEnv.name]?.[0];
            if (initialASRService) {
                // if there is a TTS service available as well, get common
                // languages, otherwise use the ASR service languages
                const languages =
                    ttsSelectedService && Object.keys(ttsSelectedService).length > 0
                        ? getCommonLanguages(initialASRService, ttsSelectedService)
                        : initialASRService.languages;

                this.setState({
                    commonLanguages: languages,
                    asrSelectedService: initialASRService,
                    selectedLanguage: languages[0],
                    shouldDisableInputControls: false,
                });
            } else this.setState({ shouldDisableInputControls: selectedPipeline.value.ASR });
        }
    };

    initTTSServices = () => {
        const { ttsSelectedEnv, asrSelectedService, selectedPipeline } = this.state;
        const { ttsServices } = this.props;
        if (ttsSelectedEnv) {
            const initialTTSService = ttsServices[ttsSelectedEnv.name]?.[0];
            if (initialTTSService) {
                // if there is an ASR service available as well, get common
                // languages, otherwise use the TTS service languages
                const languages =
                    Object.keys(asrSelectedService).length > 0
                        ? getCommonLanguages(initialTTSService, asrSelectedService)
                        : initialTTSService.languages;

                const selectedVoice = initialTTSService.voiceCollection.find(
                    (e) => e.language === languages[0]
                ).voices[0];

                this.setState({
                    commonLanguages: languages,
                    ttsSelectedService: initialTTSService,
                    selectedLanguage: languages[0],
                    selectedVoice,
                });
            } else this.setState({ shouldDisableInputControls: selectedPipeline.value.TTS });
        }
    };

    initDMServices = () => {
        const { dmServices } = this.props;
        const { dmSelectedEnv, selectedPipeline } = this.state;
        if (dmSelectedEnv) {
            const initialDMService = dmServices[dmSelectedEnv.name]?.[0];
            if (initialDMService) {
                // set the initial state of shouldShowDashboard based on
                // the service selected by default on load
                const shouldShowDashboard = isDashboardAllowed(initialDMService);
                this.setState({
                    dmSelectedService: initialDMService,
                    shouldShowDashboard,
                });
            } else this.setState({ shouldDisableInputControls: selectedPipeline.value.DM });
        }
    };

    // add the keyword and its details to the table
    onKWSEnroll = (enrollment) => {
        const { configTable } = this.state;
        this.setState({ configTable: [...configTable, enrollment] });
    };

    // delete the keyword information from the table
    onKeywordDelete = (index) => {
        const { configTable } = this.state;
        const copyOfConfigTable = [...configTable];
        copyOfConfigTable.splice(index, 1);
        this.setState({ configTable: copyOfConfigTable });
    };

    toggleSettings = () => {
        const { shouldShowSettings } = this.state;
        this.setState({ shouldShowSettings: !shouldShowSettings });
    };

    onPipelineChange = (newPipeline) => {
        const { asrSelectedService, dmSelectedService, ttsSelectedService } = this.state;
        const shouldDisableInputControls =
            (newPipeline.value.ASR && Object.keys(asrSelectedService).length === 0) ||
            (newPipeline.value.DM && Object.keys(dmSelectedService).length === 0) ||
            (newPipeline.value.TTS && Object.keys(ttsSelectedService).length === 0);

        this.setState({
            selectedPipeline: newPipeline,
            shouldDisableInputControls,
        });
    };

    // Function to delete history and also all the saved audio and text in "view requests" and "view responses"
    handleDeleteHistory = () => {
        this.setState({ messages: [], audioRequests: [], audioResponses: [] });
    };

    /**
     * Function to append the keywords imported from the JSON file to the config table
     * @param {*} result - list of keywords imported from the JSON file
     */
    appendRowsToConfigTable = (result) => {
        const { configTable, repeatedKeyCounter } = this.state;
        try {
            const response = getEnrollmentsToAppend(result, configTable, repeatedKeyCounter);
            const configTableCopy = [...configTable];
            configTableCopy.push(...response.enrollments);
            this.setState({
                configTable: configTableCopy,
                repeatedKeyCounter: response.repeatedKeyCounter,
            });
        } catch (error) {
            const { t } = this.props;
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.importError"),
            });
            /* eslint-disable no-console */
            console.log(error);
        }
    };

    onKeywordImport = (event) => {
        try {
            const importedFile = event.target.files[0];
            const reader = new FileReader();

            reader.addEventListener("load", (e) => {
                const result = JSON.parse(e.target.result);
                this.appendRowsToConfigTable(result);
            });

            reader.readAsBinaryString(importedFile);
        } catch (error) {
            /* eslint-disable no-console */
            console.log(error);
        }
    };

    onKeywordChange = (keyword) => {
        this.setState({ selectedKeyword: keyword });
    };

    /**
     * Function to get the settings for KWS, ASR, DM and TTS
     */
    getSettings = () => {
        const {
            selectedVoice,
            asrSelectedService,
            dmSelectedService,
            ttsSelectedService,
            selectedLanguage,
            shouldShowSettings,
            currentState,
            asrSelectedEnv,
            dmSelectedEnv,
            ttsSelectedEnv,
            kwsSelectedEnv,
            commonLanguages,
            kwsEnrollEndpoint,
            configTable,
            selectedKeyword,
        } = this.state;
        const { t, asrServices, dmServices, ttsServices, accessibleEnvs } = this.props;

        const getVoices = () => {
            const tempVoiceCollection = ttsSelectedService?.voiceCollection?.find(
                (element) => element.language === selectedLanguage
            );
            return tempVoiceCollection?.voices || [];
        };

        const handleModalClose = (event, reason) => {
            if (reason && reason === "backdropClick") return;
            this.toggleSettings();
        };

        const getActions = () => {
            return <TertiaryButton text={t("buttons.close")} onClick={handleModalClose} />;
        };

        return shouldShowSettings ? (
            <ConfigurationDialog
                isConfigDialogOpen={shouldShowSettings}
                setConfigDialogOpen={handleModalClose}
                actions={getActions()}
                title={t("assistant.settings")}
            >
                <Stack gap={2}>
                    {/* Language dropdown */}
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            {selectedLanguage && commonLanguages.length > 0 ? (
                                <LanguageDropdown
                                    onLanguageChange={this.onLanguageChange}
                                    languages={commonLanguages}
                                    selectedLanguage={selectedLanguage}
                                    name="assistant_languages"
                                    shouldShowInfo
                                    title={t("main.assistantLanguage")}
                                />
                            ) : (
                                <NoValuesDropdown
                                    title={t("main.assistantLanguage")}
                                    value={t("main.noLanguages")}
                                    tooltip={t("assistant.assistantLanguageNote")}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <br />
                    <Typography fontWeight="bold">{t("main.configureAssistant")}</Typography>
                    <KWSSettings
                        accessibleEnvs={accessibleEnvs}
                        selectedEnv={kwsSelectedEnv}
                        onEnvChange={this.onKWSEnvChange}
                        onPortChanged={(port) => this.setState({ kwsPort: port })}
                        onEndpointSelected={this.updateKWSEndpoint}
                        enrollEndpoint={kwsEnrollEndpoint}
                        onEnroll={(enrollment) => this.onKWSEnroll(enrollment)}
                        onDelete={this.onKeywordDelete}
                        configTable={configTable}
                        onImport={this.onKeywordImport}
                        onKeywordChange={this.onKeywordChange}
                        selectedKeyword={selectedKeyword}
                    />
                    <ASRSettings
                        services={asrServices}
                        selectedService={asrSelectedService}
                        onServiceChange={this.onASRServiceChange}
                        accessibleEnvs={accessibleEnvs}
                        selectedEnv={asrSelectedEnv}
                        onEnvChange={this.onASREnvChange}
                    />
                    <DMSettings
                        services={dmServices}
                        selectedService={dmSelectedService}
                        onServiceChange={this.onDMServiceChange}
                        accessibleEnvs={accessibleEnvs}
                        selectedEnv={dmSelectedEnv}
                        onEnvChange={this.onDMEnvChange}
                    />
                    <TTSSettings
                        services={ttsServices}
                        selectedService={ttsSelectedService}
                        onServiceChange={this.onTTSServiceChange}
                        voices={getVoices()}
                        selectedVoice={selectedVoice}
                        onVoiceChange={this.onVoiceChange}
                        isStartDisabled={currentState !== AssistantState.IDLE}
                        setSpeechRate={this.setSpeechRate}
                        accessibleEnvs={accessibleEnvs}
                        selectedEnv={ttsSelectedEnv}
                        onEnvChange={this.onTTSEnvChange}
                    />
                </Stack>
            </ConfigurationDialog>
        ) : null;
    };

    render() {
        const { t } = this.props;
        const {
            asrSelectedService,
            dmSelectedService,
            ttsSelectedService,
            asrSelectedEnv,
            dmSelectedEnv,
            ttsSelectedEnv,
            kwsSelectedEnv,
            currentState,
            messages,
            shouldShowDashboard,
            dashboardControls,
            selectedPipeline,
            showErrorToast,
            showInfoToast,
            errorToastMessage,
            infoToastMessage,
            audioRequests,
            audioResponses,
            shouldDisableInputControls,
        } = this.state;
        const isStartDisabled = currentState !== AssistantState.IDLE;

        const messenger = (
            <AssistantMessenger
                sendMessage={this.sendMessage}
                messages={messages}
                isRecording={
                    currentState === AssistantState.RECORDING_ASR ||
                    currentState === AssistantState.WAITING_FOR_KEYWORD
                }
                isStartDisabled={isStartDisabled}
                localStream={this.micStream}
                handleStart={this.startRecording}
                handleStop={this.stopAssistant}
                selectedPipeline={selectedPipeline}
                audioRequests={audioRequests}
                audioResponses={audioResponses}
                shouldDisableInputControls={shouldDisableInputControls}
                handleDeleteHistory={this.handleDeleteHistory}
            />
        );
        const dashboard = shouldShowDashboard ? (
            <Dashboard dashboardControls={dashboardControls} />
        ) : null;

        return (
            <>
                <Box sx={{ marginLeft: "1%", marginRight: "1%" }}>
                    <Stack spacing={2}>
                        <Grid container>
                            <Grid item xs={12} sm={3}>
                                <Stack spacing={2} sx={{ paddingRight: "16px" }}>
                                    <Stack direction="row" gap={1}>
                                        <Typography variant="h4">Assistant</Typography>
                                        <CustomTooltip
                                            title={t("assistant.settings")}
                                            key="settings"
                                        >
                                            <IconButton
                                                aria-label="events"
                                                sx={{
                                                    padding: "8px 0px 0px 5px",
                                                }}
                                                onClick={this.toggleSettings}
                                                disabled={isStartDisabled}
                                            >
                                                <SettingsOutlinedIcon
                                                    sx={{
                                                        color: isStartDisabled ? "grey" : "teal",
                                                    }}
                                                />
                                            </IconButton>
                                        </CustomTooltip>
                                    </Stack>
                                    <PipelineDropdown
                                        selectedPipeline={selectedPipeline}
                                        onPipelineChange={this.onPipelineChange}
                                        isStartDisabled={currentState !== AssistantState.IDLE}
                                    />
                                </Stack>
                            </Grid>

                            <PipelineVisuals
                                onSettingsClick={this.toggleSettings}
                                isStartDisabled={currentState !== AssistantState.IDLE}
                                selectedPipeline={selectedPipeline}
                                currentState={currentState}
                                asrSelectedEnv={asrSelectedEnv?.name}
                                asrSelectedService={asrSelectedService?.flavor}
                                dmSelectedEnv={dmSelectedEnv?.name}
                                ttsSelectedEnv={ttsSelectedEnv?.name}
                                kwsSelectedEnv={kwsSelectedEnv?.name}
                                ttsSelectedService={ttsSelectedService?.flavor}
                                dmSelectedService={dmSelectedService?.flavor}
                            />
                        </Grid>
                        {/* div is VERY important here, otherwise the entire Grid will be indented and
                therefore not left-aligned with the surrounding content */}
                        {dashboard ? (
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                        {messenger}
                                    </Grid>
                                    <Grid item xs={12} sm={7}>
                                        {dashboard}
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={12} sm={5}>
                                    {messenger}
                                </Grid>
                            </Grid>
                        )}
                    </Stack>
                </Box>
                <CustomSnackbar
                    open={showErrorToast}
                    message={errorToastMessage}
                    handleClose={() => this.setState({ showErrorToast: false })}
                />
                <CustomSnackbar
                    open={showInfoToast}
                    message={infoToastMessage}
                    handleClose={() => this.setState({ showInfoToast: false })}
                    severity="info"
                />
                {this.getSettings()}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        asrServices: {
            // get a dictionary of ASR services across all the envs
            production: state.production.asrServices,
            staging: state.staging.asrServices,
            development: state.development.asrServices,
            collab: state.collab.asrServices,
            platform: state.platform.asrServices,
        },
        dmServices: {
            // get a dictionary of DM services across all the envs
            production: state.production.dmServices,
            staging: state.staging.dmServices,
            development: state.development.dmServices,
            collab: state.collab.dmServices,
            platform: state.platform.dmServices,
        },
        ttsServices: {
            // get a dictionary of TTS services across all the envs
            production: state.production.ttsServices,
            staging: state.staging.ttsServices,
            development: state.development.ttsServices,
            collab: state.collab.ttsServices,
            platform: state.platform.ttsServices,
        },
        accessibleEnvs: state.accessibleEnvs,
    };
}

export default compose(
    withTranslation("translation"),
    connect(mapStateToProps, actions)
)(Assistant);

Assistant.propTypes = {
    asrServices: componentServiceProps.isRequired,
    dmServices: componentServiceProps.isRequired,
    ttsServices: componentServiceProps.isRequired,
    t: PropTypes.func.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
};
