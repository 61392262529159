import React from "react";
import { withTranslation } from "react-i18next";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { Stack, Grid, Typography, IconButton, Divider, Box, Chip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import PropTypes from "prop-types";
import slugify from "react-slugify";
import { addNewAudioPlayer } from "../../shared/components/AudioPlayerList";
import "../../styles/appStyle.css";
import { getTime } from "../../services/utils";

import SecondaryButton from "../../shared/components/SecondaryButton";
import CustomTooltip from "../../shared/components/CustomTooltip";
import CustomAudioPlayerWithDownload from "../../shared/components/CustomAudioPlayerWithDownload";

/**
 * React functional component to display the configuration table
 * @param {*} props - consists data to be displayed in the table
 * @returns the JSX of the config table
 */
class KWSConfigurationTable extends React.Component {
    constructor() {
        super();
        this.state = {
            audioPlayerReferences: {},
        };
        this.fileName = "KWS-config-";
    }

    // add reference to all the audio urls present in the config table
    // This function is called whenever the table is updated.
    componentDidUpdate(prevProps) {
        const { configTable } = this.props;
        const { audioPlayerReferences } = this.state;
        if (prevProps.configTable !== configTable) {
            for (let i = 0; i < configTable.length; i++) {
                // check if the keyword has audio
                const urls = configTable[i].audioUrls;
                if (urls.length > 0 && urls[0] !== "-") {
                    // loop through the audio urls and add to references
                    for (let j = 0; j < urls.length; j++) {
                        if (!audioPlayerReferences[urls[j]]) {
                            this.addToAudioPlayerReferences(urls[j]);
                        }
                    }
                }
            }
        }
    }

    /**
     * Function to export the text content of the KWS config table as a JSON file
     */
    onExport = () => {
        const toBeExported = [];
        const { configTable } = this.props;
        // loop through list of enrolled keywords
        for (let keywordIndex = 0; keywordIndex < configTable.length; keywordIndex++) {
            // loop through the dictionary object containing info about each keyword
            // add everything except audioUrls
            const keywordInfo = {};
            Object.keys(configTable[keywordIndex]).forEach((key) => {
                if (key !== "audioUrls") {
                    keywordInfo[key] = configTable[keywordIndex][key];
                }
            });

            toBeExported.push(keywordInfo);
        }

        const data = JSON.stringify(toBeExported, null, "\t");
        const textBlob = new Blob([data], { type: "text/plain" });
        const url = URL.createObjectURL(textBlob);
        const link = document.createElement("a");
        link.href = url;
        const currentFileName = this.fileName + getTime();
        link.download = `${slugify(currentFileName.substring(0, 30))}.json`;
        link.click();
    };

    /**
     * Create a reference and add it to the audioPlayerReferences dictionary.
     * @param {*} newKey variable that is used as key when the new reference object is added to
     * the audioPlayerReferences dictionary.
     */
    addToAudioPlayerReferences = (newKey) => {
        this.setState((prevState) => {
            return {
                audioPlayerReferences: addNewAudioPlayer(prevState.audioPlayerReferences, newKey),
            };
        });
    };

    render() {
        const { t, configTable, onDelete } = this.props;

        return configTable?.length > 0 ? (
            <Stack gap={1}>
                <Stack direction="row" gap={1} alignItems="center">
                    <CheckCircleOutlineIcon />
                    <Typography fontSize={20} fontWeight={500}>
                        {t("main.overview")}
                    </Typography>
                </Stack>
                {/* Grid header */}
                <Grid container>
                    <Grid item xs={2} sm={2} pt="9px">
                        <Typography fontWeight="bold" fontSize={14}>
                            {t("kws.name")}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} pt="9px">
                        <Stack direction="row" gap={0.5}>
                            <MusicNoteOutlinedIcon />
                            <Typography fontWeight="bold" fontSize={14}>
                                {t("kws.audio")}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={4} pt="9px">
                        <Typography fontWeight="bold" fontSize={14}>
                            {t("kws.keyword")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} pt="9px">
                        <Typography fontWeight="bold" fontSize={14}>
                            {t("kws.threshold")}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} />
                    {/*  */}
                </Grid>
                <Divider />
                {/* Grid content */}
                {configTable.length > 0 ? (
                    <Box sx={{ overflowY: "scroll", maxHeight: "300px" }}>
                        {configTable.map((row, index) => (
                            <Stack key={row.name}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} pt="9px">
                                        {index === 0 ? (
                                            <div>
                                                {row.name} <br />
                                                (default)
                                            </div>
                                        ) : (
                                            <CustomTooltip title={row.name} enableEllipsis>
                                                {row.name}
                                            </CustomTooltip>
                                        )}
                                    </Grid>
                                    <Grid item xs={2} sm={2} pt="9px">
                                        {row.audioUrls.map((url) =>
                                            // if audiourls is a hyphen then there is no audio
                                            url === "-" ? (
                                                "(none)"
                                            ) : (
                                                <Stack key={url} direction="row" gap={2}>
                                                    <CustomAudioPlayerWithDownload
                                                        audioUrls={configTable[index].audioUrls}
                                                        name={configTable[index].name}
                                                    />
                                                </Stack>
                                            )
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sm={4} pt="9px">
                                        {row.keyword.split(";").map((item) => (
                                            <Chip sx={{ margin: "2px" }} label={item} key={item} />
                                        ))}
                                    </Grid>
                                    <Grid item xs={2} sm={2} pt="9px" sx={{ marginLeft: "16px" }}>
                                        <CustomTooltip title={row.threshold} enableEllipsis>
                                            {row.threshold}
                                        </CustomTooltip>
                                    </Grid>
                                    <Grid item xs={1} sm={1}>
                                        {/* Sophia at index=0 should not be deleted */}
                                        {index === 0 ? (
                                            <IconButton disabled>
                                                <DeleteOutline />
                                            </IconButton>
                                        ) : (
                                            <IconButton onClick={() => onDelete(index)}>
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Stack>
                        ))}
                    </Box>
                ) : (
                    <Stack>
                        <Grid container>
                            <Grid item xs={1} sm={1}>
                                {/*  */}
                            </Grid>
                            <Grid item>
                                <Typography fontSize={14} sx={{ marginBottom: "8px" }}>
                                    NO KWS
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Stack>
                )}

                <Stack direction="row" justifyContent="end" spacing={1} marginTop="24px">
                    <SecondaryButton onClick={this.onExport}>
                        {t("buttons.exportAll")}
                    </SecondaryButton>
                </Stack>
                <Box
                    display="flex"
                    justifyContent="center"
                    margin="28px"
                    marginLeft="0px"
                    color="rgba(0, 100, 73, 1)"
                >
                    <Stack gap={1} direction="row">
                        <TipsAndUpdatesOutlinedIcon sx={{ color: "rgba(0, 100, 73, 1)" }} />
                        <Typography fontWeight={400} fontSize={14}>
                            {t("kws.tableWarning")}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        ) : null;
    }
}

export default withTranslation()(KWSConfigurationTable);

KWSConfigurationTable.propTypes = {
    t: PropTypes.func.isRequired,
    configTable: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            audioUrls: PropTypes.arrayOf(PropTypes.string),
            keyword: PropTypes.string,
            threshold: PropTypes.string,
        })
    ).isRequired,

    onDelete: PropTypes.func.isRequired,
};
