import * as React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import { useTranslation } from "react-i18next";
import CustomTooltip from "./CustomTooltip";
import TertiaryButton from "./TertiaryButton";
import ConfigurationDialog from "./ConfigurationDialog";

function SimpleButtonDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { buttonContent, buttonDisabled, children, title, isRequest } = props;

    const { t } = useTranslation();

    const getActions = () => {
        return <TertiaryButton text={t("buttons.close")} onClick={handleClose} />;
    };

    return (
        <>
            <CustomTooltip title={buttonContent}>
                <IconButton
                    aria-label="expert"
                    sx={{
                        padding: "0px 5px 0px 5px",
                    }}
                    onClick={handleClickOpen}
                    disabled={buttonDisabled}
                >
                    {isRequest ? (
                        <RecordVoiceOverOutlinedIcon
                            sx={{ color: buttonDisabled ? "grey" : "teal" }}
                        />
                    ) : (
                        <VolumeUpOutlinedIcon sx={{ color: buttonDisabled ? "grey" : "teal" }} />
                    )}
                </IconButton>
            </CustomTooltip>
            <ConfigurationDialog
                isConfigDialogOpen={open}
                setConfigDialogOpen={setOpen}
                actions={getActions()}
                title={title}
            >
                {children}
            </ConfigurationDialog>
        </>
    );
}

SimpleButtonDialog.propTypes = {
    buttonContent: PropTypes.node.isRequired,
    buttonDisabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    isRequest: PropTypes.bool, // to check if it is request or response
};

SimpleButtonDialog.defaultProps = {
    buttonDisabled: false,
    isRequest: false,
};

export default SimpleButtonDialog;
