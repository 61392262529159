import React from "react";
import PropTypes from "prop-types";
import * as Scroll from "react-scroll";

import Message from "./Message";
import "../../styles/appStyle.css";

const messageListStyle = {
    overflowY: "auto",
    height: "300px",
};

/**
 * This React Component renders the messages sent by the user and/or the assistant
 */
class MessageList extends React.Component {
    constructor(props) {
        super(props);
        this.scroll = Scroll.animateScroll;
    }

    componentDidUpdate(prevProps) {
        const { messages, id } = this.props;
        if (prevProps.messages !== messages)
            this.scroll.scrollToBottom({
                containerId: id,
            });
    }

    render() {
        const { messages, id } = this.props;
        return (
            <div id={id} style={messageListStyle}>
                {messages.map((message) => (
                    <div key={message.id}>
                        <Message message={message} />
                    </div>
                ))}
            </div>
        );
    }
}

export default MessageList;

MessageList.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.shape({
                sender: PropTypes.string,
                text: PropTypes.string,
            }),
        })
    ).isRequired,
    id: PropTypes.string,
};

MessageList.defaultProps = {
    id: "messageList",
};
