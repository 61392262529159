// TODO: move the rest of the audio processing functions from HelperFunctions.js to here
import { AudioParameters } from "../../shared/constants";

/**
 * Function to change an Int16Array into an Array of Int16Array, where each In16Array represents
 * one channel. This function is necessary to be able to concatenate the audio into one AudioBuffer.
 *
 * @param {Int16Array} data - array of recorded audio
 * @param {Number} numberOfChannels - number of audio channels
 * @returns {Array} recordedBuffers - array of Int16Arrays, where each Int16Array represents an
 *      audio channel
 */
function getRecordedBuffer(data, numberOfChannels) {
    if (numberOfChannels !== AudioParameters.mono)
        /* eslint-disable no-console */
        console.log(
            "Error: This method is currently implemented only considering input of one audio channel"
        );
    const recordedBuffers = [];
    for (let ch = 0; ch < numberOfChannels; ++ch) {
        recordedBuffers[ch] = data;
    }
    return recordedBuffers;
}

export default getRecordedBuffer;
