import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import NameInput from "./NameInput";
import VoiceRecorder from "./VoiceRecorder";

function RecordAudio({ name, onNameChange, onRecordAudio, label, audioLengthHint }) {
    const { t } = useTranslation();

    const saveEncodedAudio = (result) => {
        onRecordAudio({
            blob: result.value,
            audioUrl: URL.createObjectURL(result.value),
        });
    };

    return (
        <Stack gap={2}>
            <Typography fontWeight={500} fontSize={20}>
                {t("sr.recordAudioFile")}
            </Typography>
            <NameInput onChange={onNameChange} value={name} label={label} id="enrollName" />
            <VoiceRecorder audioLengthHint={audioLengthHint} saveEncodedAudio={saveEncodedAudio} />
        </Stack>
    );
}

RecordAudio.propTypes = {
    onNameChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onRecordAudio: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    audioLengthHint: PropTypes.string.isRequired,
};

export default RecordAudio;
