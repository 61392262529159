const tooltipStyle = {
    "& .MuiTooltip-tooltip": {
        backgroundColor: "black",
        color: "white",
        padding: "1rem",
        fontSize: "1rem",
    },
};

export default tooltipStyle;
