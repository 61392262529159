import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import MuiList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import LabeledCheckboxWithTooltip from "./LabeledCheckbox";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

/**
 * React functional component to display a confirmation alert to the user.
 * @param {*} props - consists of the text to be displayed and the callback function
 * to be called when user clicks yes and the alert closes.
 * On no, the alert just closes.
 * If "Don't ask again" is checked then this confirmation will not be shown again.
 * @returns
 */
function ConfirmAlert(props) {
    const { open, text, isDontAskAgainChecked, toggleDontAskAgain, onYes, onClose } = props;
    const { t } = useTranslation();
    const List = styled(MuiList)(() => ({
        "& .MuiSvgIcon-root": {
            color: "#ffa501",
            width: "2rem",
            height: "2rem",
        },
        "& .MuiListItemText-primary": {
            fontSize: "1.2rem",
        },
    }));
    return (
        <Dialog
            sx={{
                "& .MuiPaper-root": {
                    boxShadow: "none",
                    padding: "1%",
                },
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: "0px" }}>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <WarningIcon />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                </List>
            </DialogTitle>
            <DialogContent>
                <LabeledCheckboxWithTooltip
                    tooltipText=""
                    value={isDontAskAgainChecked}
                    onChange={() => toggleDontAskAgain()}
                    id="dontAskAgain"
                    checked={isDontAskAgainChecked}
                    label={t("main.dontAskAgain")}
                />
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    name="confirmYes"
                    onClick={() => {
                        onYes();
                        onClose();
                    }}
                >
                    Yes
                </PrimaryButton>
                <SecondaryButton
                    name="confirmNo"
                    onClick={() => {
                        onClose();
                    }}
                >
                    No
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmAlert;

ConfirmAlert.propTypes = {
    // required callback function when user clicks yes
    onYes: PropTypes.func.isRequired,
    // required callback function to close the alert
    onClose: PropTypes.func.isRequired,
    isDontAskAgainChecked: PropTypes.bool,
    toggleDontAskAgain: PropTypes.func.isRequired,
    text: PropTypes.string,
    open: PropTypes.bool.isRequired,
};

ConfirmAlert.defaultProps = {
    text: "Are you sure?",
    isDontAskAgainChecked: false,
};
