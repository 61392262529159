import PropTypes from "prop-types";
import Button from "@mui/material/Button";

/**
 * React functional component that displays a button that performs the primary action
 * i.e the action that allows the user to accomplish their most common or most
 * important goal of the given module */
function PrimaryButton(props) {
    const { children, isErrorButton, onClick, disabled, endIcon } = props;
    const buttonBgColor = isErrorButton ? "#D4000D" : "#26a69a";

    return (
        <Button
            variant="contained"
            onClick={onClick}
            disabled={disabled}
            endIcon={endIcon}
            sx={{
                borderRadius: 0,
                fontWeight: 500,
                fontSize: "14px",
                textTransform: "inherit",
                backgroundColor: buttonBgColor,
                "&:hover": {
                    backgroundColor: buttonBgColor,
                },
                color: "#FFFFFF",
            }}
        >
            {children}
        </Button>
    );
}

export default PrimaryButton;

PrimaryButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    isErrorButton: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    endIcon: PropTypes.element,
};

PrimaryButton.defaultProps = {
    isErrorButton: false,
    disabled: false,
    endIcon: null,
};
