import { Box, Checkbox, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfigurationDialog from "../../shared/components/ConfigurationDialog";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import PrimaryButton from "../../shared/components/PrimaryButton";
import TertiaryButton from "../../shared/components/TertiaryButton";
import { speakerProps } from "../../shared/constants";

function SpeakerClassification({
    speakers,
    showSpeakerClassification,
    onCancelClick,
    handleOnClassifySpeaker,
}) {
    const speakersCopy = [];
    speakers.forEach((s) => speakersCopy.push(JSON.parse(JSON.stringify(s))));

    const [showErrorToast, setShowErrorToast] = useState(false);
    const { t } = useTranslation();

    const [localSpeakers, setLocalSpeakers] = useState(speakersCopy);
    const [counters, setCounters] = useState({
        areAllSpeakersSelected: false,
        areSomeSpeakersSelected: false,
        areNoSpeakersSelected: true,
    });

    const getSelectedSpeakers = () => {
        return localSpeakers.filter((s) => s.selected);
    };

    const updateCounters = () => {
        const selectedSpeakers = getSelectedSpeakers();
        const areAllSpeakersSelected =
            localSpeakers.length > 0 && selectedSpeakers.length === localSpeakers.length;
        const areSomeSpeakersSelected =
            selectedSpeakers.length > 0 && selectedSpeakers.length < localSpeakers.length;
        const areNoSpeakersSelected = selectedSpeakers.length === 0;
        setCounters({ areAllSpeakersSelected, areSomeSpeakersSelected, areNoSpeakersSelected });
    };

    const handleSelectAllSpeakers = (checked) => {
        localSpeakers.forEach((speaker) => {
            // eslint-disable-next-line no-param-reassign
            speaker.selected = checked;
        });
        setLocalSpeakers(localSpeakers);
        updateCounters();
    };

    const onClassifySpeaker = () => {
        const selectedSpeakers = getSelectedSpeakers();
        if (selectedSpeakers.length === 0) {
            setShowErrorToast(true);
        } else {
            setShowErrorToast(false);
            handleOnClassifySpeaker(selectedSpeakers);
            onCancelClick();
        }
    };

    const handleHeaderCheckboxClick = (checked) => {
        handleSelectAllSpeakers(checked);
    };

    const handleSelectSingleSpeaker = (event, index) => {
        localSpeakers[index].selected = event.target.checked;
        setLocalSpeakers(localSpeakers);
        updateCounters();
    };

    const getActions = () => {
        return (
            <>
                <TertiaryButton text={t("buttons.cancel")} onClick={onCancelClick} />
                <PrimaryButton onClick={onClassifySpeaker}>
                    {t("sr.classifySpeaker")}
                </PrimaryButton>
            </>
        );
    };

    return (
        <>
            <ConfigurationDialog
                actions={getActions()}
                isConfigDialogOpen={showSpeakerClassification}
                setConfigDialogOpen={onCancelClick}
                title={t("sr.speakerClassification")}
            >
                <Box sx={{ width: "100%" }}>
                    <Stack gap={2}>
                        <Typography fontSize={16} fontWeight="bold">
                            {t("sr.chooseSpeaker")}
                        </Typography>
                        <Grid container>
                            <Grid item xs={1} sm={1}>
                                {counters.areAllSpeakersSelected ? (
                                    <Checkbox
                                        checked
                                        onChange={(e) =>
                                            handleHeaderCheckboxClick(e.target.checked)
                                        }
                                    />
                                ) : null}
                                {counters.areSomeSpeakersSelected ? (
                                    <IconButton onClick={() => handleHeaderCheckboxClick(true)}>
                                        <IndeterminateCheckBoxIcon sx={{ color: "#4fc4a2" }} />
                                    </IconButton>
                                ) : null}
                                {counters.areNoSpeakersSelected ? (
                                    <Checkbox
                                        checked={false}
                                        onChange={(e) =>
                                            handleHeaderCheckboxClick(e.target.checked)
                                        }
                                    />
                                ) : null}
                            </Grid>
                            <Grid item xs={2} sm={2} pt="9px">
                                <Typography fontWeight="bold" fontSize={14}>
                                    Name
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Box sx={{ overflowY: "scroll", maxHeight: "300px" }}>
                            {localSpeakers.map((speaker, index) => (
                                <Stack key={speaker.id}>
                                    <Grid container>
                                        <Grid item xs={1} sm={1}>
                                            <Checkbox
                                                checked={speaker.selected}
                                                onChange={(event) =>
                                                    handleSelectSingleSpeaker(event, index)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2} pt="9px">
                                            {speaker.name}
                                        </Grid>
                                    </Grid>
                                </Stack>
                            ))}
                        </Box>
                    </Stack>
                </Box>
            </ConfigurationDialog>
            <CustomSnackbar
                open={showErrorToast}
                message={t("toasts.speakerForClassification")}
                handleClose={() => setShowErrorToast(false)}
                severity="error"
            />
        </>
    );
}

SpeakerClassification.propTypes = {
    speakers: PropTypes.arrayOf(speakerProps).isRequired,
    showSpeakerClassification: PropTypes.bool.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    handleOnClassifySpeaker: PropTypes.func.isRequired,
};

export default SpeakerClassification;
