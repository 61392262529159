import { Grid, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import "../../styles/appStyle.css";
import CardPanel from "./CardPanel";
import { ReactComponent as AC } from "../../assets/dashboard/imgs/AC.svg";
import { ReactComponent as InteriorLighting } from "../../assets/dashboard/imgs/interior_light.svg";
import { ReactComponent as Temperature } from "../../assets/dashboard/imgs/temperature.svg";
import { ReactComponent as FrontWindow } from "../../assets/dashboard/imgs/frontwindow.svg";
import { ReactComponent as BackWindow } from "../../assets/dashboard/imgs/backwindow.svg";
import { ReactComponent as Lowbeam } from "../../assets/dashboard/imgs/lowbeam.svg";
import { ReactComponent as Highbeam } from "../../assets/dashboard/imgs/highbeam.svg";
import { ReactComponent as Parking } from "../../assets/dashboard/imgs/parking.svg";
import { ReactComponent as Sunroof } from "../../assets/dashboard/imgs/sunroof.svg";
import { ReactComponent as Warning } from "../../assets/dashboard/imgs/warning.svg";
import { ReactComponent as WipersBack } from "../../assets/dashboard/imgs/wipers_back.svg";
import { ReactComponent as WipersFront } from "../../assets/dashboard/imgs/wipers_front.svg";

import ACGif from "../../assets/dashboard/gifs/AC.gif";
import InteriorLightingGif from "../../assets/dashboard/gifs/interior_light.gif";
import TemperatureGif from "../../assets/dashboard/gifs/temperature.gif";
import FrontWindowGif from "../../assets/dashboard/gifs/frontwindow.gif";
import BackWindowGif from "../../assets/dashboard/gifs/backwindow.gif";
import LowbeamGif from "../../assets/dashboard/gifs/lowbeam.gif";
import HighbeamGif from "../../assets/dashboard/gifs/highbeam.gif";
import ParkingGif from "../../assets/dashboard/gifs/parking.gif";
import SunroofGif from "../../assets/dashboard/gifs/sunroof.gif";
import WarningGif from "../../assets/dashboard/gifs/warning.gif";
import WipersBackGif from "../../assets/dashboard/gifs/wipers_back.gif";
import WipersFrontGif from "../../assets/dashboard/gifs/wipers_front.gif";

/**
 * This React Component displays the Dashboard with car functionalities.
 * @param {dictionary} dashboardControls - Contains the car devices and its values
 */
function Dashboard(props) {
    const { dashboardControls } = props;
    const { t } = useTranslation();
    const imgStyle = { width: 60, height: 60 };

    return (
        <CardPanel title="Car Dashboard" shouldHideDelete>
            {/* first row names */}
            <Grid container>
                <Grid item sm={2} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.window_frontleft", {
                            value: dashboardControls.window_frontleft.value,
                        })}
                    </Typography>
                </Grid>
                <Grid item sm={2} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.window_frontright", {
                            value: dashboardControls.window_frontright.value,
                        })}
                    </Typography>
                </Grid>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.window_backleft", {
                            value: dashboardControls.window_backleft.value,
                        })}
                    </Typography>
                </Grid>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.window_backright", {
                            value: dashboardControls.window_backright.value,
                        })}
                    </Typography>
                </Grid>
                <Grid item sm={2} pt={1}>
                    <Typography textAlign="center">
                        {" "}
                        {t("carFunctions.sunroof", {
                            value: dashboardControls.sunroof.value,
                        })}
                    </Typography>
                </Grid>
            </Grid>
            {/* first row icons */}
            <Grid container>
                <Grid item sm={2} pb={2}>
                    <Stack
                        alignItems="center"
                        className={dashboardControls.window_frontleft.className}
                    >
                        {dashboardControls.window_frontleft.value === 0 ? (
                            <SvgIcon
                                sx={{ ...imgStyle, transform: "scale(-1, 1)", color: "#6097A0" }}
                                component={FrontWindow}
                            />
                        ) : (
                            <img
                                style={{ ...imgStyle, transform: "scale(-1, 1)" }}
                                src={FrontWindowGif}
                                alt="Front Window Open"
                            />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={2} pb={2}>
                    <Stack
                        alignItems="center"
                        className={dashboardControls.window_frontright.className}
                    >
                        {dashboardControls.window_frontright.value === 0 ? (
                            <SvgIcon
                                sx={{ ...imgStyle, color: "#6097A0" }}
                                component={FrontWindow}
                            />
                        ) : (
                            <img style={imgStyle} src={FrontWindowGif} alt="Front Window Open" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={3} pb={2}>
                    <Stack
                        alignItems="center"
                        className={dashboardControls.window_backleft.className}
                    >
                        {dashboardControls.window_backleft.value === 0 ? (
                            <SvgIcon
                                sx={{ ...imgStyle, transform: "scale(-1, 1)", color: "#6097A0" }}
                                component={BackWindow}
                            />
                        ) : (
                            <img
                                style={{ ...imgStyle, transform: "scale(-1, 1)" }}
                                src={BackWindowGif}
                                alt="Back Window Open"
                            />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={3} pb={2}>
                    <Stack
                        alignItems="center"
                        className={dashboardControls.window_backright.className}
                    >
                        {dashboardControls.window_backright.value === 0 ? (
                            <SvgIcon
                                sx={{ ...imgStyle, color: "#6097A0" }}
                                component={BackWindow}
                            />
                        ) : (
                            <img style={imgStyle} src={BackWindowGif} alt="Back Window Open" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={2} pb={2}>
                    <Stack alignItems="center" className={dashboardControls.sunroof.className}>
                        {dashboardControls.sunroof.value === 0 ? (
                            <SvgIcon sx={{ ...imgStyle, color: "#6097A0" }} component={Sunroof} />
                        ) : (
                            <img style={imgStyle} src={SunroofGif} alt="Sunroof Open" />
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Divider />
            {/* second row names */}
            <Grid container>
                <Grid item sm={2} pt={1}>
                    <Typography textAlign="center">{t("carFunctions.highbeam")}</Typography>
                </Grid>
                <Grid item sm={2} pt={1}>
                    <Typography textAlign="center">{t("carFunctions.lowbeam")}</Typography>
                </Grid>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.hazardwarninglight")}
                    </Typography>
                </Grid>
                <Grid item sm={2} pt={1}>
                    <Typography textAlign="center">{t("carFunctions.parkinglights")}</Typography>
                </Grid>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.interiorlighting")}
                    </Typography>
                </Grid>
            </Grid>
            {/* second row icons */}
            <Grid container>
                <Grid item sm={2} pb={2}>
                    <Stack alignItems="center" className={dashboardControls.highbeam.className}>
                        {dashboardControls.highbeam.value === 0 ? (
                            <SvgIcon
                                sx={{ ...imgStyle, color: " #00799B" }}
                                component={Highbeam}
                            />
                        ) : (
                            <img style={imgStyle} src={HighbeamGif} alt="High beam on" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={2} pb={2}>
                    <Stack alignItems="center" className={dashboardControls.lowbeam.className}>
                        {dashboardControls.lowbeam.value === 0 ? (
                            <SvgIcon sx={{ ...imgStyle, color: "#FFA501" }} component={Lowbeam} />
                        ) : (
                            <img style={imgStyle} src={LowbeamGif} alt="Low bam on" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={3} pb={2}>
                    <Stack
                        alignItems="center"
                        className={dashboardControls.hazardwarninglight.className}
                    >
                        {dashboardControls.hazardwarninglight.value === 0 ? (
                            <SvgIcon sx={{ ...imgStyle, color: "#DE0505" }} component={Warning} />
                        ) : (
                            <img style={imgStyle} src={WarningGif} alt="Warning on" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={2} pb={2}>
                    <Stack
                        alignItems="center"
                        className={dashboardControls.parkinglights.className}
                    >
                        {dashboardControls.parkinglights.value === 0 ? (
                            <SvgIcon sx={{ ...imgStyle, color: "#FFA501" }} component={Parking} />
                        ) : (
                            <img style={imgStyle} src={ParkingGif} alt="Parking light on" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={3} pb={2}>
                    <Stack
                        alignItems="center"
                        className={dashboardControls.interiorlighting.className}
                    >
                        {dashboardControls.interiorlighting.value === 0 ? (
                            <SvgIcon
                                sx={{ ...imgStyle, color: "#FFA501" }}
                                component={InteriorLighting}
                            />
                        ) : (
                            <img
                                style={imgStyle}
                                src={InteriorLightingGif}
                                alt="Interior light on"
                            />
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Divider />
            {/* third row names */}
            <Grid container>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">AC</Typography>
                </Grid>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.temperature")} ({dashboardControls.temperature.value}
                        &#8451;)
                    </Typography>
                </Grid>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.wiper_front", {
                            value:
                                dashboardControls.wiper_front.value === "OFF"
                                    ? dashboardControls.wiper_front.value
                                    : `${t("carFunctions.level")} ${
                                          dashboardControls.wiper_front.value
                                      }`,
                        })}
                    </Typography>
                </Grid>
                <Grid item sm={3} pt={1}>
                    <Typography textAlign="center">
                        {t("carFunctions.wiper_back", {
                            value:
                                dashboardControls.wiper_back.value === "OFF"
                                    ? dashboardControls.wiper_back.value
                                    : `${t("carFunctions.level")} ${
                                          dashboardControls.wiper_back.value
                                      }`,
                        })}
                    </Typography>
                </Grid>
            </Grid>
            {/* third row icons */}
            <Grid container>
                <Grid item sm={3} pb={2}>
                    <Stack alignItems="center" className={dashboardControls.ac.className}>
                        {dashboardControls.ac.value === 0 ? (
                            <SvgIcon sx={{ ...imgStyle, color: "#6097A0" }} component={AC} />
                        ) : (
                            <img style={imgStyle} src={ACGif} alt="AC on" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={3} pb={2}>
                    <Stack alignItems="center" className={dashboardControls.temperature.className}>
                        {dashboardControls.temperature.value === 20 ? (
                            <SvgIcon
                                sx={{ ...imgStyle, color: "#6097A0" }}
                                component={Temperature}
                            />
                        ) : (
                            <img style={imgStyle} src={TemperatureGif} alt="Temperature" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={3} pb={2}>
                    <Stack alignItems="center" className={dashboardControls.wiper_front.className}>
                        {dashboardControls.wiper_front.value === "OFF" ? (
                            <SvgIcon
                                sx={{ ...imgStyle, color: "#6097A0" }}
                                component={WipersFront}
                            />
                        ) : (
                            <img style={imgStyle} src={WipersFrontGif} alt="Wipers front" />
                        )}
                    </Stack>
                </Grid>
                <Grid item sm={3} pb={2}>
                    <Stack alignItems="center" className={dashboardControls.wiper_back.className}>
                        {dashboardControls.wiper_back.value === "OFF" ? (
                            <SvgIcon
                                sx={{ ...imgStyle, color: "#6097A0" }}
                                component={WipersBack}
                            />
                        ) : (
                            <img style={imgStyle} src={WipersBackGif} alt="Wipers back" />
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </CardPanel>
    );
}

export default Dashboard;

const deviceProps = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
});

Dashboard.propTypes = {
    dashboardControls: PropTypes.shape({
        wiper_front: deviceProps,
        wiper_back: deviceProps,
        lowbeam: deviceProps,
        highbeam: deviceProps,
        hazardwarninglight: deviceProps,
        window_frontleft: deviceProps,
        window_frontright: deviceProps,
        window_backleft: deviceProps,
        window_backright: deviceProps,
        sunroof: deviceProps,
        interiorlighting: deviceProps,
        parkinglights: deviceProps,
        ac: deviceProps,
        temperature: deviceProps,
    }).isRequired,
};
