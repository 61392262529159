import PropTypes from "prop-types";
import AudioPlayerList from "./AudioPlayerList";
import SimpleButtonDialog from "./SimpleButtonDialog";

/**
 * The AudioListModal consists of a button to open the Modal, and the Modal itself.
 * In the Modal an AudioPlayerList is shown with the given texts and audios.
 *
 * @param {*} props
 *
 * @returns Modal for the AudioList with the Button to display the element.
 */
function AudioListModal(props) {
    const { audioData, buttonName, modalTitle, isRequest } = props;

    return (
        <SimpleButtonDialog
            title={modalTitle}
            buttonContent={buttonName}
            buttonDisabled={audioData?.length <= 0}
            isRequest={isRequest}
        >
            <AudioPlayerList audioData={audioData} />
        </SimpleButtonDialog>
    );
}

AudioListModal.propTypes = {
    /** Audio data that is shown in the Audio List,
     * consisting of a list of displayed text and the audio */
    audioData: PropTypes.arrayOf(
        PropTypes.shape({
            audioSrc: PropTypes.string,
            text: PropTypes.string,
        })
    ).isRequired,
    /** Text that is displayed on the button */
    buttonName: PropTypes.string,
    /** Title that is displayed in the modal above the audio list */
    modalTitle: PropTypes.string,
    /** Optional function when the modal closes */
    isRequest: PropTypes.bool, // to check if it is request or response
};

AudioListModal.defaultProps = {
    buttonName: "Open Audio Message Modal",
    modalTitle: "Audio Messages",
    isRequest: false,
};

export default AudioListModal;
