import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import slugify from "react-slugify";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { AudioParameters, DefaultEnvironmentName, envProps } from "../../shared/constants";
import ComponentWrapper from "../../shared/components/ComponentWrapper";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import EnvironmentDropdown from "../../shared/components/EnvironmentDropdown";
import NewSpeaker from "./NewSpeaker";
import SpeakerRegistrationTable from "./SpeakerRegistrationTable";
import SpeakerClassificationTable from "./SpeakerClassificationTable";
import AddButton from "./AddButton";
import { getTime } from "../../services/utils";
import { readFileAsBase64 } from "../../services/audio/audio-processing";
import { sendSRRequest } from "../../services/requests";
import {
    createConfigMessageForClassificationRequest,
    createConfigMessageForEmbeddingRequest,
} from "../../services/sr-backend-communication";
import { addNewAudioPlayer } from "../../shared/components/AudioPlayerList";

class SR extends React.Component {
    fileName = "SR-embeddings-";

    constructor(props) {
        super(props);

        // set the default env(from the .env file) as the selected env on load
        const initEnv =
            props.accessibleEnvs.length > 0
                ? props.accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0]
                : {};
        const endpoints = this.getUpdatedEndpoints(initEnv);

        this.state = {
            classificationEndpoint: endpoints.classificationEndpoint,
            enrollmentEndpoint: endpoints.enrollmentEndpoint,
            speakers: [],
            classificationSamples: [],
            showNewSpeaker: false,
            showNewClassification: false,
            selectedEnv: initEnv,
            showErrorToast: false,
            showInfoToast: false,
            errorToastMessage: "",
            atLeastOneSpeakerAdded: false,
            // dictionary to keep track of repeated names when importing config from a JSON file
            repeatedNameCounter: {},
        };
    }

    componentDidUpdate(prevProps) {
        const { accessibleEnvs } = this.props;
        if (prevProps.accessibleEnvs !== accessibleEnvs) this.setDefaultEnv();
    }

    // update the default env when the page is refreshed
    setDefaultEnv = () => {
        const { accessibleEnvs } = this.props;
        const newEnv = accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0];
        const newEndpoints = this.getUpdatedEndpoints(newEnv);
        this.setState({
            selectedEnv: newEnv,
            classificationEndpoint: newEndpoints.classificationEndpoint,
            enrollmentEndpoint: newEndpoints.enrollmentEndpoint,
        });
    };

    // Function to update the endpoints based on the selected env
    getUpdatedEndpoints = (env) => {
        if (env)
            return {
                classificationEndpoint: `https://${env.servicesEndpoint}${process.env.REACT_APP_SR_URL_Classification_File}`,
                enrollmentEndpoint: `https://${env.servicesEndpoint}${process.env.REACT_APP_SR_URL_Embedding_File}`,
            };

        return {
            classificationEndpoint: "",
            enrollmentEndpoint: "",
        };
    };

    /**
     * Delete a speaker from the list of speakers
     * @param {*} index index of the speaker in the list of speakers
     */
    deleteSpeaker = (index) => {
        const { speakers } = this.state;
        const newItemArray = [...speakers];
        newItemArray.splice(index, 1);
        this.setState({ speakers: newItemArray });
    };

    /**
     * Delete a speaker from the list of classificationSamples
     * @param {*} index index of the speaker in the list of classificationSamples
     */
    deleteClassificationSample = (index) => {
        const { classificationSamples } = this.state;
        const newItemArray = [...classificationSamples];
        newItemArray.splice(index, 1);
        this.setState({ classificationSamples: newItemArray });
    };

    /**
     * Function to update the state with the selected environment
     * @param {*} env - newly selected environment
     */
    onEnvChange = (env) => {
        const endpoints = this.getUpdatedEndpoints(env);
        this.setState({
            selectedEnv: env,
            classificationEndpoint: endpoints.classificationEndpoint,
            enrollmentEndpoint: endpoints.enrollmentEndpoint,
        });
    };

    onHandleCloseSnackbar = () => {
        this.setState({ showErrorToast: false });
    };

    toggleShowNewSpeaker = () => {
        const { showNewSpeaker } = this.state;
        this.setState({ showNewSpeaker: !showNewSpeaker });
    };

    toggleShowNewClassification = () => {
        const { showNewClassification } = this.state;
        this.setState({ showNewClassification: !showNewClassification });
    };

    /**
     * Import the contents of a single json file and add its entries to the speakers list.
     * @param {*} importedFile file that is to be imported
     */
    importSingleFile = (importedFile) => {
        try {
            const reader = new FileReader();

            reader.addEventListener("load", (e) => {
                const result = JSON.parse(e.target.result);
                this.addFileEntriesToSpeakers(result);
            });

            reader.readAsBinaryString(importedFile);
        } catch (error) {
            /* eslint-disable no-console */
            console.log(error);
        }
    };

    /**
     * Add the entries of a json dictionary to the list of speakers.
     * @param {*} speakerJsonDict json dictionary containing the new speaker entries.
     */
    addFileEntriesToSpeakers = (speakerJsonDict) => {
        try {
            Object.values(speakerJsonDict).forEach((speaker) => {
                this.addSpeaker(speaker);
            });
        } catch (error) {
            const { t } = this.props;
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.importError"),
            });
            /* eslint-disable no-console */
            console.log(error);
        }
    };

    /**
     * Create a reference and add it to the audioPlayerReferences dictionary.
     * @param {*} newKey variable that is used as key when the new reference object is added to
     * the audioPlayerReferences dictionary.
     */
    addToAudioPlayerReferences = (newKey) => {
        this.setState((prevState) => {
            return {
                audioPlayerReferences: addNewAudioPlayer(prevState.audioPlayerReferences, newKey),
            };
        });
    };

    getUniqueSpeakerName = (name) => {
        const { speakers, repeatedNameCounter } = this.state;
        let newName = name;

        const existing = speakers.filter((item) => item.name === name);
        if (existing?.length > 0) {
            // add the name to repeatedNameCounter, increment counter if name exists else set to 1
            if (repeatedNameCounter[name]) repeatedNameCounter[name] += 1;
            else repeatedNameCounter[name] = 1;
            newName = `${name}_${repeatedNameCounter[name]}`;
            return this.getUniqueSpeakerName(newName);
        }
        this.setState({ repeatedNameCounter });
        return newName;
    };

    /**
     * Add a new speaker to the list of speakers
     * @param {*} information the information given can differ based on if an audio file, or a recording,
     * or a json file with existing embeddings was uploaded.
     */
    addSpeaker = (information) => {
        const { name, id, audio, origin, audioData, embedding, group } = information;

        const uniqueName = this.getUniqueSpeakerName(name);

        let newItem = { name: uniqueName, origin, embedding, id, group, selected: false };

        if (!newItem.id) {
            newItem.id = getTime();
        }
        if (!newItem.embedding) {
            newItem.embedding = [];
        }

        /** Get the URL of the audio, if there is an audio. */
        if (origin === "recording" || origin === "audioFile") {
            newItem.audioUrls = audio;
            newItem.audioRef = React.createRef();
            this.addToAudioPlayerReferences(newItem.audioRef);
            newItem.format = AudioParameters.WAV;
        }

        // audio data has to be changed into a base64 string for sending it to the backend
        if (audioData) {
            readFileAsBase64(audioData).then((result) => {
                newItem = { ...newItem, audioData: result };
                this.setState((prevState) => {
                    const { speakers } = prevState;
                    const newSpeakers = [...speakers, newItem];
                    return { speakers: newSpeakers };
                });
            });
        } else {
            this.setState((prevState) => {
                const { speakers } = prevState;

                const newSpeakers = [...speakers, newItem];
                return { speakers: newSpeakers };
            });
        }
        this.setState({ atLeastOneSpeakerAdded: true });
    };

    addClassificationSpeaker = (information) => {
        const { name, audio, origin, id, audioData } = information;

        let newItem = { name, audio, origin, id };
        if (!newItem.id) {
            newItem.id = getTime();
        }
        newItem.classification = {};

        newItem.audioUrls = audio;
        newItem.audioRef = React.createRef();
        this.addToAudioPlayerReferences(newItem.audioRef);
        newItem.format = AudioParameters.WAV;

        // audio data has to be changed into a base64 string for sending it to the backend
        readFileAsBase64(audioData).then((result) => {
            newItem = { ...newItem, audioData: result };
            this.setState((prevState) => {
                const { classificationSamples } = prevState;

                const extendedExamples = [...classificationSamples, newItem];
                return { classificationSamples: extendedExamples };
            });
        });
    };

    exportContent = (toBeExported, speakerName) => {
        const data = JSON.stringify(toBeExported, null, "\t");
        const textBlob = new Blob([data], { type: "text/plain" });
        const url = URL.createObjectURL(textBlob);
        const link = document.createElement("a");
        link.href = url;
        const currentFileName = speakerName
            ? `${this.fileName}${speakerName}-${getTime()}`
            : this.fileName + getTime();
        link.download = `${slugify(currentFileName.substring(0, 30))}.json`;
        link.click();
    };

    handleSingleSpeakerDownload = (speakerId) => {
        const { speakers } = this.state;
        const toBeExported = {};
        const speakerIndex = speakers.findIndex((s) => s.id === speakerId);
        const speaker = speakers[speakerIndex];
        toBeExported[speaker.id] = {
            group: speaker.group,
            name: speaker.name,
            id: speaker.id,
            embedding: speaker.embedding,
        };
        this.exportContent(toBeExported, speaker.name);
    };

    handleMultiSpeakersDownload = () => {
        const { speakers } = this.state;
        const toBeExported = {};
        speakers.forEach((speaker) => {
            if (speaker.selected)
                toBeExported[speaker.id] = {
                    group: speaker.group,
                    name: speaker.name,
                    id: speaker.id,
                    embedding: speaker.embedding,
                };
        });
        this.exportContent(toBeExported);
    };

    handleSelectSingleSpeaker = (event, index) => {
        const { speakers } = this.state;
        speakers[index].selected = event.target.checked;
        this.setState({ speakers });
    };

    /**
     * Process the server response with the computed embedding
     * The computed embedding is added to the speaker entry.
     *
     * @param {*} speaker speaker that was sent to the server
     * @param {SpeechRecognitionResult} result embedding response from the server
     */
    processEmbeddingResult = (speaker, result) => {
        const { speakers } = this.state;
        const receivedEmbedding = result.embedding.embeddingValue;

        this.setState({
            speakers: speakers.map((spkr) =>
                spkr === speaker
                    ? {
                          ...spkr,
                          embedding: [...spkr.embedding, receivedEmbedding],
                      }
                    : spkr
            ),
        });
    };

    /**
     * Get the embedding for a specified speaker
     * @param {*} selectedSpeaker speaker for whose audio an embedding is requested
     */
    getEmbeddingFromServer = (selectedSpeaker) => {
        const { enrollmentEndpoint } = this.state;
        const config = createConfigMessageForEmbeddingRequest(selectedSpeaker);
        sendSRRequest(config, selectedSpeaker.audioData, enrollmentEndpoint)
            .then((response) => this.processEmbeddingResult(selectedSpeaker, response))
            .catch((err) => {
                /* eslint-disable no-console */
                console.log(err);
                const { t } = this.props;
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("toasts.connectError"),
                });
            });
    };

    /**
     * Get the audioUrls from the uploaded audio file and add the speaker
     * @param {*} speakerName speaker's name to be added
     * @param {*} audioFile audio of the speaker to be added
     * @param {*} isClassification boolean to differentiate between adding to speakers list or
     * classificationSamples list. Defaults to false
     */
    handleUploadSpeakerAudio = (speakerName, audioFile, isClassification = false) => {
        const fd = new FormData();
        const audioUrls = [];
        const audioData = [];

        const fileName = "file0";
        audioData.push(audioFile);
        audioUrls.push(URL.createObjectURL(audioFile));
        fd.append(fileName, audioFile);

        const result = {
            audio: audioUrls[0],
            name: speakerName,
            origin: "audioFile",
            audioData: audioData[0],
        };

        if (isClassification) this.addClassificationSpeaker(result);
        else this.addSpeaker(result);
    };

    handleUploadClassificationAudio = (speakerName, audioFile) => {
        this.handleUploadSpeakerAudio(speakerName, audioFile, true);
    };

    getClassifiedSpeakerName = (id) => {
        const { speakers } = this.state;
        const speaker = speakers.filter((s) => s.id === id);
        if (speaker.length === 0) return "Unknown";
        return speaker[0].name;
    };

    /**
     * Process the server response with the computed classification.
     * The computed classification, and the speakers used for the classification, are added to the example entry.
     *
     * @param {*} givenExample example that was sent to the server
     * @param {SpeechRecognitionResult} result classification response from the server
     * @param {*} classificationSpeakers speakers that were used for the classification
     */
    processClassificationResult = (givenExample, result, classificationSpeakers) => {
        const { classificationSamples } = this.state;
        const classes = classificationSpeakers.map((singleSpeaker) => singleSpeaker.name);
        this.setState({
            classificationSamples: classificationSamples.map((singleExample) =>
                singleExample === givenExample
                    ? {
                          ...singleExample,
                          classification: {
                              classes,
                              id: result.id,
                              name: this.getClassifiedSpeakerName(result.id),
                          },
                      }
                    : singleExample
            ),
            showInfoToast: true,
        });
    };

    /**
     * Request the embeddings of speakers that are selected, but have no embedding yet, from the backend. Add the returned embeddings to the speaker entries.
     *
     * @param {*} selectedSpeakers selected speakers
     */
    computeAllMissingEmbeddings = async (selectedSpeakers) => {
        const { enrollmentEndpoint } = this.state;

        await Promise.allSettled(
            selectedSpeakers.map(async (singleSpeaker) => {
                return singleSpeaker.embedding.length > 0
                    ? singleSpeaker.embedding
                    : sendSRRequest(
                          createConfigMessageForEmbeddingRequest(singleSpeaker),
                          singleSpeaker.audioData,
                          enrollmentEndpoint
                      )
                          .then((response) => this.processEmbeddingResult(singleSpeaker, response))
                          .then((response) => {
                              return response;
                          })
                          .catch((err) => {
                              /* eslint-disable no-console */
                              console.log(err);
                              const { t } = this.props;
                              this.setState({
                                  showErrorToast: true,
                                  errorToastMessage: t("toasts.connectError"),
                              });
                          });
            })
        );
    };

    /**
     * Create a database of speakers that is used for the classification request.
     * Each speaker is represented by its id, group, name, and embedding.
     *
     * @returns database that is a list of objects representing speakers
     */
    createDatabaseForClassification = async (selectedSpeakers) => {
        const speakerDatabase = selectedSpeakers.map((speaker) => {
            return {
                id: speaker.id,
                group: speaker.group,
                name: speaker.name,
                embedding: speaker.embedding,
            };
        });

        return speakerDatabase;
    };

    getSelectedSpeakersFromState = (selectedSpeakersForClassification) => {
        const { speakers } = this.state;
        return speakers.filter(
            (speaker) =>
                selectedSpeakersForClassification.findIndex((input) => input.id === speaker.id) !==
                -1
        );
    };

    handleClassifySpeaker = async (sample, selectedSpeakersForClassification) => {
        const { classificationEndpoint } = this.state;
        const { t } = this.props;

        this.computeAllMissingEmbeddings(
            this.getSelectedSpeakersFromState(selectedSpeakersForClassification)
        )
            .then(() => {
                return this.createDatabaseForClassification(
                    this.getSelectedSpeakersFromState(selectedSpeakersForClassification)
                );
            })
            .then((database) => {
                return createConfigMessageForClassificationRequest(sample, database);
            })
            .then((config) => {
                sendSRRequest(config, sample.audioData, classificationEndpoint)
                    .then((response) =>
                        this.processClassificationResult(
                            sample,
                            response,
                            this.getSelectedSpeakersFromState(selectedSpeakersForClassification)
                        )
                    )
                    .catch((err) => {
                        /* eslint-disable no-console */
                        console.log(err);
                        this.setState({
                            showErrorToast: true,
                            errorToastMessage: t("toasts.connectError"),
                        });
                    });
            });
    };

    handleSelectAllSpeakers = (checked) => {
        const { speakers } = this.state;
        speakers.forEach((speaker) => {
            // eslint-disable-next-line no-param-reassign
            speaker.selected = checked;
        });
        this.setState({ speakers });
    };

    render() {
        const {
            selectedEnv,
            showErrorToast,
            errorToastMessage,
            showNewSpeaker,
            showNewClassification,
            speakers,
            classificationSamples,
            atLeastOneSpeakerAdded,
            showInfoToast,
        } = this.state;
        const { accessibleEnvs, t } = this.props;

        return (
            <>
                <ComponentWrapper title="Speaker Recognition">
                    <Stack direction="row">
                        <TipsAndUpdatesOutlinedIcon sx={{ color: "teal", marginRight: "8px" }} />
                        <Typography sx={{ color: "teal" }}>{t("sr.dataDeletionHint")}</Typography>
                    </Stack>
                    {accessibleEnvs.length > 1 ? (
                        <Grid container>
                            <Grid item xs={12} sm={3}>
                                <EnvironmentDropdown
                                    envs={accessibleEnvs}
                                    name="SR_environment"
                                    selectedEnv={selectedEnv}
                                    onEnvChange={this.onEnvChange}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    {showNewSpeaker ? (
                        <NewSpeaker
                            speakers={speakers}
                            showNewSpeaker={showNewSpeaker}
                            onCancelClick={this.toggleShowNewSpeaker}
                            onRegisterUploadJSON={this.importSingleFile}
                            onRegisterRecordedAudio={this.addSpeaker}
                            onRegisterUploadAudio={this.handleUploadSpeakerAudio}
                        />
                    ) : null}
                    {showNewClassification ? (
                        <NewSpeaker
                            speakers={classificationSamples}
                            showNewSpeaker={showNewClassification}
                            onCancelClick={this.toggleShowNewClassification}
                            onRegisterRecordedAudio={this.addClassificationSpeaker}
                            onRegisterUploadAudio={this.handleUploadClassificationAudio}
                            isClassification
                        />
                    ) : null}

                    {atLeastOneSpeakerAdded ? (
                        <Grid container justifyContent="space-around" spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SpeakerRegistrationTable
                                    speakers={speakers}
                                    onDelete={this.deleteSpeaker}
                                    showNewSpeaker={this.toggleShowNewSpeaker}
                                    onSingleSpeakerDownload={this.handleSingleSpeakerDownload}
                                    onGetEmbedding={this.getEmbeddingFromServer}
                                    handleSelectAllSpeakers={this.handleSelectAllSpeakers}
                                    onMultiSpeakersDownload={this.handleMultiSpeakersDownload}
                                    handleSelectSingleSpeaker={this.handleSelectSingleSpeaker}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <SpeakerClassificationTable
                                    speakers={speakers}
                                    classificationSamples={classificationSamples}
                                    showNewClassification={this.toggleShowNewClassification}
                                    onDelete={this.deleteClassificationSample}
                                    onClassifySpeaker={this.handleClassifySpeaker}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: "250px" }}
                        >
                            <AddButton
                                text={t("sr.addNewSpeaker")}
                                onClick={this.toggleShowNewSpeaker}
                            />
                        </Grid>
                    )}
                </ComponentWrapper>
                <CustomSnackbar
                    open={showErrorToast}
                    message={errorToastMessage}
                    handleClose={this.onHandleCloseSnackbar}
                />
                <CustomSnackbar
                    open={showInfoToast}
                    message={t("sr.classificationSuccessful")}
                    handleClose={() => this.setState({ showInfoToast: false })}
                    severity="info"
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        accessibleEnvs: state.accessibleEnvs,
    };
}

export default compose(withTranslation("translation"), connect(mapStateToProps))(SR);

SR.propTypes = {
    t: PropTypes.func.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
};
