import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardHeader } from "@mui/material";

/**
 * React functional component to display the tiles on the Homepage.
 * @param {*} props - consists of information about the component to be displayed
 * @returns JSX of a component in a tile
 */
function ComponentTile(props) {
    const { path, title, style } = props;
    return (
        <Link style={{ textDecoration: "none" }} to={path}>
            <Card sx={style.root}>
                <div style={style.special}>
                    <CardHeader title={title} sx={{ textAlign: "center" }} />
                </div>
            </Card>
        </Link>
    );
}

export default ComponentTile;

ComponentTile.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
};

ComponentTile.defaultProps = {
    title: "",
    path: "",
    style: {},
};
