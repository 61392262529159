import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddButton from "./AddButton";
import { classificationSampleProps, speakerProps } from "../../shared/constants";
import SpeakerClassification from "./SpeakerClassification";
import CustomTooltip from "../../shared/components/CustomTooltip";
import CustomAudioPlayerWithDownload from "../../shared/components/CustomAudioPlayerWithDownload";

function SpeakerClassificationTable({
    classificationSamples,
    showNewClassification,
    onDelete,
    onClassifySpeaker,
    speakers,
}) {
    const { t } = useTranslation();
    const [showSpeakerClassification, setShowSpeakerClassification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const onClassifySpeakerButtonClick = (index) => {
        setSelectedIndex(index);
        setShowSpeakerClassification(true);
    };

    const handleOnClassifySpeaker = (selectedSpeakers) => {
        onClassifySpeaker(classificationSamples[selectedIndex], selectedSpeakers);
    };

    return (
        <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" gap={1} alignItems="center">
                    <CheckCircleOutlineIcon />
                    <Typography fontSize={20} fontWeight={500}>
                        {t("sr.speakerClassification")}
                    </Typography>
                </Stack>
                <AddButton text={t("sr.addNewSample")} onClick={showNewClassification} />
            </Stack>
            {/* Grid header */}
            <Grid container sx={{ padding: "5px" }}>
                <Grid item xs={2} sm={2} pt="9px">
                    <Typography fontWeight="bold" fontSize={14}>
                        Name
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} pt="9px">
                    <Stack direction="row" gap={0.5} alignItems="center">
                        <CheckCircleOutlineIcon />
                        <Typography fontWeight="bold" fontSize={14}>
                            {t("sr.classifiedSpeaker")}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={3} sm={3} pt="9px">
                    <Stack direction="row" gap={0.5} alignItems="center">
                        <MusicNoteOutlinedIcon />
                        <Typography fontWeight="bold" fontSize={14}>
                            Audio
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={2} sm={2} pt="9px">
                    {/*  */}
                </Grid>
                <Grid item xs={1} sm={1}>
                    {/*  */}
                </Grid>
            </Grid>
            <Divider />
            {/* Grid content */}
            {classificationSamples.length > 0 ? (
                <Box sx={{ overflowY: "scroll", maxHeight: "300px" }}>
                    {classificationSamples.map((sample, index) => (
                        <Stack key={sample.id}>
                            <Grid container>
                                <Grid item xs={2} sm={2} pt="9px">
                                    {sample.name}
                                </Grid>
                                <Grid item xs={4} sm={4} pt="9px">
                                    {sample.classification.name}
                                </Grid>
                                <Grid item xs={3} sm={3} pt="9px">
                                    {sample.audioUrls ? (
                                        <Stack direction="row" gap={2}>
                                            <CustomAudioPlayerWithDownload
                                                audioUrls={classificationSamples[index].audioUrls}
                                                name={classificationSamples[index].name}
                                            />
                                        </Stack>
                                    ) : (
                                        "-"
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={2} pt="9px">
                                    <CustomTooltip title={t("sr.classifySpeaker")}>
                                        <IconButton
                                            onClick={() => onClassifySpeakerButtonClick(index)}
                                            sx={{ padding: "0px" }}
                                        >
                                            <SyncOutlinedIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                                <Grid item xs={1} sm={1}>
                                    <CustomTooltip title={t("buttons.delete")}>
                                        <IconButton onClick={() => onDelete(index)}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Stack>
                    ))}
                </Box>
            ) : (
                <Stack>
                    <Grid container>
                        <Grid item>
                            <Typography fontSize={14} sx={{ marginBottom: "8px" }}>
                                {t("sr.noSamples")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Stack>
            )}
            {showSpeakerClassification && (
                <SpeakerClassification
                    showSpeakerClassification={showSpeakerClassification}
                    onCancelClick={() => setShowSpeakerClassification(false)}
                    handleOnClassifySpeaker={handleOnClassifySpeaker}
                    speakers={speakers}
                />
            )}
        </Stack>
    );
}

SpeakerClassificationTable.propTypes = {
    speakers: PropTypes.arrayOf(speakerProps).isRequired,
    classificationSamples: PropTypes.arrayOf(classificationSampleProps).isRequired,
    showNewClassification: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClassifySpeaker: PropTypes.func.isRequired,
};

export default SpeakerClassificationTable;
