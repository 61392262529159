import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en.json";
import translationDE from "./locales/de.json";

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

i18n
    /** Detect user language
     * Current default order:
     * - 'querystring' (append ?lng=LANGUAGE to URL)
     * - 'cookie' (set cookie i18next=LANGUAGE)
     * - 'localStorage' (set key i18nextLng=LANGUAGE)
     * - 'sessionStorage' (set key i18nextLng=LANGUAGE)
     * - 'navigator' (set browser language)
     * - 'htmlTag' (add html language tag <html lang="LANGUAGE" ...)
     * - 'path' (http://my.site.com/LANGUAGE/...)
     * - 'subdomain' (http://LANGUAGE.site.com/...)
     * Learn more: https://github.com/i18next/i18next-browser-languageDetector
     *    especially for changing the possibilities where to look for language settings,
     *    and the order in which they are considered.
     */
    .use(LanguageDetector)
    /** Passing i18n down to react-i18next. */
    .use(initReactI18next)
    /** Init i18next:
     * For all options read: https://www.i18next.com/overview/configuration-options */
    .init({
        resources,
        fallbackLng: "de",
        debug: true,
        supportedLngs: ["de", "en"],

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
