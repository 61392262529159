import { AudioListParameters } from "../shared/constants";
import { getTime } from "./utils";

/**
 * Function to create and download a WAV file for the given url
 * It checks if the url is created from a blob or if it is yet to be fetched.
 * @param {*} name - name to be given to the WAV file while downloaded
 * @param {*} url - url of the data to be put in the WAV File
 */
function downloadAsWAV(name, url) {
    // check if the url is created from a blob
    // in this case the url will be: "blob:http://..."
    if (url.includes("blob")) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${name}.wav`;
        link.click();
    } else {
        // check if the url is yet to be processed
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = () => {
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = `${name}.wav`;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
        };
        xhr.open("GET", url);
        xhr.send();
    }
}

/**
 * Function to save the dialogue interactions to a json file
 */
function downloadHistory(messages) {
    const data = JSON.stringify(messages, null, "\t");
    const textBlob = new Blob([data], { type: "text/plain" });
    const url = URL.createObjectURL(textBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `DialogueHistory_${getTime()}.json`;
    link.click();
}

/**
 * Function to add a new audio item, consisting of url and transcribed text, to an existing list of audio items
 * @param {String} urlOfNewAudio - url of user's audio request
 * @param {String} textOfNewAudio - transcribed text
 * @param {Array} audioItems - list of requests to add/update
 */
function saveRequests(urlOfNewAudio, textOfNewAudio, audioItems) {
    // check if there is user text, this check helps us
    // to ignore the empty audio that gets saved before
    // stop is clicked
    if (textOfNewAudio !== "") {
        // make a copy of the list
        const newAudioItems = [...audioItems];

        // create a dict with text and audio source url
        const item = {};

        item[AudioListParameters.text] = textOfNewAudio.trim();
        item[AudioListParameters.audioSrc] = urlOfNewAudio;

        // add to audioRequests list
        newAudioItems.push(item);

        return newAudioItems;
    }
    return audioItems;
}

/**
 * Function to save/update the audio response and the corresponding text
 * @param {*} url - url of audio response
 * @param {*} responseText - text response from DM
 * @param {*} stateAudioResponses - list of responses to add/update
 */
function saveResponses(url, responseText, stateAudioResponses) {
    // make a copy of the list

    const audioResponses = [...stateAudioResponses];
    // check if it already has 5 elements,
    // if so, delete the earliest and push latest
    if (audioResponses?.length === 5) {
        audioResponses.shift();
    }

    // create a dict with text and audio source url
    const item = {};
    const { text, audioSrc } = AudioListParameters;
    item[text] = responseText.trim();
    item[audioSrc] = url;

    // add to audioResponses list
    audioResponses.push(item);

    return audioResponses;
}

export { downloadAsWAV, downloadHistory, saveRequests, saveResponses };
