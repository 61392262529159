import { IconButton, Stack, Typography } from "@mui/material";
import InformationIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { newServiceProps } from "../constants";
import CustomTooltip from "./CustomTooltip";
import CustomSelect from "./CustomSelect";
import "../../styles/appStyle.css";
import NoValuesDropdown from "./NoValuesDropdown";

/**
 * React functional component to display dropdown values (of services)
 * @param {List<dictionary>} props - consists of details of services available
 * @returns JSX of dropdown with services
 */
function ServiceDropdown(props) {
    const { services, name, selectedService, onServiceChange, customLabel } = props;

    const { t } = useTranslation();

    const serviceDescription = selectedService?.description || "";
    return services?.length > 0 ? (
        <Stack>
            <Stack direction="row">
                <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                    {customLabel || t("main.service")}
                </Typography>
                {serviceDescription !== "" && (
                    <div style={{ display: "inline-flex" }}>
                        <CustomTooltip title={serviceDescription}>
                            <IconButton sx={{ color: "#4fc4a2" }}>
                                <InformationIcon fontSize="small" />
                            </IconButton>
                        </CustomTooltip>
                    </div>
                )}
            </Stack>
            <CustomSelect
                items={services}
                itemToOptionLabel={(item) => item.flavor}
                name={name}
                value={selectedService}
                onSelected={onServiceChange}
            />
        </Stack>
    ) : (
        <NoValuesDropdown title={t("main.service")} value={t("main.noServices")} />
    );
}

export default ServiceDropdown;

ServiceDropdown.propTypes = {
    services: PropTypes.arrayOf(newServiceProps),
    name: PropTypes.string,
    onServiceChange: PropTypes.func.isRequired,
    selectedService: PropTypes.oneOfType([newServiceProps.isRequired, PropTypes.string]),
    customLabel: PropTypes.string,
};

ServiceDropdown.defaultProps = {
    name: "ServiceDropdown",
    customLabel: null,
    selectedService: "",
    services: null,
};
