import { useEffect } from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";
import Spectrogram from "spectrogram";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

import CardPanel from "../../shared/components/CardPanel";

/**
 * React Component to display the Spectrogram of user audio
 * @param {MediaStream} audio - refers to the user audio stream
 */
function ASRSpectrogram({ audio }) {
    useEffect(() => {
        const spectro = Spectrogram(document.getElementById("spectro_canvas"), {
            audio: {
                enable: true,
            },
            colors(steps) {
                const baseColors = [
                    [0, 0, 255, 1],
                    [0, 255, 255, 1],
                    [0, 255, 0, 1],
                    [255, 255, 0, 1],
                    [255, 0, 0, 1],
                ];
                const positions = [0, 0.15, 0.3, 0.5, 0.75];

                // eslint-disable-next-line new-cap
                const scale = new chroma.scale(baseColors, positions).domain([0, steps]);

                const colors = [];

                for (let i = 0; i < steps; ++i) {
                    const color = scale(i);
                    colors.push(color.hex());
                }

                return colors;
            },
        });

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(audio);
        analyser.smoothingTimeConstant = 0;
        analyser.fftSize = 2048;
        source.connect(analyser);

        spectro.connectSource(analyser, audioContext);
        spectro.start();
        return () => {
            analyser.disconnect();
            source.disconnect();
        };
    }, []);

    return <canvas style={{ width: "100%", height: "100px" }} id="spectro_canvas" />;
}

ASRSpectrogram.propTypes = {
    audio: PropTypes.instanceOf(MediaStream).isRequired,
};

/**
 * React component that displays the Spectrogram, if a non-null localStream is given to it.
 * Else just displays an empty container for the Spectrogram.
 */
function ASRSpectrogramContainer({ localStream }) {
    const { t } = useTranslation();

    return (
        <CardPanel partial shouldHideDelete title={t("main.spectrogram")}>
            <Stack spacing={1} alignItems="center" height="170px">
                {localStream && <ASRSpectrogram audio={localStream} />}
            </Stack>
        </CardPanel>
    );
}

export default ASRSpectrogramContainer;

ASRSpectrogramContainer.propTypes = {
    localStream: PropTypes.instanceOf(MediaStream),
};

ASRSpectrogramContainer.defaultProps = {
    localStream: null,
};
