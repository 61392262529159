import { useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Stack, Typography, Link, Dialog, DialogContentText } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import * as actions from "./store/action-creators";
import Homepage from "./views/Homepage";
import ASR from "./views/ASR";
import DM from "./views/DM";
import TTS from "./views/TTS";
import Assistant from "./views/Assistant";
import KWS from "./views/KeywordSpotter";
import SR from "./views/SR";
import keycloak from "./keycloak";
import AllingaAppBar from "./routing/AllingaAppBar";
import "./styles/appStyle.css";
import build from "./build.json";
import { AllingaAccessState, Roles } from "./shared/constants";
import getAccessibleEnvironments from "./services/accessible-environments";
import SecondaryButton from "./shared/components/SecondaryButton";
import LoadingAnimationProgress from "./shared/components/Loading";

const globalTheme = createTheme({
    palette: {
        primary: {
            main: "rgba(116, 196, 180, 1)",
        },
    },
});

/**
 * Function to verify if user has necessary permission and then display the webapp
 */
function App({
    setAccessToKWS,
    setAccessToSR,
    getServicesAcrossEnvs,
    setAccessibleEnvironments,
    hasAccessToASR,
    hasAccessToDM,
    hasAccessToTTS,
    allFetched,
}) {
    const [allingaAccess, setAllingaAccess] = useState(AllingaAccessState.CHECKING);
    const [accessToCurrentEnv, setAccessToCurrentEnv] = useState(false);

    // fetch the services and set the accessible environments once after first render
    useEffect(() => {
        const accessibleEnvs = getAccessibleEnvironments();

        // access to the current env
        setAccessToCurrentEnv(
            accessibleEnvs.filter((a) => a.name === process.env.REACT_APP_DEFAULT_ENV).length > 0
        );

        // first set the accessible envs and then fetch the services
        Promise.all([setAccessibleEnvironments(accessibleEnvs)]).then(() => {
            getServicesAcrossEnvs();
        });
    }, []);

    const kwsAccess = keycloak?.hasRealmRole(Roles.kws_access);
    const srAccess = keycloak?.hasRealmRole(Roles.sr_access);

    /** If the user has access to any of the components, store the corresponding setting in redux */
    setAccessToKWS(kwsAccess);
    setAccessToSR(srAccess);

    /** Check if users have access to more than one app. */
    const moreThanOneApp =
        hasAccessToASR + hasAccessToDM + hasAccessToTTS + kwsAccess + srAccess >= 2;

    /** Determine the paths of the apps.
     * This is depending on if the users have access to services of more than one component:
     * - no:  the path of the only accessible component is at the root path.
     * - yes: the Homepage with the app overview is at the root path,
     *        and the apps are shown at respective sub paths. */
    const rootPath = "/";
    const asrPath = !moreThanOneApp && hasAccessToASR ? rootPath : "/asr";
    const dmPath = !moreThanOneApp && hasAccessToDM ? rootPath : "/dm";
    const ttsPath = !moreThanOneApp && hasAccessToTTS ? rootPath : "/tts";
    const kwsPath = !moreThanOneApp && kwsAccess ? rootPath : "/kws";
    const srPath = !moreThanOneApp && srAccess ? rootPath : "/sr";

    useEffect(() => {
        /**
         * Only after all the services are fetched from all the applicable environments,
         * restrict users from accessing AS if any of the following is false:
         * 1. do not have access to AS
         * 2. do not have access to at least one component
         * 3. do not have access to the current environment
         */
        if (allFetched) {
            const atleastOneApp =
                hasAccessToASR || hasAccessToDM || hasAccessToTTS || kwsAccess || srAccess;

            const access =
                keycloak?.hasRealmRole(Roles.allinga_stage) && atleastOneApp && accessToCurrentEnv;
            setAllingaAccess(access ? AllingaAccessState.APPROVED : AllingaAccessState.DENIED);
        }
    }, [allFetched]);

    return (
        <ThemeProvider theme={globalTheme}>
            <Router basename="/">
                {/* eslint-disable-next-line no-nested-ternary */}
                {allingaAccess === AllingaAccessState.APPROVED ? (
                    <>
                        <AllingaAppBar />
                        <div
                            style={{
                                marginTop: "15px",
                                paddingBottom: "30px",
                                minHeight: "100%",
                            }}
                        >
                            <Routes>
                                {moreThanOneApp && (
                                    <Route path={rootPath} element={<Homepage showReload />} />
                                )}
                                {hasAccessToASR && <Route path={asrPath} element={<ASR />} />}
                                {hasAccessToDM && <Route path={dmPath} element={<DM />} />}
                                {hasAccessToTTS && <Route path={ttsPath} element={<TTS />} />}
                                {kwsAccess && <Route path={kwsPath} element={<KWS />} />}
                                {srAccess && <Route path={srPath} element={<SR />} />}

                                <Route path="/assistant" element={<Assistant />} />
                                <Route path="*" element={<Navigate to={rootPath} />} />
                            </Routes>
                        </div>
                        <Footer />
                    </>
                ) : allingaAccess === AllingaAccessState.DENIED ? (
                    <div style={{ display: "inline" }}>
                        <AccessDeniedNotification />
                    </div>
                ) : (
                    <LoadingAnimationProgress />
                )}
            </Router>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    hasAccessToASR: state.hasAccessToASR,
    hasAccessToDM: state.hasAccessToDM,
    hasAccessToTTS: state.hasAccessToTTS,
    allFetched: state.allFetched,
});

export default connect(mapStateToProps, actions)(App);

App.propTypes = {
    setAccessToKWS: PropTypes.func.isRequired,
    setAccessToSR: PropTypes.func.isRequired,
    setAccessibleEnvironments: PropTypes.func.isRequired,
    getServicesAcrossEnvs: PropTypes.func.isRequired,
    hasAccessToASR: PropTypes.bool.isRequired,
    hasAccessToDM: PropTypes.bool.isRequired,
    hasAccessToTTS: PropTypes.bool.isRequired,
    allFetched: PropTypes.bool.isRequired,
};

// Function to display the footer across the webapp
function Footer() {
    const { t } = useTranslation();
    const year = new Date().getFullYear();

    const getLink = (url, buttonText) => {
        return (
            <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                    component="button"
                    underline="none"
                    className="link-button"
                    onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
                >
                    {buttonText}
                </Link>
            </Typography>
        );
    };

    return (
        <div className="footer grey lighten-2">
            <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
                {getLink("https://www.allinga-plattform.de/imprint", t("footer.imprint"))}
                {getLink("https://www.allinga-plattform.de/privacy", t("footer.privacy"))}
            </Stack>
            <p
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginTop: "2px",
                    color: "grey",
                }}
            >
                © {year} Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.
                <br />
                Build: {build.build}
            </p>
        </div>
    );
}

/**
 * React component to display the error message in a modal when
 * the user does not have access to Allinga Stage
 */
function AccessDeniedNotification() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleOnClick = () => {
        keycloak.logout();
        navigate("/");
    };

    const handleSupport = () => {
        const url = "https://jira.iais.fraunhofer.de/jira/servicedesk/customer/portal/9";
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleOpenEmail = () => {
        window.location = "mailto:platform-speech-assistant@iais.fraunhofer.de";
    };

    const notificationStyles = {
        dialog: {
            width: { xs: "21rem", xl: "25rem" },
            margin: 0,
            padding: "2rem",
            boxShadow: "none",
            backgroundColor: "rgba(245, 245, 245, 1)",
        },
        description: {
            marginTop: "1rem",
            marginBottom: "2rem",
        },
        iconError: {
            width: "3rem",
            height: "3rem",
            color: "#D51313",
        },
    };

    const getLink = (text, onClick) => {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
                color="secondary"
                underline="none"
                component="button"
                sx={{
                    textTransform: "inherit",
                    fontWeight: "400",
                    fontSize: "1rem",
                    lineHeight: "1.5",
                    fontFamily: "inherit",
                    padding: "0rem",
                    verticalAlign: "inherit",
                    color: "#26a69a",
                }}
                onClick={onClick}
            >
                {text}&nbsp;
            </Link>
        );
    };

    return (
        <Dialog
            id="accessDenied"
            open
            PaperProps={{ sx: notificationStyles.dialog }}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <BlockOutlinedIcon
                        sx={{ color: "rgba(212, 0, 13, 1)", width: "35px", height: "35px" }}
                    />
                    <Typography
                        sx={{ color: "rgba(51, 51, 51, 0.87)" }}
                        alignItems="center"
                        variant="h6"
                        fontWeight={500}
                    >
                        {t("access.accessDeniedLabel")}
                    </Typography>
                </Stack>
                <DialogContentText
                    sx={notificationStyles.description}
                    id="error-dialog-description"
                >
                    {t("access.accessDeniedTextLine1")}&nbsp;
                    {getLink(t("access.accessDeniedLinkText1"), handleSupport)}
                    {t("access.accessDeniedTextLine2")}&nbsp;
                    {getLink(t("access.accessDeniedLinkText2"), handleOpenEmail)}
                    {t("access.accessDeniedTextLine3")}
                </DialogContentText>
                <Typography
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <SecondaryButton onClick={handleOnClick}>
                        {t("access.loginButton")}
                    </SecondaryButton>
                </Typography>
            </Stack>
        </Dialog>
    );
}
