/**
 * Create the config message for the embedding request to the backend
 *
 * @param {*} candidate candidate whose embedding is requested.
 * @returns config message dictionary
 */
export function createConfigMessageForEmbeddingRequest(candidate) {
    return {
        debug: true,
        audio_format: { encoding: candidate.format, samplerate: 16000 },
    };
}
/**
 * Create the config message for the classification request to the backend
 *
 * @param {*} example example whose classification is requested.
 * @param {*} candidates candidates that are used as classes for the classification.
 * @returns config message dictionary
 */
export function createConfigMessageForClassificationRequest(example, candidates) {
    return {
        debug: true,
        audio_format: { encoding: example.format, samplerate: 16000 },
        candidates,
    };
}
