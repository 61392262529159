import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddButton from "./AddButton";
import { speakerProps } from "../../shared/constants";
import CustomTooltip from "../../shared/components/CustomTooltip";
import CustomAudioPlayerWithDownload from "../../shared/components/CustomAudioPlayerWithDownload";

function SpeakerRegistrationTable({
    speakers,
    showNewSpeaker,
    onSingleSpeakerDownload,
    onMultiSpeakersDownload,
    onGetEmbedding,
    onDelete,
    handleSelectAllSpeakers,
    handleSelectSingleSpeaker,
}) {
    const { t } = useTranslation();
    const selectedSpeakers = speakers.filter((s) => s.selected);
    const areAllSpeakersSelected =
        speakers.length > 0 && selectedSpeakers.length === speakers.length;
    const areSomeSpeakersSelected =
        selectedSpeakers.length > 0 && selectedSpeakers.length < speakers.length;
    const areNoSpeakersSelected = selectedSpeakers.length === 0;

    const handleHeaderCheckboxClick = (checked) => {
        handleSelectAllSpeakers(checked);
    };

    return (
        <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" gap={1} alignItems="center">
                    <CheckCircleOutlineIcon />
                    <Typography fontSize={20} fontWeight={500}>
                        {t("sr.speakerRegistration")}
                    </Typography>
                </Stack>
                <AddButton text={t("sr.addNewSpeaker")} onClick={showNewSpeaker} />
            </Stack>

            {/* Grid header */}
            <Grid container>
                <Grid item xs={1} sm={1}>
                    {areAllSpeakersSelected ? (
                        <Checkbox
                            checked
                            onChange={(e) => handleHeaderCheckboxClick(e.target.checked)}
                        />
                    ) : null}
                    {areSomeSpeakersSelected ? (
                        <IconButton onClick={() => handleHeaderCheckboxClick(true)}>
                            <IndeterminateCheckBoxIcon sx={{ color: "#4fc4a2" }} />
                        </IconButton>
                    ) : null}
                    {areNoSpeakersSelected ? (
                        <Checkbox
                            checked={false}
                            onChange={(e) => handleHeaderCheckboxClick(e.target.checked)}
                        />
                    ) : null}
                </Grid>
                <Grid item xs={2} sm={2} pt="9px">
                    <Typography fontWeight="bold" fontSize={14}>
                        Name
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={3} pt="9px">
                    <Stack direction="row" gap={0.5}>
                        <MusicNoteOutlinedIcon />
                        <Typography fontWeight="bold" fontSize={14}>
                            Audio
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={3} sm={3} pt="9px">
                    <Stack direction="row" gap={0.5}>
                        <WorkspacesOutlinedIcon />
                        <Typography fontWeight="bold" fontSize={14}>
                            Embedding
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={2} sm={2} pt="9px">
                    <Stack direction="row" gap={0.5}>
                        {areAllSpeakersSelected || areSomeSpeakersSelected ? (
                            <IconButton
                                onClick={onMultiSpeakersDownload}
                                sx={{ padding: "0px", color: "black" }}
                            >
                                <FileDownloadOutlinedIcon />
                                <Typography fontWeight="bold" fontSize={14}>
                                    {t("sr.downloadJson")}
                                </Typography>
                            </IconButton>
                        ) : (
                            <>
                                <CodeOutlinedIcon />
                                <Typography fontWeight="bold" fontSize={14}>
                                    JSON
                                </Typography>
                            </>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={1} sm={1}>
                    {/*  */}
                </Grid>
            </Grid>
            <Divider />
            {/* Grid content */}
            {speakers.length > 0 ? (
                <Box sx={{ overflowY: "scroll", maxHeight: "300px" }}>
                    {speakers.map((speaker, index) => (
                        <Stack key={speaker.id}>
                            <Grid container>
                                <Grid item xs={1} sm={1}>
                                    <Checkbox
                                        checked={speaker.selected}
                                        onChange={(event) =>
                                            handleSelectSingleSpeaker(event, index)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2} pt="9px">
                                    {speaker.name}
                                </Grid>
                                <Grid item xs={3} sm={3} pt="9px">
                                    {speaker.audioUrls ? (
                                        <Stack direction="row" gap={2}>
                                            <CustomAudioPlayerWithDownload
                                                audioUrls={speakers[index].audioUrls}
                                                name={speakers[index].name}
                                            />
                                        </Stack>
                                    ) : (
                                        "-"
                                    )}
                                </Grid>
                                <Grid item xs={3} sm={3} pt="9px">
                                    {speaker.embedding?.[0]?.length > 0 ? (
                                        <CustomTooltip title={t("sr.fetched")}>
                                            <DoneOutlinedIcon />
                                        </CustomTooltip>
                                    ) : (
                                        <CustomTooltip title={t("sr.getEmbedding")}>
                                            <IconButton
                                                onClick={() => onGetEmbedding(speaker)}
                                                sx={{ padding: "0px" }}
                                            >
                                                <SyncOutlinedIcon />
                                            </IconButton>
                                        </CustomTooltip>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={2} pt="9px">
                                    <CustomTooltip title={t("sr.download")}>
                                        <IconButton
                                            onClick={() => onSingleSpeakerDownload(speaker.id)}
                                            sx={{ padding: "0px" }}
                                            disabled={
                                                areAllSpeakersSelected || areSomeSpeakersSelected
                                            }
                                        >
                                            <FileDownloadOutlinedIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                                <Grid item xs={1} sm={1}>
                                    <CustomTooltip title={t("buttons.delete")}>
                                        <IconButton onClick={() => onDelete(index)}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Stack>
                    ))}
                </Box>
            ) : (
                <Stack>
                    <Grid container>
                        <Grid item xs={1} sm={1}>
                            {/*  */}
                        </Grid>
                        <Grid item>
                            <Typography fontSize={14} sx={{ marginBottom: "8px" }}>
                                {t("sr.noSpeakers")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Stack>
            )}
        </Stack>
    );
}

SpeakerRegistrationTable.propTypes = {
    speakers: PropTypes.arrayOf(speakerProps).isRequired,
    showNewSpeaker: PropTypes.func.isRequired,
    onSingleSpeakerDownload: PropTypes.func.isRequired,
    onGetEmbedding: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    handleSelectAllSpeakers: PropTypes.func.isRequired,
    onMultiSpeakersDownload: PropTypes.func.isRequired,
    handleSelectSingleSpeaker: PropTypes.func.isRequired,
};

export default SpeakerRegistrationTable;
