import PropTypes from "prop-types";

// constants for keycloak roles
const Roles = {
    allinga_stage: "AllingaStage",
    kws_access: "KWSAccess",
    sr_access: "SRAccess",
    allinga_stage_dashboardaccess: "AllingaStage_DashboardAccess",
};

// constants for component names
const ComponentNames = {
    DM: "dm",
    ASR: "asr",
    TTS: "tts",
};

const AllingaAccessState = {
    CHECKING: "checking",
    APPROVED: "approved",
    DENIED: "denied",
};

// constants for parameters applicable to services
const ServiceParameters = {
    namespace: "namespace",
    component: "component",
    languages: "languages",
    flavor: "flavor",
    name: "name",
    descriptionEn: "descriptionEn",
    descriptionDe: "descriptionDe",
};

const NewServiceParameters = {
    flavor: "flavor",
    languages: "languages",
    description: "description",
    voiceCollection: "voiceCollection", // this will not be applicable for ASR and DM
    deDeploymentId: "deDeploymentId", // only applicable for DM
};

// parameters for AudioList component
const AudioListParameters = {
    text: "text",
    audioSrc: "audioSrc",
};

const AudioParameters = {
    PCM16: "PCM16",
    PCM32: "PCM32",
    mono: 1,
    stereo: 2,
    WAV: "WAV",
};

// values for slider for speech rate
const SpeedSliderConstants = {
    marks: [
        {
            value: 0.8,
            label: "0.8",
        },
        {
            value: 0.9,
            label: "0.9",
        },
        {
            value: 1.0,
            label: "1.0",
        },
        {
            value: 1.1,
            label: "1.1",
        },
        {
            value: 1.2,
            label: "1.2",
        },
    ],
    min: 0.8,
    max: 1.2,
};

// KWS audio files/recordings should be a max of 2s
const KWSAudioDuration = 2;

const Demonstrator = "demonstrator";

const envProps = PropTypes.shape({
    name: PropTypes.string,
    servicesEndpoint: PropTypes.string,
    kwsEndpoint: PropTypes.string,
});

// Proptype that defines the expected structure of a service
const newServiceProps = PropTypes.shape({
    flavor: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    voiceCollection: PropTypes.arrayOf(
        PropTypes.shape({
            language: PropTypes.string,
            voices: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    deDeploymentId: PropTypes.string,
});

const componentServiceProps = PropTypes.shape({
    production: PropTypes.arrayOf(newServiceProps),
    staging: PropTypes.arrayOf(newServiceProps),
    development: PropTypes.arrayOf(newServiceProps),
    collab: PropTypes.arrayOf(newServiceProps),
    platform: PropTypes.arrayOf(newServiceProps),
});

const keywordProps = PropTypes.shape({
    name: PropTypes.string,
    audioUrls: PropTypes.arrayOf(PropTypes.string),
    keyword: PropTypes.string,
    threshold: PropTypes.string,
});

const kwsConfigTableProps = PropTypes.arrayOf(keywordProps);

const selectedPipelineProps = PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({
        KWS: PropTypes.bool,
        ASR: PropTypes.bool,
        DM: PropTypes.bool,
        TTS: PropTypes.bool,
    }),
});

const carDashboardControls = {
    wiper_front: { value: "OFF", className: "" },
    wiper_back: { value: "OFF", className: "" },
    lowbeam: { value: 0, className: "" },
    highbeam: { value: 0, className: "" },
    hazardwarninglight: { value: 0, className: "" },
    window_frontleft: { value: 0, className: "" },
    window_frontright: { value: 0, className: "" },
    window_backleft: { value: 0, className: "" },
    window_backright: { value: 0, className: "" },
    sunroof: { value: 0, className: "" },
    interiorlighting: { value: 0, className: "" },
    parkinglights: { value: 0, className: "" },
    ac: { value: 0, className: "" },
    temperature: { value: 20.0, className: "" },
};

const pipelineOptions = [
    { name: "KWS + ASR + DM + TTS", value: { KWS: true, ASR: true, DM: true, TTS: true } },
    { name: "KWS + ASR + DM", value: { KWS: true, ASR: true, DM: true, TTS: false } },
    { name: "ASR + DM + TTS", value: { KWS: false, ASR: true, DM: true, TTS: true } },
    { name: "ASR + DM", value: { KWS: false, ASR: true, DM: true, TTS: false } },
    { name: "DM + TTS", value: { KWS: false, ASR: false, DM: true, TTS: true } },
];

// Assistant state enum
const AssistantState = {
    IDLE: 0,
    WAITING_FOR_KEYWORD: 1,
    RECORDING_ASR: 2,
    WAITING_FOR_DM_RESPONSE: 3,
    PLAYING_RESPONSE: 4,
};

const speakerProps = PropTypes.shape({
    name: PropTypes.string,
    group: PropTypes.string,
    id: PropTypes.string,
    embedding: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    audioUrls: PropTypes.string,
    selected: PropTypes.bool,
});

const classificationSampleProps = PropTypes.shape({
    name: PropTypes.string,
    group: PropTypes.string,
    id: PropTypes.string,
    classification: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        classes: PropTypes.arrayOf(PropTypes.string),
    }),
    audioUrls: PropTypes.string,
    selected: PropTypes.bool,
});

const DefaultEnvironmentName = "production";

export {
    Roles,
    ComponentNames,
    ServiceParameters,
    AudioListParameters,
    AudioParameters,
    SpeedSliderConstants,
    KWSAudioDuration,
    Demonstrator,
    carDashboardControls,
    newServiceProps,
    NewServiceParameters,
    envProps,
    componentServiceProps,
    kwsConfigTableProps,
    keywordProps,
    pipelineOptions,
    selectedPipelineProps,
    AssistantState,
    speakerProps,
    classificationSampleProps,
    DefaultEnvironmentName,
    AllingaAccessState,
};
