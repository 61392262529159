import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import ServiceDropdown from "./ServiceDropdown";
import LanguageDropdown from "./LanguageDropdown";
import VoiceDropdown from "./VoiceDropdown";
import { envProps, newServiceProps } from "../constants";
import EnvironmentDropdown from "./EnvironmentDropdown";

/**
 * React component that displays the dropdown selections for services,
 * languages and voices wherever applicable to the calling parent component
 */
function ComponentDropdownSelections(props) {
    const {
        selectedService,
        selectedLanguage,
        selectedVoice,
        onServiceChange,
        onLanguageChange,
        onVoiceChange,
        name,
        services,
        isVoiceNeeded,
        selectedEnv,
        onEnvChange,
        accessibleEnvs,
    } = props;

    // Fetch the voices based on the selected language
    const getVoices = () => {
        const tempVoiceCollection = selectedService?.voiceCollection?.find(
            (element) => element.language === selectedLanguage
        );
        return tempVoiceCollection?.voices || [];
    };

    const envDropDown =
        accessibleEnvs.length > 1 ? (
            <EnvironmentDropdown
                envs={accessibleEnvs}
                name={`${name}_environment`}
                selectedEnv={selectedEnv}
                onEnvChange={onEnvChange}
            />
        ) : null;

    const serviceDropdown = (
        <ServiceDropdown
            onServiceChange={onServiceChange}
            services={services}
            selectedService={selectedService}
            name={`${name}_services`}
        />
    );

    const languageDropdown =
        selectedService && selectedLanguage ? (
            <LanguageDropdown
                onLanguageChange={onLanguageChange}
                languages={selectedService.languages}
                selectedLanguage={selectedLanguage}
                name={`${name}_languages`}
            />
        ) : null;

    const voiceDropdown =
        isVoiceNeeded && selectedLanguage && selectedVoice ? (
            <VoiceDropdown
                onVoiceChange={onVoiceChange}
                selectedVoice={selectedVoice}
                name={`${name}_voices`}
                voices={getVoices()}
            />
        ) : null;

    return (
        <>
            <Grid item xs={12} sm={7} md={4}>
                {serviceDropdown}
            </Grid>
            {accessibleEnvs.length > 1 ? (
                <Grid item xs={12} sm={5} md={2}>
                    {envDropDown}
                </Grid>
            ) : null}
            <Grid item xs={12} sm={5} md={2}>
                {languageDropdown}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                {voiceDropdown}
            </Grid>
        </>
    );
}

export default ComponentDropdownSelections;

ComponentDropdownSelections.propTypes = {
    selectedService: newServiceProps,
    selectedLanguage: PropTypes.string,
    selectedVoice: PropTypes.string,
    onServiceChange: PropTypes.func.isRequired,
    onLanguageChange: PropTypes.func.isRequired,
    onVoiceChange: PropTypes.func,
    name: PropTypes.string,
    services: PropTypes.arrayOf(newServiceProps),
    isVoiceNeeded: PropTypes.bool,
    selectedEnv: envProps,
    onEnvChange: PropTypes.func,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
};

ComponentDropdownSelections.defaultProps = {
    selectedService: null,
    selectedLanguage: null,
    selectedVoice: "",
    onVoiceChange: () => null,
    name: "Component",
    isVoiceNeeded: false,
    selectedEnv: {},
    onEnvChange: () => null,
    services: null,
};
