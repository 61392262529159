import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Send from "@mui/icons-material/Send";
import CustomSpinner from "../../shared/components/CustomSpinner";
import PrimaryButton from "../../shared/components/PrimaryButton";
import UploadFile from "../../shared/components/UploadFile";
import CustomSnackbar from "../../shared/components/CustomSnackbar";

function KWSUploadJSONFile({ onImport }) {
    const { t } = useTranslation();

    const initialState = {
        fileUploadName: "",
        filenameKey: Date.now(),
    };
    const [state, setState] = useState(initialState);
    const [isEnrolling, setEnrolling] = useState(false);
    const [event, setEvent] = useState(null);
    const [showToast, setShowToast] = useState(false);

    const onUpload = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        setEvent(e);
        const file = e.target.files[0];

        setState({ ...state, fileUploadName: file.name });
    };
    const { filenameKey, fileUploadName } = state;

    const onImportHandler = async () => {
        if (!fileUploadName) {
            setShowToast(true);
            return;
        }
        try {
            setEnrolling(true);
            await onImport(event);
            setEvent(null);
        } catch (err) {
            /* eslint-disable no-console */
            console.log(err);
        }

        setEnrolling(false);
        setState({
            ...state,
            fileUploadName: "",
            filenameKey: Date.now(),
        });
    };

    const enrollIcon = isEnrolling ? <CustomSpinner /> : <Send />;

    return (
        <>
            <UploadFile
                fileUploadName={fileUploadName}
                filenameKey={filenameKey}
                onUpload={onUpload}
                isJSON
            />
            <Box display="flex" justifyContent="flex-end">
                <PrimaryButton
                    endIcon={enrollIcon}
                    type="submit"
                    value="Save"
                    onClick={onImportHandler}
                >
                    {t("kws.enroll")}
                </PrimaryButton>
            </Box>
            <CustomSnackbar
                open={showToast}
                message={t("toasts.uploadFile")}
                handleClose={() => setShowToast(false)}
            />
        </>
    );
}

KWSUploadJSONFile.propTypes = {
    onImport: PropTypes.func.isRequired,
};

export default KWSUploadJSONFile;
