import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import KeywordUploadTab from "./KeywordUploadTab";
import KeywordRecordTab from "./KeywordRecordTab";
import KeywordPhoneticTab from "./KeywordPhoneticTab";
import { a11yProps, TabPanel } from "../../shared/components/TabHelpers";
import KWSUploadJSONFile from "./KWSUploadJSONFile";

/**
 * React component that displays the different tabs in KWS configuration
 */
function KWSTabs({ enrollEndpoint, onEnroll, configTable, onImport }) {
    const [value, setValue] = React.useState(0);
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    const { t } = useTranslation();

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                <Tabs variant="fullWidth" value={value} onChange={handleChange}>
                    <Tab label={t("kws.uploadAudio")} {...a11yProps(0)} />
                    <Tab label={t("kws.record")} {...a11yProps(1)} />
                    <Tab label={t("kws.phonetic")} {...a11yProps(2)} />
                    <Tab label={t("kws.uploadJSONFile")} {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <KeywordUploadTab
                    onEnroll={onEnroll}
                    configTable={configTable}
                    enrollEndpoint={enrollEndpoint}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <KeywordRecordTab
                    onEnroll={onEnroll}
                    configTable={configTable}
                    enrollEndpoint={enrollEndpoint}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <KeywordPhoneticTab onEnroll={onEnroll} configTable={configTable} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <KWSUploadJSONFile onImport={onImport} />
            </TabPanel>
        </Box>
    );
}

export default KWSTabs;

KWSTabs.propTypes = {
    enrollEndpoint: PropTypes.string.isRequired,
    onEnroll: PropTypes.func.isRequired,
    configTable: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            audioUrls: PropTypes.arrayOf(PropTypes.string),
            keyword: PropTypes.string,
            threshold: PropTypes.string,
        })
    ).isRequired,
    onImport: PropTypes.func.isRequired,
};
