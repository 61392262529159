import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ComponentDropdownSelections from "../../shared/components/ComponentDropdownSelections";
import ComponentWrapper from "../../shared/components/ComponentWrapper";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import Dashboard from "../../shared/components/Dashboard";
import DMMessenger from "./DMMessenger";
import * as actions from "../../store/action-creators";
import {
    carDashboardControls,
    componentServiceProps,
    DefaultEnvironmentName,
    envProps,
} from "../../shared/constants";
import { getLanguage, isServicesUpdated, onServiceChange } from "../../services/service-handling";
import { isDashboardAllowed } from "../../services/dashboard";
import { updateDialogState } from "../../services/utils";
import { dmRequest } from "../../services/requests";
import usePrevious from "../../shared/custom-hooks";

/**
 * DM: Dialogue Manager
 * React Component that sends to and receives text messages from the server.
 */
function DM(props) {
    const { accessibleEnvs, services } = props;

    const initEnv =
        accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0] ||
        accessibleEnvs[0];
    const initService = services?.[initEnv?.name]?.[0];
    const initLanguage = initService?.languages?.[0];

    const [state, setState] = useState({
        messages: [],
        selectedService: initService,
        selectedLanguage: initLanguage,
        tracker: "",
        dashboardControls: carDashboardControls,
        shouldShowDashboard: false,
        selectedEnv: initEnv,
        showErrorToast: false,
        errorToastMessage: "",
        shouldDisableInputControls: !initService,
    });

    const { t } = useTranslation();

    const prevServices = usePrevious(services);
    const prevAccessibleEnvs = usePrevious(accessibleEnvs);

    const haveServicesGotUpdated = prevServices
        ? isServicesUpdated(prevServices, services)
        : false;
    const haveAccessibleEnvsGotUpdated = prevAccessibleEnvs !== accessibleEnvs;

    // useEffect to run on load (componentDidMount)
    useEffect(() => {
        // set the initial state of shouldShowDashboard based on
        // the service selected by default on load
        if (state.selectedService) {
            setState({ ...state, shouldShowDashboard: isDashboardAllowed(state.selectedService) });
        } else {
            // if no service is available then set defaults
            setState({ ...state, selectedService: {}, selectedLanguage: "" });
        }
    }, []);

    const setDefaultEnv = () => {
        const defEnv = accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0];
        setState({ ...state, selectedEnv: defEnv || accessibleEnvs[0] });
    };

    // set the default selected service
    const setDefaultServiceAndLanguage = () => {
        if (state.selectedEnv) {
            const service = services[state.selectedEnv.name]?.[0] || {};
            const language = service?.languages?.[0] || "";
            setState({
                ...state,
                selectedLanguage: language,
                selectedService: service,
                shouldDisableInputControls: Object.keys(service).length === 0,
            });
        }
    };

    useEffect(() => {
        if (haveServicesGotUpdated) setDefaultServiceAndLanguage();
        if (haveAccessibleEnvsGotUpdated) setDefaultEnv();
    }, [haveServicesGotUpdated, haveAccessibleEnvsGotUpdated]);

    const handleDeleteHistory = () => {
        setState({ ...state, messages: [] });
    };

    const setAllDefaults = (env) => {
        const { selectedLanguage, selectedService, shouldShowDashboard } = state;
        // check if previously selected service's flavor exists in the new one
        const newServices = services[env.name];
        let newSelectedService = newServices?.[0] || {};
        let newSelectedLanguage = newSelectedService?.languages?.[0] || "";

        if (newServices?.length > 0) {
            newServices.forEach((service) => {
                if (service.flavor === selectedService.flavor) {
                    newSelectedService = service;
                    // if service.flavor exists, then check if that service has same language too
                    newSelectedLanguage = getLanguage(service.languages, selectedLanguage);
                }
            });
        }

        const isSelectedServiceInvalid = Object.keys(newSelectedService).length === 0;

        setState({
            ...state,
            selectedEnv: env,
            selectedService: newSelectedService,
            selectedLanguage: newSelectedLanguage,
            showErrorToast: isSelectedServiceInvalid,
            errorToastMessage: isSelectedServiceInvalid
                ? t("toasts.noServicesFound", {
                      value: env?.name || "",
                  })
                : "",
            shouldShowDashboard: isSelectedServiceInvalid ? false : shouldShowDashboard,
            shouldDisableInputControls: isSelectedServiceInvalid,
        });
    };

    /**
     * Function to update the state with the selected environment
     * @param {*} env - newly selected environment
     */
    const onEnvChange = (env) => {
        setAllDefaults(env);
    };

    /**
     * Function to update the selected service and display a message to notify
     * the user about the change in service
     * @param {Number} newService the newly selected service
     */
    const onDMServiceChange = (newService) => {
        const { messages, selectedService, selectedLanguage } = state;
        const response = onServiceChange(
            messages,
            selectedService,
            newService,
            messages.length,
            ""
        );

        const newLang = getLanguage(newService.languages, selectedLanguage);
        setState({
            ...state,
            shouldShowDashboard: isDashboardAllowed(newService),
            selectedService: newService,
            tracker: "",
            messages: response,
            selectedLanguage: newLang !== "" ? newLang : newService.languages?.[0] || "",
        });
    };

    /**
     * Function to update the state with the selected language
     * @param {*} newLanguage - object with name and value of the selected language
     */
    const onLanguageChange = (newLanguage) => {
        setState({ ...state, selectedLanguage: newLanguage.value });
    };

    /**
     *
     * @param {DialogueResponse} data - consists of events and text responses
     * from server
     */
    const processResponse = (data, existingMessages) => {
        const updatedDialogState = updateDialogState(
            data.events,
            existingMessages,
            existingMessages.length,
            state.dashboardControls
        );

        setState({
            ...state,
            tracker: data.tracker,
            messages: updatedDialogState.messages,
            dashboardControls: updatedDialogState.dashboardControls,
        });
    };

    /**
     * Function to send the user message to server
     * @param {String} text - text entered by the user
     * If a service is selected, then it is used to connect to
     * the service/namespace directly.
     */
    const sendMessage = (text) => {
        const { messages, selectedService, selectedEnv, tracker } = state;
        const msg = { sender: "user", text, id: messages.length };
        const copyOfMessages = [...messages];
        copyOfMessages.push(msg);
        setState({ ...state, messages: copyOfMessages });

        // if de deployment exists then use that id else flavor
        const flavor = selectedService.deDeploymentId || selectedService.flavor;
        dmRequest(text, flavor, selectedEnv.servicesEndpoint, tracker)
            .then((response) => processResponse(response, copyOfMessages))
            .catch((err) => {
                /* eslint-disable no-console */
                console.log(err);
                setState({
                    ...state,
                    showErrorToast: true,
                    errorToastMessage: t("toasts.connectError"),
                });
            });
    };

    const onHandleCloseSnackbar = () => {
        setState({ ...state, showErrorToast: false });
    };

    return (
        <>
            <ComponentWrapper
                title="Dialog Manager"
                leftMainCol={
                    <DMMessenger
                        sendMessage={sendMessage}
                        messages={state.messages}
                        shouldDisableInputControls={state.shouldDisableInputControls}
                        handleDeleteHistory={handleDeleteHistory}
                    />
                }
                rightMainCol={
                    state.shouldShowDashboard ? (
                        <Dashboard dashboardControls={state.dashboardControls} />
                    ) : null
                }
            >
                <Grid container spacing={1}>
                    {state.selectedService && state.selectedEnv ? (
                        <ComponentDropdownSelections
                            selectedService={state.selectedService}
                            selectedLanguage={state.selectedLanguage}
                            onServiceChange={onDMServiceChange}
                            onLanguageChange={onLanguageChange}
                            name="DM"
                            services={services[state.selectedEnv?.name]}
                            selectedEnv={state.selectedEnv}
                            onEnvChange={onEnvChange}
                            accessibleEnvs={accessibleEnvs}
                        />
                    ) : null}
                </Grid>
            </ComponentWrapper>
            <CustomSnackbar
                open={state.showErrorToast}
                message={state.errorToastMessage}
                handleClose={onHandleCloseSnackbar}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        services: {
            // get a dictionary of DM services across all the envs
            production: state.production.dmServices,
            staging: state.staging.dmServices,
            development: state.development.dmServices,
            collab: state.collab.dmServices,
            platform: state.platform.dmServices,
        },
        accessibleEnvs: state.accessibleEnvs,
    };
}

DM.propTypes = {
    services: componentServiceProps.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
};

export default connect(mapStateToProps, actions)(DM);
