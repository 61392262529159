import { IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import PropTypes from "prop-types";
import InformationIcon from "@mui/icons-material/InfoOutlined";
import CustomSelect from "./CustomSelect";
import CustomTooltip from "./CustomTooltip";

// Reusable component to display "no <services/language> available" when enumerate returns an empty list of services/languages.
function NoValuesDropdown({ title, value, tooltip }) {
    const { t } = useTranslation();
    return (
        <Stack>
            <Stack direction="row">
                <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                    {title}
                </Typography>
                <div style={{ display: "inline-flex" }}>
                    {tooltip !== "" ? (
                        <CustomTooltip title={tooltip}>
                            <IconButton sx={{ color: "#4fc4a2" }}>
                                <InformationIcon fontSize="small" />
                            </IconButton>
                        </CustomTooltip>
                    ) : (
                        <CustomTooltip title={t("main.selectService")}>
                            <IconButton sx={{ color: "#ffa501" }}>
                                <WarningAmberOutlinedIcon fontSize="small" />
                            </IconButton>
                        </CustomTooltip>
                    )}
                </div>
            </Stack>

            <CustomSelect
                items={[value]}
                name="no_services"
                value={value}
                onSelected={() => null}
            />
        </Stack>
    );
}

NoValuesDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
};

NoValuesDropdown.defaultProps = {
    tooltip: "",
};

export default NoValuesDropdown;
