// import { Demonstrator } from "../constants";

/**
 * Function to check if dashboard can be shown for the given service
 * @param {*} service the service to be checked for a dashboard
 * @returns a boolean; true if dashboard can be shown, false otherwise.
 */
function isDashboardAllowed(service) {
    // TODO: will the car commands service name will always be called so? if so, add to constants
    const carCommandsService = "car-function-commands-v2";
    return service.flavor === carCommandsService;
}

/**
 * Function to assign value and check if device needs to be
 * highlighted and update that accordingly
 * @param {String} device - car device control
 * @param {String/Number} value - new/updated value of the device
 * @param {*} dashboardControls - consists of all the dashboard controls
 * @returns the updated dashboard controls
 */
function setValueAndHighlighting(device, value, dashboardControls) {
    const dbCntrls = { ...dashboardControls };

    if (device === "wiper_front" || device === "wiper_back") {
        if (value !== "OFF") {
            dbCntrls[device] = { value, className: "highlighted" };
        } else {
            dbCntrls[device] = { value, className: "" };
        }
    } else if (value !== 0) {
        dbCntrls[device] = { value, className: "highlighted" };
    } else {
        dbCntrls[device] = { value, className: "" };
    }
    return dbCntrls;
}

/**
 * Processes a single dashboard event (as sent by the car functions DM service)
 * by updating the given dashboard controls. Due to React, they are not updated
 * directly, but instead this function returns an updated copy of the original
 * controls, which can be passed to React's setState directly.
 *
 * @param {*} dashboardEvent - event that is triggered
 * @param {*} oldDashboardControls - consists of all the dashboard controls
 * @returns the updated dashboard controls
 */
function updateDashboardControls(dashboardEvent, oldDashboardControls) {
    if (dashboardEvent.request) {
        if (dashboardEvent.request && dashboardEvent.request === "set") {
            return setValueAndHighlighting(
                dashboardEvent.device,
                dashboardEvent.value,
                oldDashboardControls
            );
        }
        if (dashboardEvent.request && dashboardEvent.request === "adjust") {
            let newValue;

            // check if the device is wiper front/back since their default value is "OFF"
            if (
                dashboardEvent.device === "wiper_front" ||
                dashboardEvent.device === "wiper_back"
            ) {
                if (oldDashboardControls[dashboardEvent.device].value === "OFF") {
                    newValue = dashboardEvent.value;
                } else {
                    // special check for wipers since the values 1-5 will be sent as strings
                    const currentValueInt = parseInt(
                        oldDashboardControls[dashboardEvent.device].value
                    );
                    const newValueInt = parseInt(dashboardEvent.value);
                    newValue = (currentValueInt + newValueInt).toString();
                }
            } else {
                // all other devices have integer defaults (mostly 0 except temp which has 20)
                newValue =
                    oldDashboardControls[dashboardEvent.device].value + dashboardEvent.value;
            }

            return setValueAndHighlighting(dashboardEvent.device, newValue, oldDashboardControls);
        }
    }

    // if none of the cases above apply, return the controls unmodified
    return oldDashboardControls;
}

export { isDashboardAllowed, updateDashboardControls };
