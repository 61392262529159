import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomSnackbar from "./CustomSnackbar";
import NameInput from "./NameInput";
import UploadFile from "./UploadFile";

function UploadAudioFile({
    onNameChange,
    name,
    fileUploadName,
    filenameKey,
    showToast,
    setShowToast,
    onUpload,
    label,
}) {
    const { t } = useTranslation();
    return (
        <>
            <Stack gap={2}>
                <Typography fontWeight={500} fontSize={20}>
                    {t("sr.uploadAudioFile")}
                </Typography>
                <NameInput onChange={onNameChange} value={name} label={label} id="enrollName" />
                <UploadFile
                    fileUploadName={fileUploadName}
                    filenameKey={filenameKey}
                    onUpload={onUpload}
                    isJSON={false}
                />
            </Stack>
            <CustomSnackbar
                open={showToast}
                message={t("toasts.invalidFormat")}
                handleClose={() => setShowToast(false)}
            />
        </>
    );
}

UploadAudioFile.propTypes = {
    onNameChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    fileUploadName: PropTypes.string.isRequired,
    filenameKey: PropTypes.number.isRequired,
    showToast: PropTypes.bool.isRequired,
    setShowToast: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default UploadAudioFile;
