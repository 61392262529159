import React from "react";
import { Grid, Stack } from "@mui/material";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import "../../styles/appStyle.css";
import { connectKWSClient, sendPCMToKWSClient } from "../../services/kws/keyword-spotter-client";
import getAudioStreamFromMicrophone from "../../services/audio/microphone";
import AudioController from "../../services/audio/audio-controller";
import { AudioParameters, DefaultEnvironmentName, envProps } from "../../shared/constants";
import keycloak from "../../keycloak";
import KWSConfiguration from "./KWSConfiguration";
import PrimaryButton from "../../shared/components/PrimaryButton";
import SecondaryButton from "../../shared/components/SecondaryButton";
import { playJingle } from "../../services/utils";
import ComponentWrapper from "../../shared/components/ComponentWrapper";
import { KWSTriggerAndScore, KWSLiveTranscript } from "./KWSOutput";
import EnvironmentDropdown from "../../shared/components/EnvironmentDropdown";
import EndpointSelector from "../../shared/components/EndpointSelector";
import KeywordDropdown from "../../shared/components/KeywordDropdown";
import CustomSnackbar from "../../shared/components/CustomSnackbar";

/**
 * KWS: Keyword Spotter
 * React Component to test the Keyword Spotter behavior
 */
class KWS extends React.Component {
    constructor(props) {
        super(props);
        const sampleKeyword = {
            name: "Sophia",
            audioUrls: ["-"], // pass audiourls as a hyphen to indicate there is no audio
            keyword: "S.OW.F.IY.AH;S.AH.F.IH.AH;S.AH. .F.IY.R;S.IH.F.Y.UW.R;S.AH. .V.IY.R",
            threshold: "0.00005",
        };

        // set the default env(from the .env file) as the selected env on load
        const initEnv =
            props.accessibleEnvs.length > 0
                ? props.accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0] ||
                  props.accessibleEnvs[0]
                : {};
        const endpoints = this.getUpdatedEndpoints(initEnv);

        this.state = {
            isConnected: false,
            localhostPort: "8888",
            endpoint: endpoints.endpoint,
            enrollEndpoint: endpoints.enrollEndpoint,
            triggerAndScoreEntry: "",
            liveTranscriptEntry: "",
            isConfigPaneOpen: false,
            configTable: [sampleKeyword],
            selectedKeyword: sampleKeyword,
            // dictionary to keep track of repeated keywords when importing config from a JSON file
            repeatedKeyCounter: {},
            selectedEnv: initEnv,
            showErrorToast: false,
            showInfoToast: false,
            errorToastMessage: "",
        };
        this.onClickConnect = this.onClickConnect.bind(this);
        this.onProcessed = this.onProcessed.bind(this);
        this.stop = this.stop.bind(this);
        this.sampleRate = 16000;
        this.numberOfChannels = AudioParameters.mono;
        this.bufferSize = 80;
        this.audioController = new AudioController(
            this.processingAudioNodeOutput,
            this.sampleRate,
            null,
            this.bufferSize,
            this.numberOfChannels
        );
    }

    componentDidUpdate(prevProps) {
        const { accessibleEnvs } = this.props;
        if (prevProps.accessibleEnvs !== accessibleEnvs) this.setDefaultEnv();
    }

    componentWillUnmount() {
        this.stop();
        // fix to stop the error "Can't perform a React state update on an unmounted component."
        this.setState = () => {};
    }

    // update the default env when the page is refreshed
    setDefaultEnv = () => {
        const { accessibleEnvs } = this.props;
        const newEnv =
            accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0] ||
            accessibleEnvs[0];
        const newEndpoints = this.getUpdatedEndpoints(newEnv);
        this.setState({
            selectedEnv: newEnv,
            endpoint: newEndpoints.endpoint,
            enrollEndpoint: newEndpoints.enrollEndpoint,
        });
    };

    /** Start and prepare all audio recording, both for sending the audio to the backend.
     */
    handleStart = () => {
        getAudioStreamFromMicrophone()
            .then((stream) => this.connectToKWSClient(stream))
            .catch(() => {
                const { t } = this.props;
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("toasts.micPermission"),
                });
            });
    };

    // update the output cardpanels for each kws call
    onProcessed = (msg) => {
        this.setState({ liveTranscriptEntry: msg?.meta?.cache });

        if (msg.is_keyword) {
            playJingle();
            this.setState({
                triggerAndScoreEntry: `spotted keyword with score ${msg?.meta?.score}`,
                showInfoToast: true,
            });
        }
    };

    // add the keyword and its details to the table
    onEnroll = (enrollment) => {
        const { configTable } = this.state;
        this.setState({ configTable: [...configTable, enrollment] });
    };

    onKeywordChange = (keyword) => {
        this.setState({ selectedKeyword: keyword });
    };

    // delete the keyword information from the table
    onDelete = (index) => {
        const { configTable } = this.state;
        const copyOfConfigTable = [...configTable];
        copyOfConfigTable.splice(index, 1);
        this.setState({ configTable: copyOfConfigTable });
    };

    // start/stop spotter on button click
    onClickConnect = () => {
        const { isConnected } = this.state;
        if (isConnected) {
            this.stop();
        } else {
            this.handleStart();
        }
    };

    /**
     * Function to import the contents of the file and add it to the config table
     * @param {*} event - event containing the file with keywords
     */
    onImport = (event) => {
        try {
            const importedFile = event.target.files[0];
            const reader = new FileReader();

            reader.addEventListener("load", (e) => {
                const result = JSON.parse(e.target.result);
                this.appendRowsToConfigTable(result);
            });

            reader.readAsBinaryString(importedFile);
        } catch (error) {
            /* eslint-disable no-console */
            console.log(error);
        }
    };

    onChangeEndpoint = (endpoint) => {
        const { localhostPort, selectedEnv } = this.state;
        if (endpoint === "local")
            // local
            this.setState({
                endpoint: `ws://localhost:${localhostPort}/spot?jwt=${keycloak.token}`,
                enrollEndpoint: `http://localhost:${localhostPort}/enroll`,
            });
        // remote
        else
            this.setState({
                endpoint: `wss://${selectedEnv.kwsEndpoint}${process.env.REACT_APP_KWS_URL}/spot?jwt=${keycloak.token}`,
                enrollEndpoint: `https://${selectedEnv.kwsEndpoint}${process.env.REACT_APP_KWS_URL}/enroll`,
            });
    };

    /**
     * Function to check if the keyword name already exists in the config table
     * If yes, it creates a new name by appending "_<counter>"
     * If no, it returns the keyword name as is
     * @param {*} name
     * @returns
     */
    getUniqueKeywordName = (name) => {
        const { configTable, repeatedKeyCounter } = this.state;
        let newName = name;

        const existing = configTable.filter((item) => item.name === name);
        if (existing?.length > 0) {
            // add the keyname to repeatedKeyCounter, increment counter if keyname exists else set to 1
            if (repeatedKeyCounter[name]) repeatedKeyCounter[name] += 1;
            else repeatedKeyCounter[name] = 1;
            newName = `${name}_${repeatedKeyCounter[name]}`;
            return this.getUniqueKeywordName(newName);
        }
        return newName;
    };

    // start stream and spotter
    stop = async () => {
        this.audioController.shutdown();
        if (this.kwsClient) this.kwsClient.close();

        this.setState({
            isConnected: false,
        });
    };

    processingAudioNodeOutput = (event) => {
        sendPCMToKWSClient(this.kwsClient, event.data[1]);
    };

    /**
     * Function to append the keywords imported from the JSON file to the config table
     * @param {*} result - list of keywords imported from the JSON file
     */
    appendRowsToConfigTable = (result) => {
        const { configTable, repeatedKeyCounter } = this.state;
        const { t } = this.props;
        // check if result is a list
        if (!result.length)
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.importError"),
            });

        for (let index = 0; index < result.length; index++) {
            // check if the json entries matches the expected structure of
            // KWS config (name, threshold and keyword)
            const isThresholdNumber =
                !Number.isNaN(parseFloat(result[index]?.threshold)) &&
                !Number.isNaN(parseFloat(result[index]?.threshold) - 0);

            if (result[index]?.name && result[index]?.keyword && isThresholdNumber) {
                const enrollment = result[index];
                enrollment.audioUrls = ["-"];
                enrollment.name = this.getUniqueKeywordName(enrollment.name);
                configTable.push(enrollment);
            } else {
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("kws.importError"),
                });
            }
        }
        this.setState({ configTable, repeatedKeyCounter });
    };

    /**
     * Function to setup KWS. Set up the audio node that is connected to the backend and
     * send the audio to the KWS.
     *
     * @param {MediaStream} stream - refers to the user audio stream
     */
    connectToKWSClient = async (stream) => {
        // set up audio nodes
        await this.audioController.initAudio(stream);

        const { endpoint, selectedKeyword } = this.state;

        try {
            // connect to KWS
            this.kwsClient = await connectKWSClient(
                endpoint,
                selectedKeyword.keyword,
                selectedKeyword.threshold,
                this.sampleRate,
                this.onProcessed,
                this.stop
            );
            this.setState({ isConnected: true });
        } catch (err) {
            const { t } = this.props;
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.kwsConnectError"),
            });
        }
    };

    // Function to update the endpoints based on the selected env
    getUpdatedEndpoints = (env) => {
        return {
            endpoint: `wss://${env.kwsEndpoint}${process.env.REACT_APP_KWS_URL}/spot?jwt=${keycloak.token}`,
            enrollEndpoint: `https://${env.kwsEndpoint}${process.env.REACT_APP_KWS_URL}/enroll`,
        };
    };

    /**
     * Function to update the state with the selected environment
     * @param {*} env - newly selected environment
     */
    onEnvChange = (env) => {
        const endpoints = this.getUpdatedEndpoints(env);
        this.setState({
            selectedEnv: env,
            endpoint: endpoints.endpoint,
            enrollEndpoint: endpoints.enrollEndpoint,
        });
    };

    render() {
        const { t, accessibleEnvs } = this.props;
        const {
            isConnected,
            isConfigPaneOpen,
            enrollEndpoint,
            configTable,
            selectedKeyword,
            triggerAndScoreEntry,
            liveTranscriptEntry,
            selectedEnv,
            showErrorToast,
            showInfoToast,
            errorToastMessage,
        } = this.state;

        return (
            <>
                <ComponentWrapper
                    title="Keyword Spotting"
                    leftMainCol={<KWSTriggerAndScore data={triggerAndScoreEntry} />}
                    rightMainCol={<KWSLiveTranscript data={liveTranscriptEntry} />}
                >
                    <Stack direction="row" spacing={2}>
                        <PrimaryButton
                            name="connect"
                            onClick={this.onClickConnect}
                            isErrorButton={isConnected}
                        >
                            {isConnected ? t("main.stop") : t("kws.connect")}
                        </PrimaryButton>
                        <SecondaryButton
                            name="config"
                            onClick={() => this.setState({ isConfigPaneOpen: true })}
                        >
                            {t("kws.configureKeywords")}
                        </SecondaryButton>
                    </Stack>
                    <Grid container sx={{ marginTop: "0px !important" }} spacing={2}>
                        <Grid item xs={12} sm={3} sx={{ paddingLeft: "0px !important" }}>
                            <KeywordDropdown
                                onKeywordChange={this.onKeywordChange}
                                selectedKeyword={selectedKeyword}
                                configTable={configTable}
                            />
                        </Grid>
                        {accessibleEnvs.length > 1 ? (
                            <Grid item xs={12} sm={3}>
                                <EnvironmentDropdown
                                    envs={accessibleEnvs}
                                    name="KWS_environment"
                                    selectedEnv={selectedEnv}
                                    onEnvChange={this.onEnvChange}
                                />
                            </Grid>
                        ) : null}
                        {/** Option to change to local KWS is only shown if users have access to the
                    expert mode */}
                        <EndpointSelector
                            selectName="kws_tab_kws"
                            onPortChanged={(port) => this.setState({ localhostPort: port })}
                            onEndpointSelected={this.onChangeEndpoint}
                        />
                    </Grid>
                    {isConfigPaneOpen ? (
                        <KWSConfiguration
                            onEnroll={(enrollment) => this.onEnroll(enrollment)}
                            enrollEndpoint={enrollEndpoint}
                            onDelete={this.onDelete}
                            configTable={configTable}
                            onImport={this.onImport}
                            onCancelClick={() => this.setState({ isConfigPaneOpen: false })}
                            showKwsConfig={isConfigPaneOpen}
                        />
                    ) : null}
                </ComponentWrapper>
                <CustomSnackbar
                    open={showErrorToast}
                    message={errorToastMessage}
                    handleClose={() => this.setState({ showErrorToast: false })}
                />
                <CustomSnackbar
                    open={showInfoToast}
                    message={t("toasts.keywordSpotted")}
                    handleClose={() => this.setState({ showInfoToast: false })}
                    severity="info"
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accessibleEnvs: state.accessibleEnvs,
});

export default compose(withTranslation("translation"), connect(mapStateToProps))(KWS);

KWS.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string,
    }).isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
};
