import "react-h5-audio-player/lib/styles.css";
import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import CustomTooltip from "./CustomTooltip";
import CustomAudioPlayerWithDownload from "./CustomAudioPlayerWithDownload";
import "../../styles/appStyle.css";

/**
 * Pause the audio of all referenced players in the audioPlayerReferences dictionary,
 * except the given one.
 * @param {*} audioPlayerReferences - dictionary of all audio player references
 * @param {*} givenRefKey key to the reference of the player in the audioPlayerReferences
 * dictionary that is not to be paused.
 * @returns null
 */
function pauseOtherAudioPlayers(audioPlayerReferences, givenRefKey) {
    const copyOfReferences = audioPlayerReferences;
    Object.keys(copyOfReferences).map((keyInRefDict) => {
        if (copyOfReferences[keyInRefDict] !== givenRefKey) {
            if (copyOfReferences[keyInRefDict].current?.audio) {
                copyOfReferences[keyInRefDict].current.audio.current.pause();
                copyOfReferences[keyInRefDict].current.audio.current.currentTime = 0;
            }
        }
        return null;
    });
}

/**
 * Create a reference and add it to the audioPlayerReferences dictionary.
 * @param {*} audioPlayerReferences - a dictionary of all the audio player references
 * @param {*} newKey variable that is used as key when the new reference object is added to
 * the dictionary of audio players.
 */
function addNewAudioPlayer(audioPlayerReferences, newKey) {
    const copyOfReferences = { ...audioPlayerReferences };
    copyOfReferences[newKey] = React.createRef();
    return copyOfReferences;
}

/**
 * React component which is responsible for displaying the list of audio requests
 * made by the user or the responses received from the server.
 * It also provides options to play or download the audio files (in wav format).
 *
 * The audios are shown in the table in reverse order, so that the most recent one is on top.
 */
class AudioPlayerList extends React.Component {
    constructor() {
        super();
        this.state = {
            audioPlayerReferences: {},
        };
    }

    /**
     * Function that is immediately invoked after a component is mounted.
     * The function checks if all of the current audioData items have a corresponding reference
     * for an audio player in the audioPlayerReferences dictionary. If not, a reference is
     * created and added to the dictionary.
     */
    componentDidMount() {
        const { audioData } = this.props;
        const { audioPlayerReferences } = this.state;
        if (audioData?.length > 0) {
            for (let i = 0; i < audioData.length; i++) {
                if (!audioPlayerReferences[audioData[i].audioSrc]) {
                    this.addToAudioPlayerReferences(audioData[i].audioSrc);
                }
            }
        }
    }

    /**
     * Function that is invoked immediately after and update.
     * If the given prop shouldStop is true, then all audio players are paused.
     * If the given audioData is changed, it adds references for the new audios to the
     * audioPlayerReferences dictionary.
     *
     * @param {*} prevProps
     */
    async componentDidUpdate(prevProps) {
        const { audioData } = this.props;
        const { audioPlayerReferences } = this.state;
        if (prevProps.audioData !== audioData) {
            for (let i = 0; i < audioData.length; i++) {
                if (!audioPlayerReferences[audioData[i].audioSrc]) {
                    this.addToAudioPlayerReferences(audioData[i].audioSrc);
                }
            }
        }
    }

    componentWillUnmount() {
        const { audioPlayerReferences } = this.state;
        Object.keys(audioPlayerReferences).map((refKey) => {
            if (audioPlayerReferences[refKey].current) {
                audioPlayerReferences[refKey].current.audio.current.pause();
            }
            return null;
        });
    }

    /**
     * Create a reference and add it to the audioPlayerReferences dictionary.
     * @param {*} newKey variable that is used as key when the new reference object is added to
     * the audioPlayerReferences dictionary.
     */
    addToAudioPlayerReferences = (newKey) => {
        this.setState((prevState) => {
            return {
                audioPlayerReferences: addNewAudioPlayer(prevState.audioPlayerReferences, newKey),
            };
        });
    };

    render() {
        const { audioData } = this.props;
        const { audioPlayerReferences } = this.state;
        return (
            <Stack spacing={2}>
                {audioData?.length > 0 && Object.keys(audioPlayerReferences).length > 0 ? (
                    <>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography fontWeight="bold">Text</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" gap={0.5}>
                                    <MusicNoteOutlinedIcon />
                                    <Typography fontWeight="bold">Audio</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container justifyContent="center" spacing={2}>
                            {/** a shallow copy of audioData has to be made, otherwise the
    underlying object is changed */}
                            {[...audioData].reverse().map((a, index) => (
                                <Grid container key={index.toString() + a.text}>
                                    <Grid item xs={12} md={6}>
                                        <TextSnippet transcribedText={a.text} />
                                    </Grid>
                                    <Stack direction="row" gap={2}>
                                        <CustomAudioPlayerWithDownload
                                            audioUrls={a.audioSrc}
                                            name={a.text}
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                ) : null}
            </Stack>
        );
    }
}

/**
 * Return the given text snippet in a div element.
 *
 * The text is shortened to 50 characters.
 * If the original text is longer, it is indicated by "..." after the shortened text and a
 * Tooltip shows the complete text when hovering over the shortened snippet.
 *
 * @param {*} props
 * @returns text snippet, adapted to a certain length with Tooltip for longer texts.
 */
function TextSnippet({ transcribedText }) {
    if (transcribedText.length > 100) {
        return (
            <CustomTooltip title={transcribedText}>
                <div name="info1">{`${transcribedText.substring(0, 50)}...`}</div>
            </CustomTooltip>
        );
    }
    return (
        <div data-iscapture="true" name="info1">
            {transcribedText}
        </div>
    );
}

TextSnippet.propTypes = {
    transcribedText: PropTypes.string.isRequired,
};

AudioPlayerList.propTypes = {
    audioData: PropTypes.arrayOf(
        PropTypes.shape({
            audioSrc: PropTypes.string,
            text: PropTypes.string,
        })
    ),
};

AudioPlayerList.defaultProps = {
    audioData: [],
};

export { pauseOtherAudioPlayers, addNewAudioPlayer };
export default AudioPlayerList;
