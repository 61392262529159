import {
    TOGGLE_EXPERTMODE,
    ACCESS_EXPERTMODE,
    ACCESS_KWS,
    ACCESS_SR,
    ACCESS_ASR,
    ACCESS_DM,
    ACCESS_TTS,
    SET_KWS_CONFIG_DO_NOT_ASK_AGAIN,
    ACCESSIBLE_ENVS,
    ALL_FETCHED,
} from "./action-types";
import {
    extractASRServices,
    extractDMServices,
    extractTTSServices,
} from "../services/service-handling";
import { getServicesFromAllComponents } from "../services/requests";

/**
 * Action creator to toggle the expert mode flag
 * @param {*} payload is ignored for this action
 * @returns an action with appropriate type and payload
 */
export function toggleExpertMode(payload) {
    return { type: TOGGLE_EXPERTMODE, payload };
}

/**
 * Set a boolean that determines, if expert mode is accessible.
 * If false, then the user is only able to see the demo mode,
 * and the button to switch to expert mode is missing.
 * @param {*} payload
 * @returns
 */
export function setAccessToExpertMode(payload) {
    return { type: ACCESS_EXPERTMODE, payload };
}

/**
 * Set a boolean that determines, if the user has KWS access.
 *
 * @param {*} payload
 * @returns
 */
export function setAccessToKWS(payload) {
    return { type: ACCESS_KWS, payload };
}

/**
 * Set a boolean that determines if the user has Speaker Recognition access.
 *
 * @param {*} payload
 * @returns
 */
export function setAccessToSR(payload) {
    return { type: ACCESS_SR, payload };
}

/**
 * Set the list of environments that are accessible by the logged in user.
 * @param {*} payload
 * @returns
 */
export function setAccessibleEnvironments(payload) {
    return { type: ACCESSIBLE_ENVS, payload };
}

/**
 * Set a boolean that determines if the user has ASR access (at least one service).
 *
 * @param {*} payload
 * @returns
 */
export function setAccessToASR(payload) {
    return { type: ACCESS_ASR, payload };
}

/**
 * Set a boolean that determines if the user has DM access (at least one service).
 *
 * @param {*} payload
 * @returns
 */
export function setAccessToDM(payload) {
    return { type: ACCESS_DM, payload };
}

/**
 * Set a boolean that determines if the user has TTS access (at least one service).
 *
 * @param {*} payload
 * @returns
 */
export function setAccessToTTS(payload) {
    return { type: ACCESS_TTS, payload };
}

// Get action-type based on the selected environment
function getActionType(envName) {
    let actionType = "";
    switch (envName) {
        case "production":
            actionType = "PRODUCTION_SERVICES_FETCHED";
            break;
        case "staging":
            actionType = "STAGING_SERVICES_FETCHED";
            break;
        case "development":
            actionType = "DEV_SERVICES_FETCHED";
            break;
        case "collab":
            actionType = "COLLAB_SERVICES_FETCHED";
            break;
        case "platform":
            actionType = "PLATFORM_SERVICES_FETCHED";
            break;
        default:
            actionType = "";
    }
    return actionType;
}

// Enumerate across all the accessible environments and components to fetch all the services.
export function getServicesAcrossEnvs() {
    return (dispatch, getState) => {
        const { accessibleEnvs } = getState();
        let envCount = 0;
        accessibleEnvs.forEach((env) => {
            getServicesFromAllComponents(env).then(async (responseDict) => {
                envCount++;
                const extractedDict = {
                    asrServices: extractASRServices(responseDict.asrServices),
                    dmServices: await extractDMServices(
                        responseDict.dmServices,
                        env.name === "staging"
                    ),
                    ttsServices: extractTTSServices(responseDict.ttsServices),
                };
                if (extractedDict.asrServices.length > 0) dispatch(setAccessToASR(true));
                if (extractedDict.dmServices.length > 0) dispatch(setAccessToDM(true));
                if (extractedDict.ttsServices.length > 0) dispatch(setAccessToTTS(true));
                // store all the services for the current env in the loop
                dispatch({ type: getActionType(env.name), payload: extractedDict });
                // Once all the services in all the envs are fetched, set allFetched as true
                if (envCount === accessibleEnvs.length) {
                    dispatch({ type: ALL_FETCHED, payload: true });
                }
            });
        });
    };
}

/**
 * Action creator to set the flag for "do not ask again" feature in KWS configurations.
 * This flag is used during deletion of keywords from the configuration table.
 * @param {*} payload - contains the boolean value to be set to the flag
 * @returns an action with appropriate type and payload
 */
export function setKWSConfigDoNotAskAgain(payload) {
    return { type: SET_KWS_CONFIG_DO_NOT_ASK_AGAIN, payload };
}
