import { useEffect } from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";
import Spectrogram from "spectrogram";
import { useTranslation } from "react-i18next";

import CardPanel from "../../shared/components/CardPanel";

/**
 * React component that displays the Spectrogram for the audioURL given
 */
function TTSSpectrogram({ audioUrl }) {
    useEffect(() => {
        async function setup() {
            const spectro = Spectrogram(document.getElementById("spectro_canvas"), {
                audio: {
                    enable: false,
                },
                colors(steps) {
                    const baseColors = [
                        [0, 0, 255, 1],
                        [0, 255, 255, 1],
                        [0, 255, 0, 1],
                        [255, 255, 0, 1],
                        [255, 0, 0, 1],
                    ];
                    const positions = [0, 0.15, 0.3, 0.5, 0.75];

                    // eslint-disable-next-line new-cap
                    const scale = new chroma.scale(baseColors, positions).domain([0, steps]);

                    const colors = [];

                    for (let i = 0; i < steps; ++i) {
                        const color = scale(i);
                        colors.push(color.hex());
                    }

                    return colors;
                },
            });

            const audioContext = new AudioContext();
            const request = new XMLHttpRequest();
            request.open("GET", audioUrl, true);
            request.responseType = "arraybuffer";

            request.onload = () => {
                audioContext.decodeAudioData(request.response, (buffer) => {
                    spectro.connectSource(buffer, audioContext);
                    spectro.start();
                });
            };
            request.send();
        }
        setup();
    }, []);
    return <canvas style={{ width: "100%", height: "100px" }} id="spectro_canvas" />;
}

TTSSpectrogram.propTypes = {
    audioUrl: PropTypes.string.isRequired,
};

/**
 * React component that displays the Spectrogram, if a non-null audioURL is given to it.
 * Else just displays an empty container for the Spectrogram.
 */
function TTSSpectrogramContainer({ audioUrl }) {
    const { t } = useTranslation();

    return (
        <CardPanel shouldHideDelete partial title={t("main.spectrogram")}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "170px",
                }}
            >
                {audioUrl ? <TTSSpectrogram audioUrl={audioUrl} /> : ""}
            </div>
        </CardPanel>
    );
}

export default TTSSpectrogramContainer;

TTSSpectrogramContainer.propTypes = {
    audioUrl: PropTypes.string,
};

TTSSpectrogramContainer.defaultProps = {
    audioUrl: null,
};
