import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import TextField from "@mui/material/TextField";
import "../../styles/appStyle.css";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid, Tooltip } from "@mui/material";
import CustomSnackbar from "./CustomSnackbar";
import tooltipStyle from "../../styles/tooltipStyle";

/**
 * React component that displays an input field, where the user can input a text message and then send it to a server.
 * It shows an alert message if there is an attempt made to send a blank message.
 * It is possible to navigate through the history of sent messages by using the arrow-up and arrow-down key.
 * If expert mode is selected, then the component also displays a button to download
 * the history of the chat.
 */
function SendMessageForm(props) {
    const initialState = {
        messageList: [],
        message: "",
        currentListPosition: -1,
    };
    const [state, setState] = useState(initialState);
    const { t } = useTranslation();
    const [showToast, setShowToast] = useState(false);

    /**
     * Function to update the message entered by user
     * @param {event} e - event consisting of message entered by user
     */
    const handleChange = (e) => {
        setState({ ...state, message: e.target.value });
    };

    /**
     * Function to check if user has entered message and make a call to
     * send the message to server
     * @param {event} e - button click event when Send message is pressed
     */
    const handleSend = (e) => {
        const { message, messageList } = state;
        const { sendMessage } = props;
        e.preventDefault();
        if (message === "") {
            setShowToast(true);
        } else {
            const newMessage = message;
            const newMessageList = [...messageList, newMessage];
            sendMessage(newMessage);
            setState({
                ...state,
                messageList: newMessageList,
                currentListPosition: newMessageList.length,
                message: "",
            });
        }
    };

    /**
     * Handle functionalities of special key when pressed in the input field.
     *
     * Keys with special function are currently:
     *  - Enter: Send the message to the server, if the message is not empty.
     *  - Arrow-Up: Display the message that was sent before the message that is currently displayed in the input field.
     *  - Arrow-Down: Display the message that was sent after the message that is currently displayed in the input field.
     *
     * @param {keyEvent} e - event that includes the name of the key that was pressed
     */
    const onKeyDown = (e) => {
        const { currentListPosition, messageList } = state;
        if (e.key === "Enter") {
            handleSend(e);
        } else if (e.key === "ArrowUp") {
            const newPointer = currentListPosition - 1;

            // -> key press shows the input that was sent before current input
            //      if the input shown is the first input sent, it continues to show this input
            if (currentListPosition > 0) {
                setState({
                    ...state,
                    message: messageList[newPointer],
                    currentListPosition: newPointer,
                });
            }
        } else if (e.key === "ArrowDown") {
            const newPointer = currentListPosition + 1;

            // the input field shows already a former input 'a', but not the most recent one
            // -> key press shows the input that was sent after input 'a'
            if (newPointer < messageList.length) {
                setState({
                    ...state,
                    message: messageList[newPointer],
                    currentListPosition: newPointer,
                });

                // the input field shows the most recent input sent,
                // -> key press shows an empty field for new input
            } else if (newPointer === messageList.length) {
                setState({ ...state, message: "", currentListPosition: newPointer });
            }
        }
    };

    const { disabled, disableTooltip } = props;
    const { message } = state;
    const disableControlsTooltip = disabled ? disableTooltip || t("main.selectService") : "";

    return (
        <>
            <Tooltip
                PopperProps={{
                    sx: tooltipStyle,
                }}
                title={disableControlsTooltip}
            >
                <Grid container spacing={2} sx={{ height: "100px" }}>
                    <Grid item xs={10} sm={11} sx={{ padding: "24px 0px 0px 24px !important" }}>
                        <TextField
                            sx={{ width: "100%", backgroundColor: "white" }}
                            name="inputMessage"
                            onChange={handleChange}
                            value={message}
                            placeholder="Type your message here"
                            type="text"
                            onKeyDown={(e) => onKeyDown(e)}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={2} sm={1} sx={{ padding: "24px 0px 0px 12px !important" }}>
                        <IconButton
                            sx={{ backgroundColor: "#26a69a", color: "white", marginTop: "8px" }}
                            onClick={handleSend}
                            name="send_message"
                            disabled={disabled}
                        >
                            <SendOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Tooltip>
            <CustomSnackbar
                open={showToast}
                message={t("toasts.blankMessage")}
                handleClose={() => setShowToast(false)}
            />
        </>
    );
}

export default SendMessageForm;

SendMessageForm.propTypes = {
    sendMessage: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    disableTooltip: PropTypes.string,
};

SendMessageForm.defaultProps = {
    disabled: false,
    disableTooltip: "",
};
