import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import ServiceDropdown from "../../../shared/components/ServiceDropdown";
import ComponentCardHeader from "./ComponentCardHeader";
import { newServiceProps, componentServiceProps, envProps } from "../../../shared/constants";
import EnvironmentDropdown from "../../../shared/components/EnvironmentDropdown";

// React component to show the DM settings in Assistant configurations.
function DMSettings(props) {
    const [open, setOpen] = useState(false);
    const {
        accessibleEnvs,
        selectedEnv,
        onEnvChange,
        services,
        selectedService,
        onServiceChange,
    } = props;
    const { t } = useTranslation();

    return (
        <Card sx={{ border: "1px solid", boxShadow: "none" }}>
            <ComponentCardHeader title="Dialogue Manager" open={open} setOpen={setOpen} />
            {open ? (
                <CardContent>
                    <Typography fontWeight={500}>{t("assistant.alwaysActive")}</Typography>
                    <Grid sx={{ marginTop: "10px" }} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {accessibleEnvs.length > 1 ? (
                                <EnvironmentDropdown
                                    envs={accessibleEnvs}
                                    name="DM_environment"
                                    selectedEnv={selectedEnv}
                                    onEnvChange={onEnvChange}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ServiceDropdown
                                onServiceChange={onServiceChange}
                                services={services[selectedEnv?.name]}
                                selectedService={selectedService}
                                name="assistant_dm"
                                customLabel="Service"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            ) : null}
        </Card>
    );
}

DMSettings.propTypes = {
    services: componentServiceProps.isRequired,
    selectedService: newServiceProps,
    onServiceChange: PropTypes.func.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
    selectedEnv: envProps.isRequired,
    onEnvChange: PropTypes.func.isRequired,
};

DMSettings.defaultProps = {
    selectedService: null,
};

export default DMSettings;
