import PropTypes from "prop-types";
import { useState } from "react";
import UploadFile from "./UploadFile";

function UploadJSONFile({ onUploadJSONFile }) {
    const initialState = {
        fileUploadName: "",
        filenameKey: Date.now(),
    };
    const [state, setState] = useState(initialState);

    const onUpload = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        setState({ ...state, fileUploadName: file.name });
        onUploadJSONFile(file);
    };

    const { filenameKey, fileUploadName } = state;
    return (
        <UploadFile
            fileUploadName={fileUploadName}
            filenameKey={filenameKey}
            onUpload={onUpload}
            isJSON
        />
    );
}

UploadJSONFile.propTypes = {
    onUploadJSONFile: PropTypes.func.isRequired,
};

export default UploadJSONFile;
