/* eslint-disable camelcase */
import jwtDecode from "jwt-decode";
import keycloak from "../keycloak";
import environments from "../environments.json";

/**
 * Function to find the environments that the logged in user can access
 * @returns a list of objects, each object containing information about an environment.
 */
const getAccessibleEnvironments = () => {
    // decode Keycloak token to get the endpoints
    const { realm_access } = jwtDecode(keycloak.token);
    const { roles } = realm_access;

    if (!roles) return [];

    const envs = [];
    environments.environments.forEach((sp) => {
        envs.push({ name: sp.name, endpoint: sp.grpcEndpoint });
    });

    // find the names of the common endpoints between envs and uniqueList
    const namesOfAllowedEnvs = [];
    roles.forEach((u) => {
        envs.forEach((e) => {
            if (u && u.includes(e.endpoint) && namesOfAllowedEnvs.indexOf(e.name) === -1)
                namesOfAllowedEnvs.push(e.name);
        });
    });

    // find the environments that match the name of the common endpoints
    const accessibleEnvs = environments.environments.filter((e) => {
        if (namesOfAllowedEnvs.indexOf(e.name) !== -1) return e;
        return null;
    });

    return accessibleEnvs;
};

export default getAccessibleEnvironments;
