import { useState } from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "../../styles/appStyle.css";
import { Box, Stack, Typography } from "@mui/material";

import CustomTooltip from "../../shared/components/CustomTooltip";
import EnrollButton from "./EnrollButton";
import NameInput from "../../shared/components/NameInput";

/**
 * React class component that provides the functionality to enter the ARPAbet phonetic
 * transcription code of a keyword.
 */
function KeywordPhoneticTab(props) {
    const [keywordName, setKeywordName] = useState("");
    const [keyword, setKeyword] = useState("");
    const [threshold, setThreshold] = useState("");

    const { t } = useTranslation();

    const assignKeywordName = (name) => {
        setKeywordName(name);
    };

    const handleKeywordChange = (name) => {
        setKeyword(name);
    };

    const handleThresholdChange = (e) => {
        setThreshold(e.target.value);
    };

    const getEnrollment = () => {
        // check if a keyword is entered
        if (keyword === "") {
            throw new Error(t("toasts.enterKeyword"));
        }

        // check if valid threshold is entered
        const parsedThreshold = parseFloat(threshold);
        if (threshold === "" || Number.isNaN(parsedThreshold)) {
            throw new Error(t("toasts.invalidThreshold"));
        }

        // pass audiourls as a hyphen to indicate there is no audio
        const enrollment = {
            name: keywordName,
            audioUrls: ["-"],
            keyword,
            threshold,
        };

        setKeywordName("");
        setKeyword("");
        setThreshold("");

        return enrollment;
    };

    const { onEnroll, configTable } = props;

    return (
        <>
            <Stack gap={2} marginBottom="36px">
                <NameInput
                    onChange={assignKeywordName}
                    value={keywordName}
                    label={t("kws.addKeywordName")}
                    id="enrollName"
                />
                <CustomTooltip title={t("kws.enterKeywordDescription")}>
                    <NameInput
                        onChange={handleKeywordChange}
                        value={keyword}
                        label={t("kws.enterKeyword")}
                        id="keywordInput"
                        fullWidth
                    />
                </CustomTooltip>

                <Typography>{t("kws.selectThreshold")}</Typography>
                <Stack direction="row">
                    <TextField
                        id="thresholdInput"
                        label={t("kws.enterThreshold")}
                        type="Number"
                        value={threshold}
                        onChange={handleThresholdChange}
                    />
                </Stack>
            </Stack>
            <Box display="flex" justifyContent="flex-end">
                <EnrollButton
                    getEnrollment={getEnrollment}
                    onEnroll={onEnroll}
                    configTable={configTable}
                    keywordName={keywordName}
                    setKeywordName={assignKeywordName}
                />
            </Box>
        </>
    );
}

export default KeywordPhoneticTab;

KeywordPhoneticTab.propTypes = {
    configTable: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            audioUrls: PropTypes.arrayOf(PropTypes.string),
            keyword: PropTypes.string,
            threshold: PropTypes.string,
        })
    ).isRequired,
    onEnroll: PropTypes.func.isRequired,
};
