import PropTypes from "prop-types";
import { ButtonBase, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

/**
 * React component to display the headers for the components in Assistant settings
 */
function ComponentCardHeader({ title, open, setOpen }) {
    return (
        <ButtonBase
            sx={{
                width: "100%",
                borderBottom: open ? "1px solid" : "",
            }}
            onClick={() => setOpen(!open)}
        >
            <Stack direction="row" sx={{ width: "100%" }} justifyContent="space-between">
                <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        padding: "16px",
                    }}
                >
                    {title}
                </Typography>
                {open ? (
                    <KeyboardArrowUpIcon
                        sx={{
                            padding: "16px",
                        }}
                    />
                ) : (
                    <KeyboardArrowDownIcon
                        sx={{
                            padding: "16px",
                        }}
                    />
                )}
            </Stack>
        </ButtonBase>
    );
}

ComponentCardHeader.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default ComponentCardHeader;
