export const TOGGLE_EXPERTMODE = "TOGGLE_EXPERTMODE";
export const ACCESS_EXPERTMODE = "ACCESS_EXPERTMODE";
export const ACCESS_KWS = "ACCESS_KWS";
export const ACCESS_SR = "ACCESS_SR";
export const ACCESS_ASR = "ACCESS_ASR";
export const ACCESS_DM = "ACCESS_DM";
export const ACCESS_TTS = "ACCESS_TTS";
export const SET_KWS_CONFIG_DO_NOT_ASK_AGAIN = "SET_KWS_CONFIG_DO_NOT_ASK_AGAIN";
export const ACCESSIBLE_ENVS = "ACCESSIBLE_ENVS";
export const PRODUCTION_SERVICES_FETCHED = "PRODUCTION_SERVICES_FETCHED";
export const STAGING_SERVICES_FETCHED = "STAGING_SERVICES_FETCHED";
export const DEV_SERVICES_FETCHED = "DEV_SERVICES_FETCHED";
export const COLLAB_SERVICES_FETCHED = "COLLAB_SERVICES_FETCHED";
export const PLATFORM_SERVICES_FETCHED = "PLATFORM_SERVICES_FETCHED";
export const ALL_FETCHED = "ALL_FETCHED";
