import {
    TOGGLE_EXPERTMODE,
    ACCESS_EXPERTMODE,
    ACCESS_KWS,
    ACCESS_SR,
    ACCESS_ASR,
    ACCESS_DM,
    ACCESS_TTS,
    SET_KWS_CONFIG_DO_NOT_ASK_AGAIN,
    ACCESSIBLE_ENVS,
    PRODUCTION_SERVICES_FETCHED,
    STAGING_SERVICES_FETCHED,
    DEV_SERVICES_FETCHED,
    COLLAB_SERVICES_FETCHED,
    PLATFORM_SERVICES_FETCHED,
    ALL_FETCHED,
} from "./action-types";

const initialState = {
    isExpertMode: false,
    hasAccessToExpertMode: false,
    hasAccessToKWS: false,
    hasAccessToSR: false,
    hasAccessToASR: false,
    hasAccessToDM: false,
    hasAccessToTTS: false,
    production: { asrServices: [], dmServices: [], ttsServices: [] },
    staging: { asrServices: [], dmServices: [], ttsServices: [] },
    development: { asrServices: [], dmServices: [], ttsServices: [] },
    collab: { asrServices: [], dmServices: [], ttsServices: [] },
    platform: { asrServices: [], dmServices: [], ttsServices: [] },
    isDoNotAskAgain: false, // KWS config uses this flag while deleting keywords
    accessibleEnvs: [],
    allFetched: false,
};

// eslint-disable-next-line default-param-last
function rootReducer(state = initialState, action) {
    if (action.type === TOGGLE_EXPERTMODE) {
        return { ...state, isExpertMode: !state.isExpertMode };
    }
    if (action.type === ACCESS_EXPERTMODE) {
        return { ...state, hasAccessToExpertMode: action.payload };
    }
    if (action.type === ACCESS_KWS) {
        return { ...state, hasAccessToKWS: action.payload };
    }
    if (action.type === ACCESS_SR) {
        return { ...state, hasAccessToSR: action.payload };
    }
    if (action.type === ACCESS_ASR) {
        return { ...state, hasAccessToASR: action.payload };
    }
    if (action.type === ACCESS_DM) {
        return { ...state, hasAccessToDM: action.payload };
    }
    if (action.type === ACCESS_TTS) {
        return { ...state, hasAccessToTTS: action.payload };
    }
    if (action.type === SET_KWS_CONFIG_DO_NOT_ASK_AGAIN) {
        return { ...state, isDoNotAskAgain: action.payload };
    }
    if (action.type === ACCESSIBLE_ENVS) {
        return { ...state, accessibleEnvs: action.payload };
    }
    if (action.type === PRODUCTION_SERVICES_FETCHED) {
        return { ...state, production: action.payload };
    }
    if (action.type === STAGING_SERVICES_FETCHED) {
        return { ...state, staging: action.payload };
    }
    if (action.type === DEV_SERVICES_FETCHED) {
        return { ...state, development: action.payload };
    }
    if (action.type === COLLAB_SERVICES_FETCHED) {
        return { ...state, collab: action.payload };
    }
    if (action.type === PLATFORM_SERVICES_FETCHED) {
        return { ...state, platform: action.payload };
    }
    if (action.type === ALL_FETCHED) return { ...state, allFetched: action.payload };
    return state;
}

export default rootReducer;
