import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

function ResponsiveAppBar(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const { logoContent, leftItems, rightItems } = props;

    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={0} // remove the box shadow
            sx={{
                borderBottom: "1px solid teal",
            }}
        >
            <Box sx={{ marginLeft: "1%", marginRight: "1%" }}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                    >
                        {logoContent}
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: leftItems.length <= 1 ? "flex" : "none", md: "flex" },
                        }}
                    >
                        {leftItems}
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: leftItems.length > 1 ? "flex" : "none", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {leftItems.map((item) => (
                                <MenuItem key={item} onClick={handleCloseNavMenu}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                    >
                        {logoContent}
                    </Typography>

                    <Stack spacing={2} direction="row" sx={{ flexGrow: 0 }} alignItems="center">
                        {rightItems}
                    </Stack>
                </Toolbar>
            </Box>
        </AppBar>
    );
}

ResponsiveAppBar.propTypes = {
    logoContent: PropTypes.node.isRequired,
    leftItems: PropTypes.node.isRequired,
    rightItems: PropTypes.node.isRequired,
};

export default ResponsiveAppBar;
