/* eslint-disable react/forbid-prop-types */
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import { IconButton } from "@mui/material";
import keycloak from "../keycloak";
import ResponsiveAppBar from "./ResponsiveAppBar";
import AllingaLogo from "../assets/allinga-logo.png";
import AppsButton from "./AppsButton";
import CustomSnackbar from "../shared/components/CustomSnackbar";
import AccountOptions from "./AccountOptions";
import LanguageSelector from "./LanguageSelector";

const logoElement = (
    <Link to="/" className="brand-logo">
        <img
            className="valign-wrapper"
            style={{
                width: "60px",
                height: "60px",
            }}
            src={AllingaLogo}
            alt="Allinga"
        />
    </Link>
);

const appsButton = <AppsButton key="appsButton" />;

function AllingaAppBar() {
    const { t } = useTranslation();
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        setShowToast(true);
        setTimeout(() => {
            keycloak.logout();
            navigate("/");
        }, 1000);
    };

    const getUserName = () => {
        let userName = "";
        // Retrieve username/full name of logged in user from keycloak token
        // If name not given, then display username
        const name = keycloak?.idTokenParsed?.name;
        if (name && name !== "") userName = name;
        else userName = keycloak.idTokenParsed.preferred_username;

        return userName;
    };

    const bugReportButton = (
        <IconButton
            sx={{
                color: "#333333",
                "&:hover": {
                    color: "#4fc4a2",
                },
            }}
            key="bugReportButton"
            aria-label="report bug"
            component="a"
            href={process.env.REACT_APP_REPORT_BUG_URL}
            target="_blank"
            rel="noopener noreferrer"
            title={t("main.reportBug")}
        >
            <BugReportOutlinedIcon />
        </IconButton>
    );

    const languageSelector = <LanguageSelector key="language" />;
    const accountDropdown = (
        <AccountOptions key="account" username={getUserName()} onLogout={handleLogout} />
    );

    const leftItems = location.pathname === "/" ? [] : [appsButton];

    return (
        <>
            <ResponsiveAppBar
                logoContent={logoElement}
                leftItems={leftItems}
                rightItems={[bugReportButton, accountDropdown, languageSelector]}
            />
            <CustomSnackbar
                open={showToast}
                message={t("toasts.logout")}
                handleClose={() => setShowToast(false)}
                severity="info"
                autoHideDuration={3000} // close the toast after 3 seconds
            />
        </>
    );
}

export default AllingaAppBar;
