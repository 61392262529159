import { updateDashboardControls } from "./dashboard";
import spottingSoundFile from "../assets/spotting_sound.mp3";

/**
 * Function to get the formatted current date and time
 * E.g: "15-12-2020 14-26-59" (day-month-year hr-min-sec)
 */
const getTime = () => {
    const today = new Date();
    const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
    const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    return `${date}-${time}`;
};

/**
 * Function to open the given url in a new tab
 * @param {*} url - url of the page to be loaded in a new tab
 */
function openURL(url) {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
}

function getMessageFromDMEvent(dmEvent, messageID) {
    let messageText;
    let messageSender;
    if (dmEvent.text) {
        messageText = dmEvent.text;
        messageSender = "dm-text";
    } else if (dmEvent.event) {
        messageText = JSON.stringify(dmEvent.event, null, 2);
        messageSender = "dm-event";
    } else return null;

    return {
        sender: messageSender,
        id: messageID,
        text: messageText,
    };
}

function updateDialogState(dmEvents, oldMessages, firstNewMessageID, oldDashboardControls) {
    const updatedMessages = [...oldMessages];
    let updatedControls = oldDashboardControls;
    let newMessageID = firstNewMessageID;
    dmEvents?.forEach((dmEvent) => {
        const newMessage = getMessageFromDMEvent(dmEvent, newMessageID);
        if (newMessage) {
            newMessageID++;
            updatedMessages.push(newMessage);
        }

        if (dmEvent.event && dmEvent.event.device) {
            // car commands
            updatedControls = updateDashboardControls(dmEvent.event, updatedControls);
        }
    });

    return { messages: updatedMessages, dashboardControls: updatedControls };
}

const jingle = new Audio(spottingSoundFile);
/**
 * Plays a jingle. Returns a Promise which resolves after playback has finished.
 * @returns a Promise which resolves after the jingle has finished playing
 */
function playJingle() {
    jingle.currentTime = 0;
    jingle.play();
    return new Promise((resolve, reject) => {
        jingle.onended = resolve;
        jingle.onerror = reject;
    });
}

export { getTime, openURL, updateDialogState, playJingle };
