/**
 * If the users give permission, the app is able to access the audio input from the
 * users microphone.
 *
 * If the users don't give their permission or if an error occurred, the returned Promise
 * fails, which must be handled by the caller.
 *
 * @param {*} processAudioStreamFunction - callback function that is called with the audio stream from
 *                                  the microphone as parameter
 * @returns the Promise of a MediaStream
 */
function getAudioStreamFromMicrophone() {
    /* For setting the properties of audio tracks
       s. https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings */

    return navigator.mediaDevices.getUserMedia({
        audio: {
            /* Automatic gain control manages changes in the volume of its
                source media to maintain a steady overall volume level */
            autoGainControl: false,

            /* Noise suppression filters the audio to remove background noise,
                hum caused by equipment, and the like from the sound */
            noiseSuppression: true,

            /* Echo cancellation attempts to prevent echo effects on a two-way audio connection
                by attempting to reduce or eliminate crosstalk between the user's output device and
                their input device */
            echoCancellation: true,
        },
    });
}

export default getAudioStreamFromMicrophone;
