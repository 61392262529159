/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FormControl, Grid, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import { ReactComponent as KWSSvg } from "../../assets/pipeline/KWS.svg";
import { ReactComponent as ASRSvg } from "../../assets/pipeline/ASR.svg";
import { ReactComponent as DMSvg } from "../../assets/pipeline/DM.svg";
import { ReactComponent as TTSSvg } from "../../assets/pipeline/TTS.svg";
import { AssistantState, pipelineOptions, selectedPipelineProps } from "../../shared/constants";
import CustomTooltip from "../../shared/components/CustomTooltip";

/**
 * React component to display the visual representation of the pipeline in Assistant.
 * The visuals are updated based on the selected pipeline. The components are in blue when they are
 * part of the pipeline and in grey otherwise.
 */
function PipelineVisuals(props) {
    const {
        selectedPipeline,
        currentState,
        kwsSelectedEnv,
        asrSelectedEnv,
        asrSelectedService,
        dmSelectedEnv,
        dmSelectedService,
        ttsSelectedEnv,
        ttsSelectedService,
    } = props;

    const getDesc = (title, env, service) => {
        return (
            <Stack>
                <Typography>{title}</Typography>
                <Typography>Environment: {env}</Typography>
                {service ? <Typography>Service: {service}</Typography> : null}
            </Stack>
        );
    };

    const iconStyle = { width: "5rem", height: "5rem" };
    const enabledIconStyle = { ...iconStyle, color: "#337C99" };
    const activeIconStyle = { ...iconStyle, color: "#1C3F52" };
    const disabledIconStyle = { ...iconStyle, color: "#bdbdbd" };
    const componentNameStyle = { color: "#337C99" };
    const pipelineComponents = selectedPipeline.value;

    const { t } = useTranslation();

    const showComponentName = (name, service) => {
        return !pipelineComponents[name] ? ( // not in pipeline, show in grey
            <Typography sx={{ color: "#bdbdbd" }}>{name}</Typography>
        ) : !service ? ( // in pipeline but no service, show tooltip
            <CustomTooltip title={t("main.selectService")}>
                <Stack direction="row" alignItems="center">
                    <Typography sx={componentNameStyle}>{name}</Typography>
                    <IconButton sx={{ color: "#ffa501", padding: "0px" }}>
                        <WarningAmberOutlinedIcon fontSize="small" />
                    </IconButton>
                </Stack>
            </CustomTooltip>
        ) : (
            // in pipeline and has service, show in default color
            <Typography sx={componentNameStyle}>{name}</Typography>
        );
    };

    return (
        <Grid item xs={12} sm={6}>
            <Grid container justifyContent="center">
                <Grid item xs={2} sm={1} align="center">
                    <MicNoneOutlinedIcon
                        sx={{
                            width: "60%",
                            height: "60%",
                            paddingTop: "8px",
                        }}
                    />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Stack alignItems="center">
                        <CustomTooltip
                            title={getDesc("Keyword Spotter", kwsSelectedEnv)}
                            placement="top"
                        >
                            <SvgIcon
                                component={KWSSvg}
                                sx={
                                    pipelineComponents.KWS
                                        ? currentState === AssistantState.WAITING_FOR_KEYWORD
                                            ? {
                                                  ...activeIconStyle,
                                              }
                                            : {
                                                  ...enabledIconStyle,
                                              }
                                        : disabledIconStyle
                                }
                            />
                        </CustomTooltip>
                        <Typography sx={{ color: pipelineComponents.KWS ? "#337C99" : "#bdbdbd" }}>
                            KWS
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Stack alignItems="center">
                        <CustomTooltip
                            title={getDesc(
                                "Automatic Speech Recognition",
                                asrSelectedEnv,
                                asrSelectedService
                            )}
                            placement="top"
                        >
                            <SvgIcon
                                component={ASRSvg}
                                sx={
                                    pipelineComponents.ASR
                                        ? currentState === AssistantState.RECORDING_ASR
                                            ? {
                                                  ...activeIconStyle,
                                              }
                                            : {
                                                  ...enabledIconStyle,
                                              }
                                        : disabledIconStyle
                                }
                            />
                        </CustomTooltip>
                        {showComponentName("ASR", asrSelectedService)}
                    </Stack>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Stack alignItems="center">
                        <CustomTooltip
                            title={getDesc("Dialog Manager", dmSelectedEnv, dmSelectedService)}
                            placement="top"
                        >
                            <SvgIcon
                                component={DMSvg}
                                sx={
                                    pipelineComponents.DM
                                        ? currentState === AssistantState.WAITING_FOR_DM_RESPONSE
                                            ? {
                                                  ...activeIconStyle,
                                              }
                                            : {
                                                  ...enabledIconStyle,
                                              }
                                        : disabledIconStyle
                                }
                            />
                        </CustomTooltip>
                        {showComponentName("DM", dmSelectedService)}
                    </Stack>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Stack alignItems="center">
                        <CustomTooltip
                            title={getDesc("Text To Speech", ttsSelectedEnv, ttsSelectedService)}
                            placement="top"
                        >
                            <SvgIcon
                                component={TTSSvg}
                                sx={
                                    pipelineComponents.TTS
                                        ? currentState === AssistantState.PLAYING_RESPONSE
                                            ? {
                                                  ...activeIconStyle,
                                              }
                                            : {
                                                  ...enabledIconStyle,
                                              }
                                        : disabledIconStyle
                                }
                            />
                        </CustomTooltip>
                        {showComponentName("TTS", ttsSelectedService)}
                    </Stack>
                </Grid>
                <Grid item xs={2} sm={1} align="center">
                    <VolumeUpOutlinedIcon
                        sx={{
                            width: "60%",
                            height: "60%",
                            paddingTop: "8px",
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

PipelineVisuals.propTypes = {
    selectedPipeline: selectedPipelineProps.isRequired,
    currentState: PropTypes.number.isRequired,
    asrSelectedService: PropTypes.string,
    dmSelectedService: PropTypes.string,
    ttsSelectedService: PropTypes.string,
    kwsSelectedEnv: PropTypes.string,
    asrSelectedEnv: PropTypes.string,
    dmSelectedEnv: PropTypes.string,
    ttsSelectedEnv: PropTypes.string,
};

PipelineVisuals.defaultProps = {
    asrSelectedService: null,
    dmSelectedService: null,
    ttsSelectedService: null,
    kwsSelectedEnv: null,
    asrSelectedEnv: null,
    dmSelectedEnv: null,
    ttsSelectedEnv: null,
};

/**
 * React component to display all the possible pipeline options in a dropdown.
 */
function PipelineDropdown({ selectedPipeline, onPipelineChange, isStartDisabled }) {
    const { t } = useTranslation();

    const shouldDisableDropdown = isStartDisabled || pipelineOptions.length <= 1;

    return (
        <Stack>
            <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                {t("assistant.pipelineSetup")}
            </Typography>
            <FormControl
                variant="standard"
                sx={{ display: "block" }}
                disabled={shouldDisableDropdown}
            >
                <Select
                    sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                    disableUnderline
                    labelId={`${t("assistant.pipelineSetup")}-label`}
                    value={selectedPipeline}
                    onChange={(e) => onPipelineChange(e.target.value)}
                >
                    {pipelineOptions.map((item) => {
                        const labelText = item.name;
                        return (
                            <MenuItem key={labelText} value={item}>
                                {labelText}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Stack>
    );
}

PipelineDropdown.propTypes = {
    selectedPipeline: selectedPipelineProps.isRequired,
    onPipelineChange: PropTypes.func.isRequired,
    isStartDisabled: PropTypes.bool.isRequired, // check if audio processing is going on
};

export { PipelineDropdown, PipelineVisuals };
