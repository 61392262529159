import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import * as actions from "../../store/action-creators";
import AudioController from "../../services/audio/audio-controller";
import encodeAudioRecordingToAudioFormat from "../../services/audio/audio-encoder";
import getAudioStreamFromMicrophone from "../../services/audio/microphone";
import getRecordedBuffer from "../../services/audio/datastructure-handling";
import { getLanguage, isServicesUpdated, onServiceChange } from "../../services/service-handling";
import {
    AudioParameters,
    componentServiceProps,
    DefaultEnvironmentName,
    envProps,
} from "../../shared/constants";
import { saveRequests } from "../../services/history";
import ComponentWrapper from "../../shared/components/ComponentWrapper";
import LabeledCheckboxWithTooltip from "../../shared/components/LabeledCheckbox";
import ComponentDropdownSelections from "../../shared/components/ComponentDropdownSelections";
import ASRSpectrogramContainer from "./ASRSpectrogramContainer";
import { getASRWebsocketConnection } from "../../services/requests";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import ASRMessenger from "./ASRMessenger";

/**
 * ASR: Automatic Speech Recognition
 * This React component records user audio, formats it and sends it to the server
 * via web-sockets and displays the text received from the server.
 */
class ASR extends React.Component {
    constructor(props) {
        super(props);

        const initEnv =
            props.accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0] ||
            props.accessibleEnvs[0];
        const initService = props.services?.[initEnv?.name]?.[0];
        const initLanguage = initService?.languages?.[0];

        this.state = {
            localStream: null,
            messages: [],
            isStartDisabled: false,
            /* the ASR component is available/shown only if the ASR services have already loaded
            (refer to App.jsx), so the following two lines should always work */
            selectedService: initService,
            selectedLanguage: initLanguage,
            isMultipleUtterances: true,
            audioRequests: [],
            // set the default env(from the .env file) as the selected env on load
            selectedEnv: initEnv,
            showErrorToast: false,
            errorToastMessage: "",
            // this is to disable the text field and the send button if there's no service
            shouldDisableInputControls: !initService,
        };
        this.userAudioText = "";
        // counter used to update same ASR response bubble multiple times
        this.asrResponseCounter = 0;
        // audio vars
        this.bitDepth = AudioParameters.PCM16;
        this.numberOfChannels = AudioParameters.mono;
        this.bufferSize = 80;
        this.messagesCounter = 0;

        /* The array for the current audio recording consists of arrays that consist of an a
        number of Int16Array, where this number is equal to the number of recorded channels */
        this.currentAudioRecording = [];
        this.recordingLength = 0;
        this.sampleRate = 16000;

        this.audioController = new AudioController(
            this.processingAudioNodeOutput,
            this.sampleRate,
            this.changeSampleRate,
            this.bufferSize,
            this.numberOfChannels
        );
    }

    componentDidMount() {
        const { selectedService } = this.state;
        // if no service is available then set defaults
        if (!selectedService)
            this.setState({
                selectedService: {},
                selectedLanguage: "",
            });
    }

    // When services are updated, default service selection must be updated too
    componentDidUpdate(prevProps) {
        const { services, accessibleEnvs } = this.props;
        if (isServicesUpdated(prevProps.services, services)) {
            this.setDefaultServiceAndLanguage();
        }
        if (prevProps.accessibleEnvs !== accessibleEnvs) {
            this.setDefaultEnv();
        }
    }

    componentWillUnmount() {
        this.handleStop();
    }

    setDefaultEnv = () => {
        const { accessibleEnvs } = this.props;
        const initEnv = accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0];
        this.setState({
            selectedEnv: initEnv || accessibleEnvs[0],
        });
    };

    // set the default selected service
    setDefaultServiceAndLanguage = () => {
        const { selectedEnv } = this.state;
        if (selectedEnv) {
            const { services } = this.props;
            const selectedService = services[selectedEnv.name]?.[0] || {};
            const selectedLanguage = selectedService?.languages?.[0] || "";
            this.setState({
                selectedService,
                selectedLanguage,
                shouldDisableInputControls: Object.keys(selectedService).length === 0,
            });
        }
    };

    setAllDefaults = (env) => {
        const { services, t } = this.props;
        const { selectedService, selectedLanguage } = this.state;

        // check if previously selected service's flavor exists in the new one
        const newServices = services[env.name];
        let newSelectedService = newServices?.[0] || {};
        let newSelectedLanguage = newSelectedService?.languages?.[0] || "";

        if (newServices?.length > 0) {
            newServices.forEach((service) => {
                if (service.flavor === selectedService.flavor) {
                    newSelectedService = service;
                    // if service.flavor exists, then check if that service has same language too
                    newSelectedLanguage = getLanguage(service.languages, selectedLanguage);
                }
            });
        }

        if (Object.keys(newSelectedService).length === 0)
            this.setState({
                selectedEnv: env,
                selectedService: newSelectedService,
                selectedLanguage: newSelectedLanguage,
                showErrorToast: true, // set this to show the snackbar informing the user that there are no services
                // available for the selected env
                errorToastMessage: t("toasts.noServicesFound", {
                    value: env?.name || "",
                }),
                shouldDisableInputControls: true,
            });
        else
            this.setState({
                selectedEnv: env,
                selectedService: newSelectedService,
                selectedLanguage: newSelectedLanguage,
                showErrorToast: false,
                shouldDisableInputControls: false,
            });
    };

    /**
     * Function to update the state with the selected environment
     * @param {*} env - newly selected environment
     */
    onEnvChange = (env) => {
        const { isStartDisabled } = this.state;

        if (isStartDisabled) {
            const { t } = this.props;
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidEnvChange"),
            });
        } else {
            this.setAllDefaults(env);
        }
    };

    handleStart = () => {
        getAudioStreamFromMicrophone()
            .then((stream) => {
                this.record(stream);

                this.setState({
                    isStartDisabled: true,
                });
            })
            .catch(() => {
                const { t } = this.props;
                this.setState({
                    showErrorToast: true,
                    errorToastMessage: t("toasts.micPermission"),
                });
            });
    };

    handleStop = () => {
        // stop media stream
        this.setState({
            isStartDisabled: false,
            localStream: null,
        });
        // close audio nodes
        this.audioController.shutdown();
        // close client to backend
        if (this.client) this.client.close();
        // add recording to list of audio input recordings
        this.addRecordingAsAudioRequestEntry();
    };

    /**
     * Function to update the selected service and display a message to notify
     * the user about the change in service
     * @param {Number} value - index of the newly selected service
     */
    onServiceChange = (newSelectedService) => {
        const { isStartDisabled, messages, selectedService, selectedLanguage } = this.state;

        if (isStartDisabled) {
            const { t } = this.props;
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidServiceChange"),
            });
        } else {
            const response = onServiceChange(
                messages,
                selectedService,
                newSelectedService,
                this.messagesCounter++,
                ""
            );

            const newLang = getLanguage(newSelectedService.languages, selectedLanguage);

            this.setState({
                selectedService: newSelectedService,
                messages: response,
                selectedLanguage:
                    newLang !== "" ? newLang : newSelectedService.languages?.[0] || "",
            });
        }
    };

    /**
     * Function to update the state with the selected language
     * @param {*} newLanguage - object with name and value of the selected language
     */
    onLanguageChange = (newLanguage) => {
        const { isStartDisabled } = this.state;
        const { t } = this.props;
        if (isStartDisabled) {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.invalidLanguageChange"),
            });
        } else {
            this.setState({ selectedLanguage: newLanguage.value });
        }
    };

    getButtonsRow = () => {
        const { t } = this.props;
        const { isMultipleUtterances, isStartDisabled } = this.state;
        return (
            <Stack direction="row" spacing={1}>
                <LabeledCheckboxWithTooltip
                    tooltipText={t("main.continuousTranscriptionDescription")}
                    id="mulUtterances"
                    disabled={isStartDisabled}
                    value={String(isMultipleUtterances)}
                    checked={isMultipleUtterances}
                    onChange={this.toggleMultipleUtterances}
                    label={t("main.continuousTranscription")}
                />
            </Stack>
        );
    };

    getSpectrogramCol = () => {
        const { localStream } = this.state;

        return <ASRSpectrogramContainer localStream={localStream} />;
    };

    /**
     * Function to display dropdowns for services and languages
     * @returns JSX consisting of dropdowns for services and languages
     */

    toggleMultipleUtterances = () => {
        const { isMultipleUtterances } = this.state;
        this.setState({
            isMultipleUtterances: !isMultipleUtterances,
        });
    };

    /**
     * Take the array of audio of the current recording, create an AudioBlob in PCM/WAV
     * from them and add the URL of the newly created AudioBlob to the list of recorded
     * audio requests.
     *
     * The recorded AudioBuffers are encoded in PCM16.
     */
    addRecordingAsAudioRequestEntry = () => {
        const { audioRequests } = this.state;
        if (this.recordingLength > 0) {
            const format = AudioParameters.WAV;

            const audioBlob = encodeAudioRecordingToAudioFormat(
                format,
                this.currentAudioRecording,
                this.recordingLength,
                this.numberOfChannels,
                this.bufferSize,
                this.sampleRate
            );

            const audioURL = URL.createObjectURL(audioBlob);
            const updatedAudioRequests = saveRequests(audioURL, this.userAudioText, audioRequests);

            this.setState({ audioRequests: updatedAudioRequests });
            this.userAudioText = "";
            this.currentAudioRecording = [];
            this.recordingLength = 0;
        }
    };

    /**
     * Function to record chunks of user's audio input,
     * create a blob and send to server
     * @param {MediaStream} stream - refers to the user audio stream
     */
    record = async (stream) => {
        // save audio stream for later use
        this.setState({ localStream: stream });

        // set up audio nodes
        await this.audioController.initAudio(stream);
        // connect to backend
        this.connect();
    };

    /**
     * The output of the PCM processor is collected to be later on changed into an audio file
     * that can be listened to or downloaded by the users.
     *
     * The function adds an ArrayBuffer to an array of ArrayBuffers.
     * The new length of the collected audio is stored in a variable, that is incremented
     * by the size of the newly added buffer. The buffers can consist of several channels.
     *
     * @param {Int16Array} data - Int16Array containing an ArrayBuffer of PCM16 encoded audio
     */
    collectDataForAudioFile = (data) => {
        this.currentAudioRecording.push(getRecordedBuffer(data, this.numberOfChannels));
        this.recordingLength += this.bufferSize;
    };

    /**
     * Send the data to the backend, i.e. the ASR service
     *
     * @param {Int16Array} data - Int16Array containing an ArrayBuffer of PCM16 encoded audio
     */
    sendAudioToBackend = (data) => {
        if (this.client != null && this.client.readyState === WebSocket.OPEN)
            this.client.send(
                JSON.stringify({
                    audio: btoa(String.fromCharCode.apply(null, new Uint8Array(data.buffer))),
                })
            );
    };

    /**
     * Function that is given as callback function to the AudioController.
     * It controls how messages from the PCMProcessor are processed:
     * - send the audio to the backend (i.e. ASR service)
     * - collect the audio (so it can be used later on to be able to listen to the whole
     *      audio and/or download it as file)
     *
     * @param {MessageEvent} event - event that includes an array of the PCM data in two formats:
     *                      [PCM16, PCM32]. In the ASR, only PCM16 is needed.
     */
    processingAudioNodeOutput = (event) => {
        // use the audio in PCM16 to send to the backend
        this.sendAudioToBackend(event.data[0]);

        // collect the audio in PCM16 for creating an audio file later on
        this.collectDataForAudioFile(event.data[0]);
    };

    /**
     * Function to connect to the ASR websocket, consists of
     * event handlers for onopen, onclose, onmessage.
     * If a service is selected, then it is used to connect to
     * the service/namespace directly.
     */
    connect = () => {
        const { t } = this.props;
        const { selectedService, selectedLanguage, isMultipleUtterances, selectedEnv } =
            this.state;

        this.client = getASRWebsocketConnection(
            selectedService.flavor,
            selectedEnv.servicesEndpoint
        );

        this.client.onopen = () => {
            const config = {
                language: selectedLanguage,
                intermediateResults: true,
                multiple_utterances: isMultipleUtterances,
                audioFormat: {
                    encoding: this.bitDepth,
                    samplerate: this.sampleRate,
                },
                debug: false,
            };
            this.client.send(
                JSON.stringify({
                    config,
                })
            );
            /* eslint-disable no-console */
            console.log("ASR Websocket Client Connected");
        };

        this.client.onerror = (err) => {
            this.setState({
                showErrorToast: true,
                errorToastMessage: t("toasts.connectError"),
            });
            /* eslint-disable no-console */
            console.log(err);
        };

        this.client.onmessage = (asrResponse) => {
            const { messages } = this.state; // destructure
            const copyOfMessages = [...messages]; // make a shallow copy
            const response = JSON.parse(asrResponse.data);

            let msg = {};
            if (this.asrResponseCounter > 0) {
                // check if ASR response already added then just update
                // the same bubble
                msg = copyOfMessages.pop();
                msg.text = response.text;
            } else {
                msg = {
                    id: this.messagesCounter++,
                    sender: "asrIntermediate",
                    text: response.text,
                };
            }
            this.asrResponseCounter++;

            if (response.utteranceFinished) {
                // if utterance finished, update the sender
                // so the message bubble color of the last message changes
                msg.sender = "asr";
                this.asrResponseCounter = 0;

                /* take the last response as user audio text;
                if the input consists of multiple messages, the messages are joined by an arrow */
                if (isMultipleUtterances) {
                    if (!this.userAudioText) {
                        this.userAudioText = response.text;
                    } else {
                        this.userAudioText += ` -> ${response.text}`;
                    }
                } else {
                    this.userAudioText = response.text;
                }
            }

            copyOfMessages.push(msg);
            this.setState({ messages: copyOfMessages });
        };

        this.client.onclose = () => {
            /* eslint-disable no-console */
            console.log("ASR websocket connection closed!!");
            this.handleStop();
        };
    };

    /**
     * Function to change the sampleRate. This function is intended to
     * give to the AudioController as callback Function.
     * @param {*} newSampleRate
     */
    changeSampleRate = (newSampleRate) => {
        this.sampleRate = newSampleRate;
    };

    onHandleCloseSnackbar = () => {
        this.setState({ showErrorToast: false });
    };

    // Function to delete history and also all the saved audio and text in "view requests"
    handleDeleteHistory = () => {
        this.setState({ messages: [], audioRequests: [] });
    };

    render() {
        const { services, accessibleEnvs } = this.props;
        const {
            isStartDisabled,
            selectedService,
            selectedLanguage,
            messages,
            selectedEnv,
            showErrorToast,
            localStream,
            errorToastMessage,
            audioRequests,
            shouldDisableInputControls,
        } = this.state;
        const messengerPanel = (
            <ASRMessenger
                handleStart={this.handleStart}
                handleStop={this.handleStop}
                messages={messages}
                isStartDisabled={isStartDisabled}
                localStream={localStream}
                audioData={audioRequests}
                shouldDisableInputControls={shouldDisableInputControls}
                handleDeleteHistory={this.handleDeleteHistory}
            />
        );

        return (
            <>
                <ComponentWrapper
                    title="Automatic Speech Recognition"
                    leftMainCol={messengerPanel}
                    rightMainCol={this.getSpectrogramCol()}
                >
                    <Grid container spacing={1}>
                        {selectedService && selectedEnv ? (
                            <ComponentDropdownSelections
                                selectedService={selectedService}
                                selectedLanguage={selectedLanguage}
                                onServiceChange={this.onServiceChange}
                                onLanguageChange={this.onLanguageChange}
                                name="ASR"
                                services={services[selectedEnv?.name]}
                                selectedEnv={selectedEnv}
                                onEnvChange={this.onEnvChange}
                                accessibleEnvs={accessibleEnvs}
                            />
                        ) : null}
                    </Grid>
                    {this.getButtonsRow()}
                </ComponentWrapper>
                <CustomSnackbar
                    open={showErrorToast}
                    message={errorToastMessage}
                    handleClose={this.onHandleCloseSnackbar}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        services: {
            // get a dictionary of ASR services across all the envs
            production: state.production.asrServices,
            staging: state.staging.asrServices,
            development: state.development.asrServices,
            collab: state.collab.asrServices,
            platform: state.platform.asrServices,
        },
        accessibleEnvs: state.accessibleEnvs,
    };
}

export default compose(withTranslation("translation"), connect(mapStateToProps, actions))(ASR);

ASR.propTypes = {
    t: PropTypes.func.isRequired,
    services: componentServiceProps.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
};
