import PropTypes from "prop-types";
import Button from "@mui/material/Button";

/**
 * React functional component that displays a button that performs the secondary action
 * i.e the action that is less important compared to the primary action of the given module */
function SecondaryButton(props) {
    const { children } = props;
    return (
        <Button
            {...props}
            variant="outlined"
            sx={{ borderRadius: 0, fontWeight: 500, fontSize: "14px", textTransform: "inherit" }}
        >
            {children}
        </Button>
    );
}

export default SecondaryButton;

SecondaryButton.propTypes = {
    children: PropTypes.string.isRequired,
};
