import { useState } from "react";
import { Card, CardActions, CardContent, CardHeader, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CodeIcon from "@mui/icons-material/Code";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import PropTypes from "prop-types";
import CustomTooltip from "./CustomTooltip";
import AudioListModal from "./AudioListModal";
import DeleteHistoryNotification from "./DeleteHistoryNotification";
import CustomSnackbar from "./CustomSnackbar";

/**
 * React component to display containers used for displaying the message box, the spectrograms etc.
 * partial: controls the height of the container. If true, a shorter container is displayed else a full container is displayed.
 */
function CardPanel(props) {
    const {
        partial,
        title,
        children,
        footer,
        shouldShowEventsToggle,
        shouldShowDownload,
        handleDownload,
        toggleEvents,
        shouldShowEvents,
        shouldShowRequestIcon,
        shouldShowResponseIcon,
        audioRequests,
        audioResponses,
        handleDeleteHistory,
        shouldDisableDelete,
        shouldHideDelete,
    } = props;

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [showInfoToast, setShowInfoToast] = useState(false);

    const fullHeight = "480px";
    const partialHeight = "230px";

    const cardPanelStyle = {
        border: "1px solid grey",
        borderRadius: "5px",
        whiteSpace: "pre-wrap",
        boxShadow: "none",
        height: partial ? partialHeight : fullHeight,
    };

    const onDeleteHistoryClick = () => {
        setOpen(true);
    };

    const handleDeleteClick = () => {
        handleDeleteHistory();
        setOpen(false);
        setShowInfoToast(true);
    };

    return (
        <>
            <Card sx={cardPanelStyle}>
                {title ? (
                    <CardHeader
                        sx={{
                            borderBottom: "1px solid grey",
                            padding: "16px",
                            textAlign: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                            "& .MuiCardHeader-title": { fontSize: "16px", fontWeight: "bold" },
                        }}
                        title={title}
                        action={
                            <div style={{ display: "inline-flex" }}>
                                {shouldShowRequestIcon ? (
                                    <AudioListModal
                                        audioData={audioRequests}
                                        buttonName={t("messageBoxButtons.viewASRRequests")}
                                        modalTitle={t("main.audioRequests")}
                                        isRequest
                                    />
                                ) : null}
                                {shouldShowResponseIcon ? (
                                    <AudioListModal
                                        audioData={audioResponses}
                                        buttonName={t("messageBoxButtons.viewTTSResponses")}
                                        modalTitle={t("main.audioResponses")}
                                    />
                                ) : null}
                                {shouldShowEventsToggle && shouldShowEvents ? (
                                    <CustomTooltip title={t("main.hideEvents")}>
                                        <IconButton
                                            aria-label="events"
                                            sx={{
                                                padding: "0px 5px 0px 5px",
                                            }}
                                            onClick={toggleEvents}
                                        >
                                            <CodeOffIcon sx={{ color: "teal" }} />
                                        </IconButton>
                                    </CustomTooltip>
                                ) : null}
                                {shouldShowEventsToggle && !shouldShowEvents ? (
                                    <CustomTooltip title={t("main.showEvents")}>
                                        <IconButton
                                            aria-label="events"
                                            sx={{
                                                padding: "0px 5px 0px 5px",
                                            }}
                                            onClick={toggleEvents}
                                        >
                                            <CodeIcon sx={{ color: "teal" }} />
                                        </IconButton>
                                    </CustomTooltip>
                                ) : null}
                                {shouldShowDownload ? (
                                    <CustomTooltip title={t("messageBoxButtons.downloadHistory")}>
                                        <IconButton
                                            sx={{
                                                padding: "0px 5px 0px 5px",
                                            }}
                                            aria-label="download"
                                            onClick={handleDownload}
                                        >
                                            <FileDownloadOutlinedIcon sx={{ color: "teal" }} />
                                        </IconButton>
                                    </CustomTooltip>
                                ) : null}
                                {!shouldHideDelete ? (
                                    <CustomTooltip title={t("messageBoxButtons.deleteHistory")}>
                                        <IconButton
                                            sx={{
                                                padding: "0px 5px 0px 5px",
                                            }}
                                            aria-label="download"
                                            onClick={onDeleteHistoryClick}
                                            disabled={shouldDisableDelete}
                                        >
                                            <DeleteOutlineOutlinedIcon
                                                sx={{
                                                    color: shouldDisableDelete ? "grey" : "teal",
                                                }}
                                            />
                                        </IconButton>
                                    </CustomTooltip>
                                ) : null}
                            </div>
                        }
                    />
                ) : null}

                <CardContent>{children}</CardContent>
                {footer ? (
                    <CardActions
                        sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                            height: "80px",
                            borderTop: "1px solid grey",
                        }}
                    >
                        {footer}
                    </CardActions>
                ) : null}
            </Card>
            {open ? (
                <DeleteHistoryNotification
                    onCloseClick={() => setOpen(false)}
                    onDeleteClick={handleDeleteClick}
                />
            ) : null}
            <CustomSnackbar
                open={showInfoToast}
                message={t("main.deleteHistoryConfirmationAlert")}
                handleClose={() => setShowInfoToast(false)}
                severity="info"
            />
        </>
    );
}

CardPanel.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    footer: PropTypes.node,
    shouldShowDownload: PropTypes.bool,
    shouldShowEventsToggle: PropTypes.bool,
    handleDownload(props, propName) {
        if (
            props.shouldShowDownload &&
            (props[propName] === undefined || typeof props[propName] !== "function")
        ) {
            return new Error("Please provide a handleDownload function!");
        }
        return null;
    },
    toggleEvents(props, propName) {
        if (
            props.shouldShowEventsToggle &&
            (props[propName] === undefined || typeof props[propName] !== "function")
        ) {
            return new Error("Please provide a handleEventsToggle function!");
        }
        return null;
    },
    partial: PropTypes.bool,
    shouldShowEvents: PropTypes.bool,
    shouldShowRequestIcon: PropTypes.bool,
    shouldShowResponseIcon: PropTypes.bool,
    audioRequests(props, propName) {
        if (props.shouldShowRequestIcon && props[propName] === undefined)
            return new Error("Please provide audio data for displaying the audio requests");
        return null;
    },
    audioResponses(props, propName) {
        if (props.shouldShowResponseIcon && props[propName] === undefined)
            return new Error("Please provide audio data for displaying the audio responses");
        return null;
    },
    handleDeleteHistory: PropTypes.func,
    shouldDisableDelete: PropTypes.bool,
    shouldHideDelete: PropTypes.bool,
};

CardPanel.defaultProps = {
    title: "",
    footer: null,
    shouldShowDownload: false,
    shouldShowEventsToggle: false,
    handleDownload: null,
    partial: false,
    toggleEvents: null,
    shouldShowEvents: true,
    shouldShowRequestIcon: false,
    shouldShowResponseIcon: false,
    audioRequests: [],
    audioResponses: [],
    shouldDisableDelete: false,
    shouldHideDelete: false,
    handleDeleteHistory: null,
};

export default CardPanel;
