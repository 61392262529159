import i18n from "../i18n";
import { NewServiceParameters } from "../shared/constants";
import { getDEDeployments } from "./requests";

/**
 * Function to create a custom message to inform the user everytime
 * a service selection is changed.
 * @param {*} oldService the previously selected service
 * @param {*} newService the newly selected service
 * @param {String} componentName - (optional) name of the component
 * @returns a string informing the user of the change in service selection.
 * output example:
    1. without componentName: The service has been changed from dbahn / dbahn-asr-de to speaker / asr-de
    2. with componentName: The ASR service has been changed from dbahn / dbahn-asr-de to speaker / asr-de
 */
function getServiceChangeMessage(oldService, newService, componentName = "") {
    return i18n.t("main.serviceChanged", {
        oldService: oldService.flavor,
        newService: newService.flavor,
        componentName,
    });
}

/**
 * Function to fetch the description of a service to be shown in the info button
 * @param {*} service the service
 * @param {*} language desired language of the description
 * @returns - dictionary with description of the service in the selected language
 */
function getServiceDescription(service, language = "de") {
    let result = "";
    switch (language) {
        case "en":
            result = service.descriptionEn;
            break;
        case "de":
            result = service.descriptionDe;
            break;
        default:
            result = "";
    }
    return result || "";
}

function onServiceChange(
    serviceMessages,
    oldService,
    newService,
    messagesCounter,
    componentName = ""
) {
    const messages = [...serviceMessages];
    let msg = {};
    msg = {
        id: messagesCounter,
        sender: "system",
        text: getServiceChangeMessage(oldService, newService, componentName),
    };
    messages.push(msg);

    return messages;
}

/**
 * Function to rearrange the services such that the service with flavor "default" is set
 * at position 0 so that it is selected as the default service on load
 * @param {*} services - services available
 * @returns - rearranged services with default at position 0, if "default" flavor exists,
 * else return the same services as input.
 */
function setDefaultServiceAsDefault(services) {
    const isDefault = (element) => element.flavor === "default";
    const indexOfDefault = services.findIndex(isDefault);
    if (indexOfDefault !== -1) {
        const defaultService = services.splice(indexOfDefault, 1)[0];
        services.splice(0, 0, defaultService);
    }
    return services;
}

function extractASRServices(response) {
    if (response.error) {
        return [];
    }
    const { flavor, languages, description } = NewServiceParameters;
    const newServices = []; // list so that order is maintained

    const { models } = response;
    // loop thru response and create a dictionary of required values
    for (let i = 0; i < models?.length; i++) {
        const service = {};
        service[flavor] = models[i].model;
        service[languages] = models[i].languages;
        service[description] = models[i].description ? models[i].description : "";
        newServices.push(service);
    }
    return setDefaultServiceAsDefault(newServices);
}

/**
 * Function to check if the given chatbotName is a DE deployment
 * @param {*} deployments - DE deployments
 * @param {*} chatbotName - chatbot name or flavor received from DM enumerate
 * @returns an object with the DE deployment name and id, if exists else returns the same chatbot name given as input
 */
function doesDEDeploymentNameMatch(deployments, chatbotName) {
    const exists = deployments.filter((d) => d.engineFactoryId === chatbotName);
    if (exists.length > 0 && exists[0].state === "deployed")
        return { name: exists[0].name, id: exists[0].engineFactoryId };
    return { name: chatbotName, id: "" };
}

function getDefaultDMServices(chatbots) {
    const newServices = [];
    const { flavor, languages, description, deDeploymentId } = NewServiceParameters;
    // loop thru response and create a dictionary of required values
    for (let i = 0; i < chatbots?.length; i++) {
        const service = {};
        service[flavor] = chatbots[i].dialogueId;
        service[deDeploymentId] = "";
        service[languages] = chatbots[i].languages;
        service[description] = chatbots[i].description || "";
        newServices.push(service);
    }
    return setDefaultServiceAsDefault(newServices);
}

async function extractDMServices(response, isStaging) {
    // TODO: currently getting the DE deployments only if the env is 'staging'
    if (response.error) {
        return [];
    }
    const { flavor, languages, description, deDeploymentId } = NewServiceParameters;
    const newServices = []; // list so that order is maintained

    const { chatbots } = response;
    let resp = [];

    if (isStaging) {
        try {
            resp = await getDEDeployments();
            // loop thru response and create a dictionary of required values
            for (let i = 0; i < chatbots?.length; i++) {
                const service = {};
                const deData = doesDEDeploymentNameMatch(resp, chatbots[i].dialogueId);
                service[flavor] = deData.name;
                service[deDeploymentId] = deData.id;
                service[languages] = chatbots[i].languages;
                service[description] = chatbots[i].description ? chatbots[i].description : "";
                newServices.push(service);
            }
            return setDefaultServiceAsDefault(newServices);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log("Error occurred while fetching DE deployments: ", e);
            return getDefaultDMServices(chatbots);
        }
    } else {
        return getDefaultDMServices(chatbots);
    }
}

function extractTTSServices(response) {
    if (response.error) {
        return [];
    }
    const { flavor, languages, description, voiceCollection } = NewServiceParameters;
    const newServices = []; // list so that order is maintained

    const { flavors } = response;
    // loop thru response and create a dictionary of required values
    for (let i = 0; i < flavors?.length; i++) {
        const service = {};
        const languagesNames = [];
        const tempVoiceCollection = [];

        flavors?.[i]?.languages?.forEach((l) => {
            languagesNames.push(l.name);
            tempVoiceCollection.push({ language: l.name, voices: l.voices });
        });

        service[flavor] = flavors[i].name;
        service[languages] = languagesNames;
        service[description] = flavors[i].description || "";
        service[voiceCollection] = tempVoiceCollection;
        newServices.push(service);
    }
    return setDefaultServiceAsDefault(newServices);
}

/**
 * Function to get the list of common but unique languages between two services
 * @param serviceA the first service
 * @param serviceB the second service
 * @returns list of common but unique languages
 */
function getCommonLanguages(serviceA, serviceB) {
    const aLanguages = serviceA.languages;
    const bLanguages = serviceB.languages;
    const commonLanguages = aLanguages.filter((lang) => bLanguages.includes(lang));
    return commonLanguages;
}

/**
 * Function to check if the two given services differ.
 * Since the services now contain information from different environments, it is necessary to check
 * each of those lists to ensure if something has changed.
 * @param {*} oldServices - existing list of services from all environments
 * @param {*} newServices - new list of services from all environments
 * @returns boolean: true, if there is a difference; else, false.
 */
function isServicesUpdated(oldServices, newServices) {
    if (oldServices.production !== newServices.production) {
        return true;
    }
    if (oldServices.staging !== newServices.staging) {
        return true;
    }
    if (oldServices.collab !== newServices.collab) {
        return true;
    }
    if (oldServices.development !== newServices.development) {
        return true;
    }
    return false;
}

function getVoice(selectedService, selectedLanguage, selectedVoice) {
    let newSelectedVoice = "";
    const tempVoiceCollection = selectedService?.voiceCollection?.find(
        (e) => e.language === selectedLanguage
    );

    if (tempVoiceCollection) {
        const { voices } = tempVoiceCollection;

        const indexIfVoiceExists = voices?.indexOf(selectedVoice);
        if (indexIfVoiceExists !== -1) newSelectedVoice = voices[indexIfVoiceExists];
        // eslint-disable-next-line prefer-destructuring
        else newSelectedVoice = voices?.[0];

        return newSelectedVoice;
    }
    return "";
}

function getLanguage(languages, selectedLanguage) {
    let newSelectedLanguage = "";
    const indexIfLanguageExists = languages?.indexOf(selectedLanguage);
    if (indexIfLanguageExists && indexIfLanguageExists !== -1) {
        newSelectedLanguage = languages[indexIfLanguageExists];
    } else newSelectedLanguage = languages?.[0] || "";

    return newSelectedLanguage;
}

export {
    getServiceDescription,
    onServiceChange,
    extractASRServices,
    extractDMServices,
    extractTTSServices,
    getCommonLanguages,
    isServicesUpdated,
    getVoice,
    // getVoiceHelper,
    getLanguage,
};
