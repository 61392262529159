import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import "../../styles/appStyle.css";
import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect";

/**
 * React functional component to display dropdown values (of voices)
 * @param {*} props - consists of voice index and callback function
 * @returns JSX of dropdown with voices
 */
function VoiceDropdown(props) {
    const { name, selectedVoice, onVoiceChange, voices } = props;

    const { t } = useTranslation();

    return voices ? (
        <Stack>
            <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                {t("main.voice")}
            </Typography>
            <CustomSelect
                items={voices}
                name={name}
                value={selectedVoice}
                onSelected={onVoiceChange}
            />
        </Stack>
    ) : null;
}

export default VoiceDropdown;

VoiceDropdown.propTypes = {
    name: PropTypes.string,
    onVoiceChange: PropTypes.func.isRequired,
    selectedVoice: PropTypes.string.isRequired,
    voices: PropTypes.arrayOf(PropTypes.string),
};

VoiceDropdown.defaultProps = {
    name: "VoiceDropdown",
    voices: [],
};
