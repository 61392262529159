/**
 * Function to check if the keyword name already exists in the config table
 * If yes, it creates a new name by appending "_<counter>"
 * If no, it returns the keyword name as is
 * @param {*} name
 * @returns
 */
const getUniqueKeywordName = (name, configTable, repeatedKeyCounter) => {
    let newName = name;
    const repeatedKeyCounterCopy = { ...repeatedKeyCounter };

    const existing = configTable.filter((item) => item.name === name);
    if (existing?.length > 0) {
        // add the keyname to repeatedKeyCounter, increment counter if keyname exists else set to 1
        if (repeatedKeyCounterCopy[name]) repeatedKeyCounterCopy[name] += 1;
        else repeatedKeyCounterCopy[name] = 1;
        newName = `${name}_${repeatedKeyCounterCopy[name]}`;
        return getUniqueKeywordName(newName, configTable, repeatedKeyCounterCopy);
    }
    return {
        newName,
        repeatedKeyCounter: repeatedKeyCounterCopy,
    };
};

// Function to get all the enrollments from the given list that can then be appended to the config table
const getEnrollmentsToAppend = (result, configTable, repeatedKeyCounter) => {
    let newRepeatedKeyCounter = { ...repeatedKeyCounter };
    const enrollments = [];

    // check if result is a list
    if (!result.length)
        throw new Error(
            "Imported file content does not match the structure of the KWS configuration"
        );

    try {
        for (let index = 0; index < result.length; index++) {
            // check if the json entries matches the expected structure of
            // KWS config (name, threshold and keyword)
            if (result[index]?.name && result[index]?.keyword && result[index]?.threshold) {
                const enrollment = result[index];
                enrollment.audioUrls = ["-"];
                enrollment.threshold = enrollment.threshold.toString();

                const newInfo = getUniqueKeywordName(
                    enrollment.name,
                    configTable,
                    newRepeatedKeyCounter
                );
                enrollment.name = newInfo.newName;
                newRepeatedKeyCounter = newInfo.repeatedKeyCounter;
                enrollments.push(enrollment);
            } else {
                throw new Error(
                    "Imported file content does not match the structure of the KWS configuration"
                );
            }
        }
    } catch (error) {
        /* eslint-disable no-console */
        console.log(error);
    }

    return { enrollments, repeatedKeyCounter: newRepeatedKeyCounter };
};

export default getEnrollmentsToAppend;
