import { useState } from "react";
import { Typography, Stack, Box } from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "../../styles/appStyle.css";
import keycloak from "../../keycloak";
import { getAudioDuration } from "../../services/audio/audio-processing";
import { KWSAudioDuration } from "../../shared/constants";

import UploadAudioFile from "../../shared/components/UploadAudioFile";
import EnrollButton from "./EnrollButton";

/**
 * React component that provides the functionality to upload an audio file
 * containing a keyword.
 * Note: The audio file may be only up to 2s long.
 */
function KeywordUploadTab(props) {
    const [keywordName, setKeywordName] = useState("");
    const [enrolledFiles, setEnrolledFiles] = useState(null);
    const [enrolledAudioUrls, setEnrolledAudioUrls] = useState([]);
    const [filenameKey, setFilenameKey] = useState(Date.now());
    const [fileUploadName, setFileUploadName] = useState("");

    const { t } = useTranslation();
    const [showToast, setShowToast] = useState(false);
    const resetState = () => {
        setKeywordName("");
        setEnrolledFiles(null);
        setEnrolledAudioUrls([]);
        setFilenameKey(Date.now());
    };
    const onUpload = (e) => {
        // return if no files selected
        const numFiles = e.target.files.length;
        if (numFiles === 0) return;

        // construct form data
        const fd = new FormData();
        const enrolledAudioUrlsTmp = [];
        // set the name of the first file (if many, else only file) as default for keyword name
        const firstFileName = e.target.files[0].name;
        const keywordNameTmp =
            firstFileName.substr(0, firstFileName.lastIndexOf(".")) || firstFileName;
        setFileUploadName(keywordNameTmp);
        setKeywordName(keywordNameTmp);

        for (let i = 0; i < numFiles; i++) {
            if (!e.target.files[i].type.startsWith("audio/")) {
                setShowToast(true);
                resetState();
                return;
            }
            const fileName = `file${i.toString()}`;
            enrolledAudioUrlsTmp.push(URL.createObjectURL(e.target.files[i]));
            fd.append(fileName, e.target.files[i]);
        }

        setEnrolledFiles(fd);
        setEnrolledAudioUrls(enrolledAudioUrlsTmp);
    };

    /**
     * Validates the uploaded audio files, especially checking if all files have
     * a maximum duration of KWSAudioDuration.
     * @returns a Promise that resolves when all files are valid and is rejected otherwise
     */
    const validateAudioFiles = async () => {
        // check if file is uploaded
        if (!enrolledFiles) {
            throw new Error(t("toasts.uploadFile"));
        }

        // check if audio length is <=2s
        const durationPromises = enrolledAudioUrls.map(async (url) => {
            const duration = await getAudioDuration(url);
            if (duration > KWSAudioDuration) {
                resetState();
                throw new Error(t("toasts.audioTooLong", { maxDuration: KWSAudioDuration }));
            }
            return duration;
        });

        return Promise.all(durationPromises);
    };

    /**
     * Function to enroll keywords from the uploaded files
     */
    const getEnrollment = async () => {
        await validateAudioFiles();
        const { enrollEndpoint } = props;
        // construct request
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
            body: enrolledFiles,
        };

        try {
            const response = await fetch(enrollEndpoint, requestOptions);

            if (response.ok) {
                const result = await response.json();
                const keywords = [];
                for (let i = 0; i < result.keywords.length; i++) {
                    keywords.push(result.keywords[i].join("."));
                }
                const keyword = keywords.join(";");
                const enrolledThreshold = result.threshold.toString();

                if (keyword !== "" && enrolledThreshold !== "") {
                    const enrollment = {
                        name: keywordName,
                        audioUrls: enrolledAudioUrls,
                        keyword,
                        threshold: enrolledThreshold,
                    };

                    resetState();
                    return enrollment;
                }
            } else if (response.status === 413) throw new Error(t("toasts.audioOver2s"));
            else throw new Error(await response.text());
        } catch (err) {
            resetState();
            throw err;
        }

        return null;
    };

    const { onEnroll, configTable } = props;

    return (
        <>
            <UploadAudioFile
                name={keywordName}
                onNameChange={setKeywordName}
                filenameKey={filenameKey}
                fileUploadName={fileUploadName}
                showToast={showToast}
                setShowToast={setShowToast}
                onUpload={onUpload}
                label={t("kws.addKeywordName")}
            />
            <Box display="flex" justifyContent="center" margin="28px" marginLeft="0px">
                <Stack gap={1} direction="row">
                    <TipsAndUpdatesOutlinedIcon sx={{ color: "rgba(0, 100, 73, 1)" }} />
                    <Typography fontWeight={400} fontSize={14}>
                        {t("kws.enrollNote")}
                    </Typography>
                </Stack>
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <EnrollButton
                    getEnrollment={getEnrollment}
                    onEnroll={onEnroll}
                    configTable={configTable}
                    keywordName={keywordName}
                    setKeywordName={setKeywordName}
                    enrollTooltip={t("kws.enrollDescription")}
                />
            </Box>
        </>
    );
}

export default KeywordUploadTab;

KeywordUploadTab.propTypes = {
    enrollEndpoint: PropTypes.string.isRequired,
    onEnroll: PropTypes.func.isRequired,
    configTable: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            audioUrls: PropTypes.arrayOf(PropTypes.string),
            keyword: PropTypes.string,
            threshold: PropTypes.string,
        })
    ).isRequired,
};
