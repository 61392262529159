import Keycloak from "keycloak-js";

/**
 * Update the Realm and Client values here.
 * Values can be found at Client Settings => Installation in Keycloak.
 */
const keycloak = new Keycloak({
    realm: "Speaker",
    url: process.env.REACT_APP_KEYCLOAK_URL,
    clientId: "speaker-platform",
});

export default keycloak;
