import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { envProps } from "../constants";
import CustomSelect from "./CustomSelect";
import "../../styles/appStyle.css";

/**
 * React functional component to display dropdown values (of environments)
 * @param {List<dictionary>} props - consists of details of environments available
 * @returns JSX of dropdown with environments
 */
function EnvironmentDropdown(props) {
    const { envs, name, selectedEnv, onEnvChange, customLabel } = props;
    const { t } = useTranslation();

    return envs.length > 0 ? (
        <Stack>
            <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                {customLabel || t("main.environment")}
            </Typography>
            <CustomSelect
                items={envs}
                itemToOptionLabel={(item) => item.name}
                name={name}
                value={selectedEnv}
                onSelected={onEnvChange}
            />
        </Stack>
    ) : null;
}

export default EnvironmentDropdown;

EnvironmentDropdown.propTypes = {
    envs: PropTypes.arrayOf(envProps).isRequired,
    name: PropTypes.string,
    selectedEnv: envProps.isRequired,
    onEnvChange: PropTypes.func.isRequired,
    customLabel: PropTypes.string,
};

EnvironmentDropdown.defaultProps = {
    name: "EnvironmentDropdown",
    customLabel: null,
};
