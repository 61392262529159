import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { keywordProps, kwsConfigTableProps } from "../constants";
import CustomSelect from "./CustomSelect";

// Component to display the keywords available in a dropdown
function KeywordDropdown({ configTable, selectedKeyword, onKeywordChange }) {
    return (
        <Stack>
            <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                Keyword
            </Typography>
            <CustomSelect
                items={configTable}
                itemToOptionLabel={(item) => item.name}
                value={selectedKeyword}
                name="kwsConfig"
                onSelected={onKeywordChange}
            />
        </Stack>
    );
}

export default KeywordDropdown;

KeywordDropdown.propTypes = {
    configTable: kwsConfigTableProps.isRequired,
    onKeywordChange: PropTypes.func.isRequired,
    selectedKeyword: keywordProps.isRequired,
};
