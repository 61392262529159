import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import CardPanel from "../../shared/components/CardPanel";
import "../../styles/appStyle.css";

function KWSTriggerAndScore({ data }) {
    const [triggerAndScore, setTriggerAndScore] = useState([" ", " ", " ", " ", " ", " "]);

    const updateTriggerAndScore = (newData) => {
        if (newData) {
            triggerAndScore.shift();
            if (newData === "") triggerAndScore.push(" ");
            else triggerAndScore.push(newData);
        }
        setTriggerAndScore(triggerAndScore);
    };

    useEffect(() => {
        updateTriggerAndScore(data);
    }, [data]);

    const { t } = useTranslation();

    return (
        <CardPanel shouldHideDelete partial title={t("kws.triggerAndScore")}>
            {triggerAndScore.join("\n")}
        </CardPanel>
    );
}

KWSTriggerAndScore.propTypes = {
    data: PropTypes.string.isRequired,
};

/**
 * React Component to display the live transcript (output)
 */
function KWSLiveTranscript({ data }) {
    const [liveTranscript, setLiveTranscript] = useState([" ", " ", " ", " ", " ", " "]);

    const updateLiveTranscript = (newData) => {
        if (newData) {
            liveTranscript.shift();
            if (newData === "") liveTranscript.push(" ");
            else liveTranscript.push(newData);
        }
        setLiveTranscript(liveTranscript);
    };

    useEffect(() => {
        updateLiveTranscript(data);
    }, [data]);

    const { t } = useTranslation();

    return (
        <CardPanel shouldHideDelete partial title={t("kws.liveTranscript")}>
            {liveTranscript.join("\n")}
        </CardPanel>
    );
}

KWSLiveTranscript.propTypes = {
    data: PropTypes.string,
};

KWSLiveTranscript.defaultProps = {
    data: "",
};

export { KWSTriggerAndScore, KWSLiveTranscript };
