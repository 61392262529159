import { useState } from "react";
import Stack from "@mui/material/Stack";
import CachedIcon from "@mui/icons-material/Cached";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import * as actions from "../../store/action-creators";
import SecondaryButton from "../../shared/components/SecondaryButton";
import CustomSpinner from "../../shared/components/CustomSpinner";
import ComponentTile from "./ComponentTile";

function Homepage(props) {
    const [isReloading, setIsReloading] = useState(false);

    const {
        showReload,
        hasAccessToKWS,
        hasAccessToSR,
        hasAccessToASR,
        hasAccessToDM,
        hasAccessToTTS,
    } = props;
    const { t } = useTranslation();

    const getComponentsCount = () => {
        let total = 0;
        if (hasAccessToASR) total += 1;
        if (hasAccessToDM) total += 1;
        if (hasAccessToTTS) total += 1;
        if (hasAccessToKWS) total += 1;
        if (hasAccessToSR) total += 1;
        return total;
    };

    // Function to force reload of services in order to fetch newly deployed services
    const reloadServices = () => {
        setIsReloading(true);

        const { getServicesAcrossEnvs } = props;
        Promise.all([getServicesAcrossEnvs()]).finally(() => {
            setIsReloading(false);
        });
    };

    const totalHeight = 500;
    const totalSections = getComponentsCount();
    const sectionHeight = totalHeight / totalSections;
    const assistantHeight = totalSections * sectionHeight + (totalSections - 1) * 12;
    const assistantPadding = (totalSections / 2) * sectionHeight - 12;

    const assistantStyle = {
        root: {
            backgroundColor: "#4db6ac",
            color: "white",
            height: assistantHeight,
            marginBottom: "12px",
            "&:hover": {
                backgroundColor: "#ffb74d",
            },
        },
        special: { paddingTop: assistantPadding },
    };

    const componentStyle = {
        root: {
            backgroundColor: "#4db6ac",
            color: "white",
            height: sectionHeight,
            marginBottom: "12px",
            "&:hover": {
                backgroundColor: "#ffb74d",
            },
        },
        special: {},
    };

    const getComponentTiles = () => {
        return (
            <Grid item xs={12} sm={5}>
                {hasAccessToKWS ? (
                    <ComponentTile title="Keyword Spotter" path="/kws" style={componentStyle} />
                ) : null}
                {hasAccessToSR ? (
                    <ComponentTile title="Speaker Recognition" path="/sr" style={componentStyle} />
                ) : null}
                {hasAccessToASR ? (
                    <ComponentTile
                        path="/asr"
                        title="Automatic Speech Recognition"
                        style={componentStyle}
                    />
                ) : null}
                {hasAccessToDM ? (
                    <ComponentTile path="/dm" title="Dialog Manager" style={componentStyle} />
                ) : null}
                {hasAccessToTTS ? (
                    <ComponentTile path="/tts" title="Text To Speech" style={componentStyle} />
                ) : null}
            </Grid>
        );
    };

    return (
        <Stack spacing={6}>
            {showReload ? (
                <Stack direction="row-reverse" paddingRight={2}>
                    <SecondaryButton
                        name="reloadServices"
                        onClick={reloadServices}
                        style={{ textTransform: "inherit" }}
                        endIcon={isReloading ? <CustomSpinner /> : <CachedIcon />}
                    >
                        {t("main.reloadServices")}
                    </SecondaryButton>
                </Stack>
            ) : null}
            <div>
                <Grid container justifyContent="center" spacing={2}>
                    {hasAccessToASR && hasAccessToDM && hasAccessToTTS ? (
                        <Grid item xs={6} sm={3}>
                            <ComponentTile
                                title="Assistant"
                                path="/assistant"
                                style={assistantStyle}
                            />
                        </Grid>
                    ) : null}
                    {getComponentTiles()}
                </Grid>
            </div>
        </Stack>
    );
}

function mapStateToProps(state) {
    return {
        hasAccessToKWS: state.hasAccessToKWS,
        hasAccessToSR: state.hasAccessToSR,
        hasAccessToASR: state.hasAccessToASR,
        hasAccessToDM: state.hasAccessToDM,
        hasAccessToTTS: state.hasAccessToTTS,
    };
}

export default connect(mapStateToProps, actions)(Homepage);

Homepage.propTypes = {
    hasAccessToKWS: PropTypes.bool.isRequired,
    hasAccessToSR: PropTypes.bool.isRequired,
    hasAccessToASR: PropTypes.bool.isRequired,
    hasAccessToDM: PropTypes.bool.isRequired,
    hasAccessToTTS: PropTypes.bool.isRequired,
    showReload: PropTypes.bool,
    getServicesAcrossEnvs: PropTypes.func.isRequired,
};

Homepage.defaultProps = {
    showReload: true,
};
