import { useState } from "react";
import PropTypes from "prop-types";
import "../../styles/appStyle.css";
import { useTranslation } from "react-i18next";
import MessageList from "../../shared/components/MessageList";
import CardPanel from "../../shared/components/CardPanel";
import { downloadHistory } from "../../services/history";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import DisplayAudioInteractions from "../../shared/components/DisplayAudioInteractions";

/**
 * React component to display the complete messenger section for ASR
 */
function ASRMessenger(props) {
    const {
        messages,
        isStartDisabled,
        localStream,
        handleStart,
        handleStop,
        audioData,
        shouldDisableInputControls,
        handleDeleteHistory,
    } = props;
    const { t } = useTranslation();
    const [showToast, setShowToast] = useState(false);

    // Function to download chat messages if they exist
    const handleDownloadHistory = () => {
        if (messages?.length > 0) downloadHistory(messages);
        else setShowToast(true);
    };

    return (
        <>
            <CardPanel
                title={t("main.conversationHistory")}
                shouldShowDownload
                handleDownload={handleDownloadHistory}
                footer={
                    <DisplayAudioInteractions
                        isStartDisabled={isStartDisabled}
                        isRecording={isStartDisabled}
                        localStream={localStream}
                        handleStart={handleStart}
                        handleStop={handleStop}
                        disableControls={shouldDisableInputControls}
                    />
                }
                audioRequests={audioData}
                handleDeleteHistory={handleDeleteHistory}
                shouldDisableDelete={messages.length === 0}
                shouldShowRequestIcon
            >
                <MessageList messages={messages} />
            </CardPanel>
            <CustomSnackbar
                open={showToast}
                message={t("toasts.noHistory")}
                handleClose={() => setShowToast(false)}
                severity="info"
            />
        </>
    );
}
export default ASRMessenger;

ASRMessenger.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.shape({
                id: PropTypes.number,
                sender: PropTypes.string,
                text: PropTypes.string,
            }),
        })
    ).isRequired,
    isStartDisabled: PropTypes.bool, // check if audio processing is going on
    localStream: PropTypes.instanceOf(MediaStream),
    handleStart: PropTypes.func.isRequired,
    handleStop: PropTypes.func.isRequired,
    // Audio data that is shown in the Audio List, consisting of a list of displayed text and the audio
    audioData: PropTypes.arrayOf(
        PropTypes.shape({
            audioSrc: PropTypes.string,
            text: PropTypes.string,
        })
    ),
    shouldDisableInputControls: PropTypes.bool.isRequired,
    handleDeleteHistory: PropTypes.func.isRequired,
};

ASRMessenger.defaultProps = {
    isStartDisabled: false,
    localStream: null,
    audioData: [],
};
