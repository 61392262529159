import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import "../../styles/appStyle.css";

/**
 * React functional component to display the information about running KWS locally
 * @returns JSX
 */
function KWSInfo() {
    const { t } = useTranslation();
    return (
        <Paper className="grey lighten-4" sx={{ padding: "1rem" }}>
            {t("kws.kwsInfoPart1")}
            <br />
            <br />
            <Stack spacing={1}>
                <Typography>
                    {t("kws.kwsInfoPart2")}{" "}
                    <a
                        href="https://docs.docker.com/get-docker/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://docs.docker.com/get-docker/
                    </a>
                </Typography>

                {t("kws.kwsInfoPart3")}
                <Paper
                    className="grey lighten-2"
                    style={{
                        fontFamily: "monospace",
                        fontWeight: "bold",
                        fontSize: "17px",
                        padding: "10px",
                    }}
                >
                    docker login registry.gitlab.cc-asp.fraunhofer.de:4567
                </Paper>

                {t("kws.kwsInfoPart4")}

                <Paper
                    className="grey lighten-2"
                    style={{
                        fontFamily: "monospace",
                        fontWeight: "bold",
                        fontSize: "17px",
                        padding: "10px",
                    }}
                >
                    docker run -it --rm -p 8888:8888
                    registry.gitlab.cc-asp.fraunhofer.de:4567/fraunhofer-speech-assistant/asr/kws/ws-release/phoneme:1.2.0
                    --verbose --no_cors
                </Paper>
            </Stack>
        </Paper>
    );
}

export default KWSInfo;
