import { Slider } from "@mui/material";
import { SpeedSliderConstants } from "../constants";

/**
 * This is a slider component dedicated to set the TTS
 * speed/speech rate.
 */
function SpeedSlider(props) {
    return (
        <Slider
            style={{
                display: "inline-block",
                width: "70%",
                marginLeft: "10px",
                marginTop: "10px",
            }}
            min={SpeedSliderConstants.min}
            max={SpeedSliderConstants.max}
            marks={SpeedSliderConstants.marks}
            step={0.002}
            defaultValue={1}
            valueLabelDisplay="auto"
            {...props} // extend default props with passed props
        />
    );
}

export default SpeedSlider;
