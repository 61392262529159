import PropTypes from "prop-types";
import "../../styles/appStyle.css";

/**
 * React functional component to render each individual message sent by the user
 * and/or the assistant.
 * @param {*} props - consists of the message to be displayed.
 * @returns JSX of messages.
 */
function Message({ message }) {
    return (
        <div className={["message", `${message.sender}`].join(" ")}>
            <div className="bubble-container">
                <div className="bubble" title={message.sender}>
                    {/* If event then pretty-print JSON by adding <pre> tag */}
                    {message.sender === "dm-event" ? (
                        <pre style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                            {message.text}
                        </pre>
                    ) : (
                        message.text
                    )}
                </div>
            </div>
        </div>
    );
}

export default Message;

Message.propTypes = {
    message: PropTypes.shape({
        sender: PropTypes.string,
        text: PropTypes.string,
        id: PropTypes.number,
    }),
};

Message.defaultProps = {
    message: { sender: "", text: "", id: 0 },
};
