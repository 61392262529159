import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";

import "../../styles/appStyle.css";
import KWSInfo from "./KWSInfo";
import CustomSelect from "./CustomSelect";
import TertiaryButton from "./TertiaryButton";
import ConfigurationDialog from "./ConfigurationDialog";

const options = ["local", "remote"];

/**
 * React Component to display the endpoint selection for the Keyword Spotter
 */
class EndpointSelector extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedEndpoint: options[1],
            showInfo: false,
        };
    }

    handleEndpointSelected = (endpoint) => {
        const { onEndpointSelected } = this.props;
        this.setState({
            selectedEndpoint: endpoint,
        });

        onEndpointSelected(endpoint);
    };

    handlePortChanged = (event) => {
        const { onPortChanged } = this.props;
        onPortChanged(event.target.value);
    };

    render() {
        const { t, selectName, defaultPort } = this.props;
        const { selectedEndpoint, showInfo } = this.state;

        const getActions = () => {
            return (
                <TertiaryButton
                    text={t("buttons.close")}
                    onClick={() => {
                        this.setState({ showInfo: false });
                    }}
                />
            );
        };

        return (
            <>
                <Grid item xs={12} sm={3}>
                    <Stack>
                        <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                            {t("kws.connection")}
                        </Typography>
                        <CustomSelect
                            items={options}
                            name={selectName}
                            value={selectedEndpoint}
                            onSelected={this.handleEndpointSelected}
                        />
                    </Stack>
                </Grid>
                {selectedEndpoint === "local" ? (
                    <Grid item xs={12} sm={3}>
                        <Stack direction="row" sx={{ marginTop: "35px" }}>
                            <TextField
                                id="kwsPort"
                                label={t("kws.port")}
                                defaultValue={defaultPort}
                                onChange={this.handlePortChanged}
                            />

                            <IconButton onClick={() => this.setState({ showInfo: true })}>
                                <TipsAndUpdatesOutlinedIcon
                                    style={{
                                        color: "teal",
                                    }}
                                />
                            </IconButton>
                        </Stack>
                        <ConfigurationDialog
                            isConfigDialogOpen={showInfo}
                            setConfigDialogOpen={() => this.setState({ showInfo: false })}
                            actions={getActions()}
                            title={t("kws.additionalInfo")}
                        >
                            <KWSInfo />
                        </ConfigurationDialog>
                    </Grid>
                ) : null}
            </>
        );
    }
}

export default withTranslation("translation")(EndpointSelector);

EndpointSelector.propTypes = {
    t: PropTypes.func.isRequired,
    defaultPort: PropTypes.string,
    selectName: PropTypes.string.isRequired,
    onPortChanged: PropTypes.func.isRequired,
    onEndpointSelected: PropTypes.func.isRequired,
};

EndpointSelector.defaultProps = {
    defaultPort: "8888",
};
