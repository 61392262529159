import { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Grid } from "@mui/material";
import ServiceDropdown from "../../../shared/components/ServiceDropdown";
import ComponentCardHeader from "./ComponentCardHeader";
import { componentServiceProps, envProps, newServiceProps } from "../../../shared/constants";
import EnvironmentDropdown from "../../../shared/components/EnvironmentDropdown";

// React component to show the ASR settings in Assistant configurations.
function ASRSettings(props) {
    const [open, setOpen] = useState(false);
    const {
        services,
        selectedService,
        onServiceChange,
        accessibleEnvs,
        selectedEnv,
        onEnvChange,
    } = props;
    return (
        <Card sx={{ border: "1px solid", boxShadow: "none" }}>
            <ComponentCardHeader
                title="Automatic Speech Recognition"
                open={open}
                setOpen={setOpen}
            />
            {open ? (
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {accessibleEnvs > 1 ? (
                                <EnvironmentDropdown
                                    envs={accessibleEnvs}
                                    name="ASR_environment"
                                    selectedEnv={selectedEnv}
                                    onEnvChange={onEnvChange}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ServiceDropdown
                                onServiceChange={onServiceChange}
                                services={services[selectedEnv?.name]}
                                selectedService={selectedService}
                                name="assistant_asr"
                                customLabel="Service"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            ) : null}
        </Card>
    );
}

ASRSettings.propTypes = {
    services: componentServiceProps.isRequired,
    selectedService: newServiceProps,
    onServiceChange: PropTypes.func.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
    selectedEnv: envProps.isRequired,
    onEnvChange: PropTypes.func.isRequired,
};

ASRSettings.defaultProps = {
    selectedService: null,
};

export default ASRSettings;
