/**
 * The AudioController creates and controls the audio nodes that are used for
 * recording user input. It messages the captured audio stream to a custom PCM processor node.
 * The PCM processor node returns audio chunks as arrays of the audio encoded in PCM16 and the
 * audio encoded as PCM32, i.e. [PCM16, PCM32].
 *
 * The received audio chunks from the PCM processor are then processed further according to
 * the callback function 'customOnMessageFunction' that is given to the AudioController as parameter.
 *
 * If the audio node could not be created with the specified sampleRate, the AudioController may
 * back propagate the default sampleRate with the callback function 'changeSampleRate', if the
 * function was given as parameter on initialization of the class.
 *
 */
class AudioController {
    constructor(
        /** @type function */
        customOnMessageFunction,

        /** @type number */
        sampleRate,

        /** @type function */
        changeSampleRate,

        /** @type number */
        bufferSize,

        /** @type number */
        numberOfChannels
    ) {
        this.customOnMessageFunction = customOnMessageFunction;
        this.pcmNode = null;
        this.audioInputNode = null;
        this.stream = null;
        this.sampleRate = sampleRate || 48000;
        this.bufferSize = bufferSize;
        this.numberOfChannels = numberOfChannels;
        this.changeSampleRate = changeSampleRate;
    }

    /**
     * Function to set up wiring for webaudio. A PCM node, using the custom PCM processor,
     * is created. For processing the output of the PCM processor, the 'customOnMessageFunction'
     * is executed. That function had to be given as a parameter by the calling class or function.
     *
     * If the function 'changeSampleRate' was given as parameter by the calling class or function,
     * it is called with the sample rate that is used by the AudioContext.
     *
     * @param {MediaStream} stream - refers to the user audio stream
     */
    async initAudio(stream) {
        // if the controller is already initialized, shut it down first
        if (this.stream) this.shutdown();

        this.stream = stream;
        let context = null;
        try {
            /* this is needed because Firefox does not seem to support AudioContexts with 
            samplerates other than the default samplerate */
            context = new AudioContext({
                sampleRate: this.sampleRate,
            });

            /* create an audio node from the stream recorded by the microphone */
            await context.audioWorklet.addModule("worklet/pcm_processor.js");
            this.audioInputNode = context.createMediaStreamSource(stream);
        } catch (e) {
            /* eslint-disable no-console */
            console.log(`Info: Could not set samplerate to ${this.sampleRate}, using default.`);
            context = new AudioContext();

            /* create an audio node from the stream recorded by the microphone */
            await context.audioWorklet.addModule("worklet/pcm_processor.js");
            this.audioInputNode = context.createMediaStreamSource(stream);
        }
        this.sampleRate = context.sampleRate;

        /* Use callback function to inform component, where the recording is taking place,
        about the change of the sample rate */
        if (this.changeSampleRate) {
            this.changeSampleRate(this.sampleRate);
        }

        this.pcmNode = new window.AudioWorkletNode(context, "pcm-processor", {
            processorOptions: {
                samplerate: this.sampleRate,
                chunksize: this.bufferSize,
                numberOfChannels: this.numberOfChannels,
            },
        });
        /* Use callback function to further process the PCM that is sent from the PCM processor */
        this.pcmNode.port.onmessage = (event) => {
            this.customOnMessageFunction(event);
        };

        /* connect audio processing  */
        this.audioInputNode.connect(this.pcmNode);
        this.pcmNode.connect(context.destination);
    }

    /**
     * Pauses the audio flow by disconnecting the audio node chain.
     * Can be undone by calling {@link resume}.
     */
    pause() {
        if (this.stream) {
            this.stream.getAudioTracks().forEach((track) => {
                // eslint-disable-next-line no-param-reassign
                track.enabled = false;
            });

            this.audioInputNode.disconnect();
        }
    }

    /**
     * Resumes the audio flow by reconnecting the audio node chain.
     * This is usually done sometime after {@link pause} was called.
     * Calling this without a preceding pause() call has no effect.
     */
    resume() {
        if (this.stream) {
            // according to the spec, this call has no effect if the nodes are already connected
            this.audioInputNode.connect(this.pcmNode);

            this.stream.getAudioTracks().forEach((track) => {
                // eslint-disable-next-line no-param-reassign
                track.enabled = true;
            });
        }
    }

    /**
     * Shut down the audio processing in a graceful way, incl. closing the audio nodes,
     * and stopping all audio streams.
     */
    shutdown() {
        if (this.stream) {
            /* close audio nodes */
            this.pcmNode.disconnect();
            this.audioInputNode.disconnect();
            this.pcmNode = null;
            this.audioInputNode = null;

            /* stop media stream */
            this.stream.getAudioTracks().forEach((track) => {
                track.stop();
            });

            this.stream = null;
        }
    }
}

export default AudioController;
