import { Stack, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";

function ConfigurationDialog({
    isConfigDialogOpen,
    setConfigDialogOpen,
    children,
    title,
    actions,
}) {
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setConfigDialogOpen(false);
    };

    return (
        <Dialog
            open={isConfigDialogOpen}
            onClose={handleClose}
            maxWidth={false}
            aria-labelledby="draggable-dialog-title"
            data-testid="configuration-dialog"
            PaperProps={{
                style: {
                    borderRadius: "0%",
                },
            }}
        >
            <Stack width={792} height={720}>
                <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>{children}</DialogContent>
                <DialogActions sx={{ paddingTop: "auto" }}>{actions}</DialogActions>
            </Stack>
        </Dialog>
    );
}

ConfigurationDialog.propTypes = {
    isConfigDialogOpen: PropTypes.bool.isRequired,
    setConfigDialogOpen: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    actions: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

export default ConfigurationDialog;
