import { useState } from "react";
import Send from "@mui/icons-material/Send";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "../../styles/appStyle.css";
import PrimaryButton from "../../shared/components/PrimaryButton";
import CustomSpinner from "../../shared/components/CustomSpinner";
import CustomTooltip from "../../shared/components/CustomTooltip";
import CustomSnackbar from "../../shared/components/CustomSnackbar";

/**
 * Component to display the enroll button in all the KWS tabs.
 */
function EnrollButton(props) {
    const [isEnrolling, setEnrolling] = useState(false);
    const { configTable, onEnroll, getEnrollment, keywordName, enrollTooltip } = props;
    const { t } = useTranslation();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const enrollHandler = async () => {
        if (keywordName === "") {
            setShowToast(true);
            setToastMessage(t("toasts.enterKeywordName"));
            return;
        }

        if (configTable.some((item) => item.name.toLowerCase() === keywordName.toLowerCase())) {
            setShowToast(true);
            setToastMessage(t("toasts.duplicateKeyword"));
            return;
        }

        try {
            setEnrolling(true);
            const enrollment = await getEnrollment();
            // call parent function
            await onEnroll(enrollment);
        } catch (err) {
            setShowToast(true);
            setToastMessage(err.message);
        }

        setEnrolling(false);
    };

    const enrollIcon = isEnrolling ? <CustomSpinner /> : <Send />;

    return (
        <>
            <CustomTooltip title={enrollTooltip}>
                <PrimaryButton
                    endIcon={enrollIcon}
                    type="submit"
                    value="Save"
                    onClick={enrollHandler}
                >
                    {t("kws.enroll")}
                </PrimaryButton>
            </CustomTooltip>
            <CustomSnackbar
                open={showToast}
                message={toastMessage}
                handleClose={() => setShowToast(false)}
            />
        </>
    );
}

export default EnrollButton;

EnrollButton.propTypes = {
    configTable: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            audioUrls: PropTypes.arrayOf(PropTypes.string),
            keyword: PropTypes.string,
            threshold: PropTypes.string,
        })
    ).isRequired,
    onEnroll: PropTypes.func.isRequired,
    getEnrollment: PropTypes.func.isRequired,
    keywordName: PropTypes.string.isRequired,
    enrollTooltip: PropTypes.string,
};

EnrollButton.defaultProps = {
    enrollTooltip: "",
};
