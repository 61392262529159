import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import tooltipStyle from "../../styles/tooltipStyle";

// React component to display a tooltip with custom styling
function CustomTooltip({ title, children, placement, enableEllipsis }) {
    return (
        <Tooltip
            title={title}
            PopperProps={{
                sx: tooltipStyle,
            }}
            placement={placement}
        >
            <div
                style={
                    enableEllipsis
                        ? {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                          }
                        : {}
                }
            >
                {children}
            </div>
        </Tooltip>
    );
}

export default CustomTooltip;

CustomTooltip.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    children: PropTypes.node.isRequired,
    placement: PropTypes.string,
    enableEllipsis: PropTypes.bool,
};

CustomTooltip.defaultProps = {
    placement: "bottom",
    enableEllipsis: false,
};
