import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

// React reusable component to display the tertiary button.
function TertiaryButton({ onClick, text }) {
    return (
        <Button
            sx={{
                margin: 1,
                minWidth: "6rem",
                borderRadius: 0,
                alignSelf: "center",
                border: "none",
                color: "rgba(51, 51, 51, 0.87)",
                textTransform: "inherit",
            }}
            onClick={onClick}
        >
            <Typography fontWeight={500} fontSize={14}>
                {text}
            </Typography>
        </Button>
    );
}

TertiaryButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default TertiaryButton;
