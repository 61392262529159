import { FormControl, InputBase, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const styles = {
    formControl: {
        width: "8ch",
    },
    selectInput: {
        color: "#333333",
        "&:hover": {
            color: "#4fc4a2",
        },
    },
};

const languages = {
    en: {
        value: "en",
        renderValue: "ENG",
        displayValue: "English",
    },
    de: {
        value: "de",
        renderValue: "DEU",
        displayValue: "Deutsch",
    },
};

function LanguageSelector() {
    const { i18n } = useTranslation();

    const handleLanguageChange = (event) => {
        const language = event.target.value;

        localStorage.setItem("language", language);
        i18n.changeLanguage(language);
    };

    return (
        <FormControl sx={styles.formControl} key="languageSelector">
            <Select
                id="language-selector"
                labelId="language-selector-label"
                data-testid="language-selector"
                value={i18n.language}
                label="Language"
                onChange={handleLanguageChange}
                input={<InputBase sx={styles.selectInput} />}
                renderValue={(language) => languages[language].renderValue}
            >
                {Object.values(languages).map((language) => (
                    <MenuItem key={language.value} value={language.value}>
                        {language.displayValue}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default LanguageSelector;
