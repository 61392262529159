import React from "react";
import { Stack, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import "../../styles/appStyle.css";
import PropTypes from "prop-types";
import AudioController from "../../services/audio/audio-controller";
import { AudioParameters } from "../constants";
import encodeAudioRecordingToAudioFormat from "../../services/audio/audio-encoder";
import getAudioStreamFromMicrophone from "../../services/audio/microphone";
import getRecordedBuffer from "../../services/audio/datastructure-handling";
import CustomSnackbar from "./CustomSnackbar";
import AudioAnalyser from "./AudioAnalyser";

/**
 * React Component to record user audio and convert it to an audioBlob.
 * It expects the callback function 'saveEncodedAudio' to control how the result,
 * i.e. the audioBlob and URL, is processed by the calling component.
 *
 */
class VoiceRecorder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRecording: false,
            sampleRate: 16000,
            showToast: false,
            micStream: null,
        };
        this.numberOfChannels = AudioParameters.mono;

        /* The array for the current audio recording consists of arrays that consist of an a 
        number of Int16Array, where this number is equal to the number of recorded channels */
        this.currentAudioRecording = [];
        this.recordingLength = 0;
        this.bufferSize = 80;

        const { sampleRate } = this.state;
        this.audioController = new AudioController(
            this.collectDataForAudioFile,
            sampleRate,
            this.changeSampleRate,
            this.bufferSize,
            this.numberOfChannels
        );
    }

    componentWillUnmount() {
        this.audioController.shutdown();
    }

    /**
     * Routing the audio stream to the AudioController instance.
     * @param {*} stream
     */
    record = async (stream) => {
        await this.audioController.initAudio(stream);
    };

    /**
     * Start the recording, by getting the audio stream from the microphone and calling
     * the AudioController with it.
     * @param {*} e
     */
    startRecording = (e) => {
        e.preventDefault();

        getAudioStreamFromMicrophone()
            .then((stream) => {
                this.setState({ isRecording: true, micStream: stream });
                this.record(stream);

                this.currentAudioRecording = [];
                this.recordingLength = 0;
            })
            .catch(() => {
                this.setState({ showToast: true });
            });
    };

    /**
     * Stop the audio recording. Shut down the AudioController. Encode the collected audio data,
     * sent by the AudioController, and encode it in WAVE format as audioBlob. The AudioBlob as
     * well as the file extension is used in the callback function 'saveEncodedAudio' from the
     * calling class/function
     *8
     * @param {*} e
     */
    stopRecording = (e) => {
        const { sampleRate } = this.state;
        const { saveEncodedAudio } = this.props;
        e.preventDefault();
        this.setState({ isRecording: false, micStream: null });
        this.audioController.shutdown();
        const format = AudioParameters.WAV;
        const audioBlob = encodeAudioRecordingToAudioFormat(
            format,
            this.currentAudioRecording,
            this.recordingLength,
            this.numberOfChannels,
            this.bufferSize,
            sampleRate
        );

        this.currentAudioRecording = [];
        saveEncodedAudio({ value: audioBlob, fileExtension: "wav" });
    };

    /**
     * The output of the PCM processor is collected to be later on changed into an audio file
     * that can be listened to or downloaded by the users.
     *
     * The function adds an ArrayBuffer to an array of ArrayBuffers.
     * The new length of the collected audio is stored in a variable, that is incremented
     * by the size of the newly added buffer. The buffers can consist of several channels.
     *
     * @param {MessageEvent} event - event that includes an array of the PCM data in two formats:
     *                      [PCM16, PCM32]. For the creation of a audio file, PCM16 is used.
     */
    collectDataForAudioFile = (event) => {
        this.currentAudioRecording.push(getRecordedBuffer(event.data[0], this.numberOfChannels));
        this.recordingLength += this.bufferSize;
    };

    changeSampleRate = (sampleRate) => {
        this.setState({ sampleRate });
    };

    render() {
        const { isRecording, showToast, micStream } = this.state;
        const { t } = this.props;
        const { audioLengthHint } = this.props;
        return (
            <>
                {isRecording ? (
                    <Stack gap={1} justifyContent="center" alignItems="center">
                        {micStream ? (
                            <div>
                                <AudioAnalyser audio={micStream} />
                            </div>
                        ) : null}
                        <IconButton
                            onClick={this.stopRecording}
                            sx={{
                                backgroundColor: "rgba(116, 196, 180, 1)",
                                width: "59px",
                                height: "59px",
                            }}
                        >
                            <StopCircleOutlinedIcon />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack gap={1} justifyContent="center" alignItems="center">
                        <IconButton
                            onClick={this.startRecording}
                            sx={{
                                backgroundColor: "rgba(116, 196, 180, 1)",
                                width: "59px",
                                height: "59px",
                            }}
                        >
                            <MicIcon />
                        </IconButton>
                    </Stack>
                )}
                <Stack gap={1} direction="row" justifyContent="center">
                    <TipsAndUpdatesOutlinedIcon sx={{ color: "rgba(0, 100, 73, 1)" }} />
                    <Typography fontWeight={400} fontSize={14}>
                        {audioLengthHint}
                    </Typography>
                </Stack>
                <CustomSnackbar
                    open={showToast}
                    message={t("toasts.micPermission")}
                    handleClose={() => this.setState({ showToast: false })}
                />
            </>
        );
    }
}

export default withTranslation("translation")(VoiceRecorder);

VoiceRecorder.propTypes = {
    t: PropTypes.func.isRequired,
    saveEncodedAudio: PropTypes.func.isRequired,
    audioLengthHint: PropTypes.string.isRequired,
};
