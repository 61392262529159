import { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../styles/appStyle.css";

import keycloak from "../../keycloak";
import {
    createWavFileFromAudioBlob,
    getAudioDuration,
} from "../../services/audio/audio-processing";
import RecordAudio from "../../shared/components/RecordAudio";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import EnrollButton from "./EnrollButton";

/**
 * React component that provides the functionality to record an audio
 * containing a keyword.
 * Note: The audio may be only up to 2s long.
 */
function KeywordRecordTab(props) {
    const [keywordName, setKeywordName] = useState("");

    const { t } = useTranslation();
    const { onEnroll, configTable, enrollEndpoint } = props;

    const [recordedAudioInfo, setRecordedAudioInfo] = useState({ audioUrl: "", blob: "" });
    const [errToastMessage, setErrToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const resetState = () => {
        setKeywordName("");
        setRecordedAudioInfo({ audioUrl: "", blob: "" });
    };

    const checkIfAudioIsRecorded = () => {
        if (recordedAudioInfo.audioUrl === "" || recordedAudioInfo.blob === "") {
            setShowErrorToast(true);
            setErrToastMessage(t("toasts.recordAudio"));
            return false;
        }
        return true;
    };
    const getEnrollment = async () => {
        // check if audio is recorded
        checkIfAudioIsRecorded();

        const duration = await getAudioDuration(recordedAudioInfo.audioUrl);
        if (duration > 2) {
            throw new Error(t("toasts.audioOver2s"));
        } else {
            const file = createWavFileFromAudioBlob(recordedAudioInfo.blob, keywordName);
            const formData = new FormData();
            formData.append(keywordName, file);
            let enrolledKeyword = "";
            let enrolledThreshold = "";

            // construct request
            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
                body: formData,
            };

            try {
                const response = await fetch(enrollEndpoint, requestOptions);
                if (response.ok) {
                    const jsonResponse = await response.json();
                    const keywords = [];
                    for (let i = 0; i < jsonResponse.keywords.length; i++) {
                        keywords.push(jsonResponse.keywords[i].join("."));
                    }
                    const keyword = keywords.join(";");
                    enrolledKeyword = keyword;
                    enrolledThreshold = jsonResponse.threshold.toString();

                    if (enrolledKeyword !== "" && enrolledThreshold !== "") {
                        const enrollment = {
                            name: keywordName,
                            audioUrls: [recordedAudioInfo.audioUrl],
                            keyword: enrolledKeyword,
                            threshold: enrolledThreshold,
                        };

                        resetState();
                        return enrollment;
                    }
                } else {
                    throw new Error(await response.text());
                }
            } catch (err) {
                resetState();
                throw err;
            }

            return null;
        }
    };

    return (
        <>
            <RecordAudio
                name={keywordName}
                onNameChange={setKeywordName}
                onRecordAudio={setRecordedAudioInfo}
                label={t("kws.addKeywordName")}
                audioLengthHint={t("kws.recordNote")}
            />

            <Box display="flex" justifyContent="flex-end">
                <EnrollButton
                    getEnrollment={getEnrollment}
                    onEnroll={onEnroll}
                    configTable={configTable}
                    keywordName={keywordName}
                    setKeywordName={setKeywordName}
                    enrollTooltip={t("kws.enrollDescription")}
                />
            </Box>
            <CustomSnackbar
                open={showErrorToast}
                message={errToastMessage}
                handleClose={() => setShowErrorToast(false)}
                severity="error"
            />
        </>
    );
}

export default KeywordRecordTab;

KeywordRecordTab.propTypes = {
    enrollEndpoint: PropTypes.string.isRequired,
    onEnroll: PropTypes.func.isRequired,
    configTable: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            audioUrls: PropTypes.arrayOf(PropTypes.string),
            keyword: PropTypes.string,
            threshold: PropTypes.string,
        })
    ).isRequired,
};
