import { AudioParameters } from "../../shared/constants";

/**
 * Write a string at a given position in a DataView as big-endian.
 *
 * @param {DataView} view - the view where the string is set.
 * @param {Number} offset - position (file offset in bytes) in the view where the string is to be set.
 * @param {String} str - string to write
 *
 * @returns updated view with the string written in big-endian at the specified position.
 */
function setByteAsBigEndian(view, offset, str) {
    for (let i = 0; i < str.length; ++i) {
        view.setUint8(offset + i, str.charCodeAt(i));
    }
    return view;
}

/**
 * Change a given inputBuffer in PCM into WAV format.
 * For a more elaborate explanation of a WaveHeader, s. http://soundfile.sapp.org/doc/WaveFormat/
 *
 * @param {Float32Array} audioInput array of PCM data
 * @param {Number} numberOfChannels number of channels in the audioInput
 * @param {Number} sampleRate sample rate of the audioInput
 * @returns audioBlob of audio in PCM/WAV
 */
function encodeAudioBuffersAsWav(audioInput, numberOfChannels, sampleRate) {
    // 44: size of the WAV header
    const headerSize = 44;

    // create new DataView for the header and data content of the future wav audio
    let view = new DataView(new ArrayBuffer(headerSize + audioInput.length * 2));
    const dataSize = audioInput.length * 2;

    /* RIFF chunk descriptor */
    view = setByteAsBigEndian(view, 0, "RIFF");

    // size of the following header and the included data
    view.setUint32(4, headerSize - 8 + dataSize, true);
    view = setByteAsBigEndian(view, 8, "WAVE");

    /* FMT sub-chunk */
    view = setByteAsBigEndian(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);

    const bitsPerSample = 16;
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numberOfChannels * (bitsPerSample / 8), true);
    view.setUint16(32, numberOfChannels * (bitsPerSample / 8), true);
    view.setUint16(34, bitsPerSample, true);

    /* data sub-chunk */
    view = setByteAsBigEndian(view, 36, "data");
    view.setUint32(40, dataSize, true);

    /* data */
    const volume = 1;
    let currentOffset = headerSize;
    for (let i = 0; i < audioInput.length; i++) {
        // set data value to the range of an Int16Array: -32768 to 32767
        view.setInt16(currentOffset, audioInput[i] * volume, true);
        currentOffset += 2;
    }

    const audioBlob = new Blob([view], { type: "audio/wav" });

    return audioBlob;
}

/**
 * Create a Float32Array with the interleaved audio input of all the recorded channels.
 *
 * @param {Array} inputArray Array of subarrays of Int16Arrays.
 *          A subarray is a chunk of the defined chunk size of one channel.
 *          It is structured as follows:
 *              [[chunk1Channel1, chunk1Channel2],
 *               [chunk2Channel1, chunk2Channel2],
 *               ...,
 *               [chunk<n>Channel1, chunk<n>Channel2]]
 * @param {Number} totalLengthOneChannel length of audio in one channel, divided over all
 *           the subarrays in the audioInput.
 * @param {Number} numberOfChannels - number of channels of the audioInput
 * @param {Number} sizeOfSingleChunk - size of the chunks in the audioInput
 *
 * @returns Float32Array where the data of all channels is written alternated,
 *          e.g. for stereo:
 *              [input1Channel1, input1Channel2,
 *               input2Channel1, input2Channel2,
 *               ...,
 *               input<n>Channel1, input<n>Channel2]
 */
function interleave(inputArray, totalLengthOneChannel, numberOfChannels, sizeOfSingleChunk) {
    const totalLength = totalLengthOneChannel * numberOfChannels;

    const result = new Float32Array(totalLength);
    let indexInResult = 0;

    for (let currentChunk = 0; currentChunk < inputArray.length; currentChunk++) {
        for (let placeInChunk = 0; placeInChunk < sizeOfSingleChunk; placeInChunk++) {
            for (let currentChannel = 0; currentChannel < numberOfChannels; currentChannel++) {
                result[indexInResult] = inputArray[currentChunk][currentChannel][placeInChunk];
                indexInResult++;
            }
        }
    }
    return result;
}

/**
 * Encode an audioInput into the specified audio format.
 * Currently, only PCM/WAVE is supported.
 *
 * @param {String} format - format that the audio should be recorded as.
 * @param {Array} audioInput - Array of subarrays of Int16Arrays.
 *          A subarray is a chunk of the defined chunk size of one channel.
 *          It is structured as follows:
 *              [[chunk1Channel1, chunk1Channel2],
 *               [chunk2Channel1, chunk2Channel2],
 *               ...,
 *               [chunk<n>Channel1, chunk<n>Channel2]]
 * @param {Number} recordingLength
 * @param {Number} numberOfChannels - number of channels of the audioInput
 * @param {Number} sizeOfSingleChunk - size of the chunks in the audioInput
 * @param {Number} sampleRate - sample rate of the audioInput
 *
 * @returns
 */
function encodeAudioRecordingToAudioFormat(
    format,
    audioInput,
    recordingLength,
    numberOfChannels,
    sizeOfSingleChunk,
    sampleRate
) {
    const interleavedBuffer = interleave(
        audioInput,
        recordingLength,
        numberOfChannels,
        sizeOfSingleChunk
    );

    if (format === AudioParameters.WAV) {
        return encodeAudioBuffersAsWav(interleavedBuffer, numberOfChannels, sampleRate);
    }

    /* eslint-disable no-console */
    console.log(`Error: Audio format ${format} is currently not supported.`);
    return "";
}

export default encodeAudioRecordingToAudioFormat;
