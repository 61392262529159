import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "../../styles/appStyle.css";
import MessageList from "../../shared/components/MessageList";
import SendMessageForm from "../../shared/components/SendMessageForm";
import CardPanel from "../../shared/components/CardPanel";
import { downloadHistory } from "../../services/history";
import DisplayAudioInteractions from "../../shared/components/DisplayAudioInteractions";
import { selectedPipelineProps } from "../../shared/constants";
import CustomSnackbar from "../../shared/components/CustomSnackbar";

/**
 * React component to display the complete messenger section for Assistant.
 * If the selected pipeline contains KWS or ASR, then the start/stop buttons are displayed.
 * If the selected pipeline is DM+TTS, then the audio section is replaced by input text field to send a message to DM.
 */
function AssistantMessenger(props) {
    const {
        messages,
        isRecording,
        isStartDisabled,
        sendMessage,
        localStream,
        handleStart,
        handleStop,
        selectedPipeline,
        audioRequests,
        audioResponses,
        shouldDisableInputControls,
        handleDeleteHistory,
    } = props;
    const [shouldShowEvents, setShouldShowEvents] = useState(true);
    const [messagesToShow, setMessagesToShow] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (shouldShowEvents) {
            // should show events
            setMessagesToShow(messages);
        } else {
            // should not show events
            const x = messages.filter((m) => m.sender !== "dm-event");
            setMessagesToShow(x);
        }
    }, [messages]);

    // Function to download chat messages if they exist
    const handleDownloadHistory = () => {
        if (messages?.length > 0) downloadHistory(messages);
        else setShowToast(true);
    };

    /**
     * Function to toggle the bool "shouldShowEvents" and update the messages
     * to show or not show events based on bool value after toggle.
     */
    const handleToggleEvents = () => {
        const flag = !shouldShowEvents; // bool value after toggle.
        if (flag) {
            // should show events
            setMessagesToShow(messages);
        } else {
            // should not show events
            const x = messages.filter((m) => m.sender !== "dm-event");
            setMessagesToShow(x);
        }
        setShouldShowEvents(flag);
    };

    const getFooter = () => {
        // if KWS or ASR is part of the pipeline then return audio stuff else message box
        if (selectedPipeline.value.KWS || selectedPipeline.value.ASR)
            return (
                <DisplayAudioInteractions
                    isStartDisabled={isStartDisabled}
                    isRecording={isRecording}
                    localStream={localStream}
                    handleStart={handleStart}
                    handleStop={handleStop}
                    disableControls={shouldDisableInputControls}
                />
            );
        return (
            <SendMessageForm
                disabled={shouldDisableInputControls}
                sendMessage={sendMessage}
                messages={messages}
            />
        );
    };

    return (
        <>
            <CardPanel
                title={t("main.conversationHistory")}
                handleDownload={handleDownloadHistory}
                shouldShowDownload
                shouldShowEventsToggle
                footer={getFooter()}
                toggleEvents={handleToggleEvents}
                shouldShowEvents={shouldShowEvents}
                audioRequests={audioRequests}
                shouldShowRequestIcon
                audioResponses={audioResponses}
                handleDeleteHistory={handleDeleteHistory}
                shouldDisableDelete={messages.length === 0}
                shouldShowResponseIcon
            >
                <MessageList messages={messagesToShow} />
            </CardPanel>
            <CustomSnackbar
                open={showToast}
                message={t("toasts.noHistory")}
                handleClose={() => setShowToast(false)}
                severity="info"
            />
        </>
    );
}
export default AssistantMessenger;

AssistantMessenger.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.shape({
                id: PropTypes.number,
                sender: PropTypes.string,
                text: PropTypes.string,
            }),
        })
    ).isRequired,
    isStartDisabled: PropTypes.bool, // check if audio processing is going on
    isRecording: PropTypes.bool, // check if ASR specifically is going on
    sendMessage: PropTypes.func.isRequired,
    localStream: PropTypes.instanceOf(MediaStream),
    handleStart: PropTypes.func.isRequired,
    handleStop: PropTypes.func.isRequired,
    selectedPipeline: selectedPipelineProps.isRequired,
    audioRequests: PropTypes.arrayOf(
        PropTypes.shape({
            audioSrc: PropTypes.string,
            text: PropTypes.string,
        })
    ),
    audioResponses: PropTypes.arrayOf(
        PropTypes.shape({
            audioSrc: PropTypes.string,
            text: PropTypes.string,
        })
    ),
    shouldDisableInputControls: PropTypes.bool.isRequired,
    handleDeleteHistory: PropTypes.func.isRequired,
};

AssistantMessenger.defaultProps = {
    isStartDisabled: false,
    isRecording: false,
    localStream: null,
    audioRequests: [],
    audioResponses: [],
};
