import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppsIcon from "@mui/icons-material/Apps";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * React component to display the Apps button on the AppBar.
 * On click, it shows a list of available components to navigate to.
 */
function AppsButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const navigate = useNavigate();

    const handleClose = (path) => {
        navigate(path);
        setAnchorEl(null);
    };

    const menuItemStyle = {
        "&:hover": {
            backgroundColor: "white",
            color: "#4db6ac",
        },
    };

    const { hasAccessToKWS, hasAccessToSR, hasAccessToASR, hasAccessToDM, hasAccessToTTS } = props;

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                    marginLeft: "10px",
                }}
            >
                <AppsIcon
                    style={{
                        width: "30px",
                        height: "30px",
                        color: "black",
                    }}
                />
                <Typography
                    sx={{
                        color: "black",
                        fontSize: "22px",
                    }}
                >
                    {t("main.apps")}
                </Typography>
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        boxShadow: "none",
                        border: "1px solid grey",
                    },
                }}
            >
                {hasAccessToASR && hasAccessToDM && hasAccessToTTS ? (
                    <MenuItem sx={menuItemStyle} onClick={() => handleClose("/assistant")}>
                        Assistant
                    </MenuItem>
                ) : null}
                {hasAccessToKWS ? (
                    <MenuItem sx={menuItemStyle} onClick={() => handleClose("/kws")}>
                        Keyword Spotter
                    </MenuItem>
                ) : null}
                {hasAccessToSR ? (
                    <MenuItem sx={menuItemStyle} onClick={() => handleClose("/sr")}>
                        Speaker Recognition
                    </MenuItem>
                ) : null}
                {hasAccessToASR ? (
                    <MenuItem sx={menuItemStyle} onClick={() => handleClose("/asr")}>
                        Automatic Speech Recognition
                    </MenuItem>
                ) : null}
                {hasAccessToDM ? (
                    <MenuItem sx={menuItemStyle} onClick={() => handleClose("/dm")}>
                        Dialog Manager
                    </MenuItem>
                ) : null}
                {hasAccessToTTS ? (
                    <MenuItem sx={menuItemStyle} onClick={() => handleClose("/tts")}>
                        Text To Speech
                    </MenuItem>
                ) : null}
            </Menu>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        hasAccessToKWS: state.hasAccessToKWS,
        hasAccessToSR: state.hasAccessToSR,
        hasAccessToASR: state.hasAccessToASR,
        hasAccessToDM: state.hasAccessToDM,
        hasAccessToTTS: state.hasAccessToTTS,
    };
}

export default connect(mapStateToProps)(AppsButton);

AppsButton.propTypes = {
    hasAccessToKWS: PropTypes.bool.isRequired,
    hasAccessToSR: PropTypes.bool.isRequired,
    hasAccessToASR: PropTypes.bool.isRequired,
    hasAccessToDM: PropTypes.bool.isRequired,
    hasAccessToTTS: PropTypes.bool.isRequired,
};
