import PropTypes from "prop-types";
import InformationIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Stack, Typography } from "@mui/material";
import "../../styles/appStyle.css";
import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect";
import CustomTooltip from "./CustomTooltip";
/**
 * React functional component to display dropdown values (of languages)
 * @param {List<String>} props - consists of details of languages available
 * @returns JSX of dropdown with languages
 */
function LanguageDropdown(props) {
    const { languages, name, selectedLanguage, onLanguageChange, shouldShowInfo, title } = props;

    const { t } = useTranslation();

    // set default title for the language dropdown
    let dropdownTitle = "";
    if (!title) dropdownTitle = t("main.language");
    else dropdownTitle = title;

    // generalize languages shown as "EN" and "DE" instead of en-US, de-DE because
    // one of the English voices is en-UK
    const generalizedLanguages = languages?.map((lang) => {
        if (lang.includes("de")) return { name: "DE", value: lang };
        if (lang.includes("en")) return { name: "EN", value: lang };
        return { name: lang, value: lang };
    });

    const selectedLanguageValue = generalizedLanguages.filter(
        (g) => g.value === selectedLanguage
    )[0];

    return languages ? (
        <Stack>
            <Stack direction="row">
                <Typography sx={{ padding: "6px 0px 6px 0px" }} fontWeight="bold">
                    {dropdownTitle}
                </Typography>
                {shouldShowInfo ? (
                    <div style={{ display: "inline-flex" }}>
                        <CustomTooltip title={t("assistant.assistantLanguageNote")}>
                            <IconButton sx={{ color: "#4fc4a2" }}>
                                <InformationIcon fontSize="small" />
                            </IconButton>
                        </CustomTooltip>
                    </div>
                ) : null}
            </Stack>

            <CustomSelect
                items={generalizedLanguages}
                itemToOptionLabel={(item) => item.name}
                name={name}
                value={selectedLanguageValue}
                onSelected={onLanguageChange}
            />
        </Stack>
    ) : null;
}

export default LanguageDropdown;

LanguageDropdown.propTypes = {
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string,
    onLanguageChange: PropTypes.func.isRequired,
    selectedLanguage: PropTypes.string.isRequired,
    shouldShowInfo: PropTypes.bool,
    title: PropTypes.string,
};

LanguageDropdown.defaultProps = {
    name: "LanguageDropdown",
    shouldShowInfo: false,
    title: "",
};
