import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ComponentWrapper from "../../shared/components/ComponentWrapper";
import TTSMessenger from "./TTSMessenger";
import TTSSpectrogramContainer from "./TTSSpectrogramContainer";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import ComponentDropdownSelections from "../../shared/components/ComponentDropdownSelections";
import SpeedSlider from "../../shared/components/SpeedSlider";
import PrimaryButton from "../../shared/components/PrimaryButton";
import {
    getLanguage,
    getVoice,
    isServicesUpdated,
    onServiceChange,
} from "../../services/service-handling";
import usePrevious from "../../shared/custom-hooks";
import { ttsRequest } from "../../services/requests";
import { saveResponses } from "../../services/history";
import { componentServiceProps, DefaultEnvironmentName, envProps } from "../../shared/constants";

/**
 * TTS: Text To Speech
 * This React component send text to the server, processes the audio received
 * and plays it.
 */
function TTS(props) {
    const { accessibleEnvs, services } = props;

    const initEnv =
        accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0] ||
        accessibleEnvs[0];
    const initService = services?.[initEnv?.name]?.[0];
    const initLanguage = initService?.languages?.[0];
    const initVoice = initService?.voiceCollection?.find(
        (element) => element.language === initLanguage
    ).voices[0];

    const [messages, setMessages] = useState([]);
    const [selectedService, setSelectedService] = useState(initService);
    const [selectedLanguage, setSelectedLanguage] = useState(initLanguage);
    const [selectedVoice, setSelectedVoice] = useState(initVoice);
    const [selectedEnv, setSelectedEnv] = useState(initEnv);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorToastMessage, setErrorToastMessage] = useState("");
    const [shouldDisableInputControls, setShouldDisableInputControls] = useState(!initService);
    const [disableInputControlsTooltip, setDisableInputControlsTooltip] = useState("");
    const [audioResponses, setAudioResponses] = useState([]);
    const [speechRate, setSpeechRate] = useState(1.0);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isStopDisabled, setStopDisabled] = useState(true);
    const [audioPlayer, setAudioPlayer] = useState(null);

    const { t } = useTranslation();

    const prevServices = usePrevious(services);
    const prevAccessibleEnvs = usePrevious(accessibleEnvs);

    const haveServicesGotUpdated = prevServices
        ? isServicesUpdated(prevServices, services)
        : false;
    const haveAccessibleEnvsGotUpdated = prevAccessibleEnvs !== accessibleEnvs;

    // useEffect to run on load (componentDidMount)
    useEffect(() => {
        if (!selectedService) {
            // if no service is available then set defaults
            setSelectedService({});
            setSelectedLanguage("");
            setSelectedVoice("");
        }
    }, []);

    const setDefaultEnv = () => {
        const defEnv = accessibleEnvs.filter((env) => env.name === DefaultEnvironmentName)[0];
        setSelectedEnv(defEnv || accessibleEnvs[0]);
    };

    // set the default selected service
    const setDefaults = () => {
        if (selectedEnv) {
            const service = services[selectedEnv.name]?.[0] || {};
            const language = service?.languages?.[0] || "";
            const voice = service?.voiceCollection?.find((e) => e.language === language).voices[0];
            setSelectedLanguage(language);
            setSelectedService(service);
            setSelectedVoice(voice);
            setShouldDisableInputControls(Object.keys(service).length === 0);
        }
    };

    useEffect(() => {
        if (haveServicesGotUpdated) setDefaults();
        if (haveAccessibleEnvsGotUpdated) setDefaultEnv();
    }, [haveServicesGotUpdated, haveAccessibleEnvsGotUpdated]);

    const setAllDefaults = (env) => {
        // check if previously selected service's flavor exists in the new one
        const newServices = services[env.name];
        let newSelectedService = newServices?.[0] || {};
        let newSelectedLanguage = newSelectedService?.languages?.[0] || "";
        let newSelectedVoice = "";

        if (newServices?.length > 0) {
            newServices.forEach((service) => {
                if (service.flavor === selectedService.flavor) {
                    newSelectedService = service;
                    // if service.flavor exists, then check if that service has same language too
                    newSelectedLanguage = getLanguage(service.languages, selectedLanguage);
                }
            });
        }
        newSelectedVoice = getVoice(newSelectedService, newSelectedLanguage, selectedVoice);

        const isSelectedServiceInvalid = Object.keys(newSelectedService).length === 0;

        setSelectedEnv(env);
        setSelectedService(newSelectedService);
        setSelectedLanguage(newSelectedLanguage);
        setSelectedVoice(newSelectedVoice);
        setShowErrorToast(isSelectedServiceInvalid);
        setErrorToastMessage(
            isSelectedServiceInvalid
                ? t("toasts.noServicesFound", {
                      value: env?.name || "",
                  })
                : ""
        );
        setShouldDisableInputControls(isSelectedServiceInvalid);
    };

    /**
     * Function to update the state with the selected environment
     * @param {*} env - newly selected environment
     */
    const onEnvChange = (env) => {
        if (isStopDisabled) {
            setAllDefaults(env);
        } else {
            setShowErrorToast(true);
            setErrorToastMessage(t("toasts.invalidEnvChange"));
        }
    };

    const handleStop = () => {
        if (audioPlayer !== null) {
            audioPlayer.pause();
            setAudioUrl(null);
            setStopDisabled(true);
            setShouldDisableInputControls(false);
            setDisableInputControlsTooltip("");
        }
    };

    /**
     * Function to update the selected service and display a message to notify
     * the user about the change in service
     * @param {Number} newService the newly selected service
     */
    const onTTSServiceChange = (newService) => {
        if (isStopDisabled) {
            const response = onServiceChange(
                messages,
                selectedService,
                newService,
                messages.length,
                ""
            );

            const newLang = getLanguage(newService.languages, selectedLanguage);
            setSelectedService(newService);
            setMessages(response);
            setSelectedLanguage(newLang !== "" ? newLang : newService.languages?.[0] || "");
            setSelectedVoice(getVoice(newService, newLang, selectedVoice));
        } else {
            setShowErrorToast(true);
            setErrorToastMessage(t("toasts.invalidServiceChange"));
        }
    };

    /**
     * Function to update the state with the selected language
     * It also updates the voice based on the newly selected language
     * @param {*} newLanguage - object with name and value of the selected language
     */
    const onLanguageChange = (newLanguage) => {
        setSelectedLanguage(newLanguage.value);
        setSelectedVoice(getVoice(selectedService, newLanguage.value, selectedVoice));
    };

    const onVoiceChange = (newVoice) => {
        setSelectedVoice(newVoice);
    };

    const onHandleCloseSnackbar = () => {
        setShowErrorToast(false);
    };

    // Function to delete history and also all the saved audio and text in "view responses"
    const handleDeleteHistory = () => {
        setMessages([]);
        setAudioResponses([]);
    };

    /**
     * Function to send the user message to server
     * @param {String} text - text entered by the user
     * If a service is selected, then it is used to connect to
     * the service/namespace directly.
     */
    const sendMessage = (text) => {
        const msg = { sender: "user", text, id: messages.length };
        const copyOfMessages = [...messages];
        copyOfMessages.push(msg);
        setMessages(copyOfMessages);

        const player = new Audio(
            ttsRequest(
                text,
                selectedLanguage,
                selectedVoice,
                speechRate,
                selectedEnv.servicesEndpoint,
                selectedService.flavor
            )
        );

        // save the url and user text to view/play it later
        const updatedResponses = saveResponses(player.src, text, audioResponses);

        // event-listener for the audio to disable the stop button
        // and reset the url of the audio's source
        player.onended = () => {
            setAudioUrl(null);
            setStopDisabled(true);
            setShouldDisableInputControls(false);
            setDisableInputControlsTooltip("");
        };

        setAudioUrl(player.src);
        setAudioResponses(updatedResponses);

        const playPromise = player.play();

        // audio.play() returns a promise which is resolved after
        // a few milliseconds. When it gets resolved is when the stop button
        // is to be enabled.
        if (playPromise !== undefined) {
            playPromise
                .then(() => {
                    // console.log("Automatic playback started!");
                    setStopDisabled(false);
                    setShouldDisableInputControls(true);
                    setDisableInputControlsTooltip(t("toasts.speechSynthesisInProgress"));
                })
                .catch((error) => {
                    // console.log("Auto-play was prevented");
                    /* eslint-disable no-console */
                    console.log(error);
                    setShowErrorToast(true);
                    setErrorToastMessage(error.message);
                });
        }

        setAudioPlayer(player);
    };

    const getServicesRow = () => {
        return (
            <Stack direction={{ xs: "column", md: "row" }} spacing={1} alignItems="center">
                <Grid container spacing={1}>
                    <ComponentDropdownSelections
                        selectedService={selectedService}
                        selectedLanguage={selectedLanguage}
                        selectedVoice={selectedVoice}
                        onServiceChange={onTTSServiceChange}
                        onLanguageChange={onLanguageChange}
                        onVoiceChange={onVoiceChange}
                        name="TTS"
                        services={services[selectedEnv?.name]}
                        isVoiceNeeded
                        selectedEnv={selectedEnv}
                        onEnvChange={onEnvChange}
                        accessibleEnvs={accessibleEnvs}
                    />
                    <Grid item xs={12} sm={6} md={3}>
                        <Stack direction="row">
                            <Typography style={{ paddingTop: "5%" }} fontWeight="bold">
                                {t("main.speechRate")}:
                            </Typography>
                            <SpeedSlider onChange={(e) => setSpeechRate(e.target.value)} />
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        );
    };

    const getButtonsRow = () => {
        return (
            <Stack direction="row" spacing={1}>
                <PrimaryButton name="stop" onClick={handleStop} disabled={isStopDisabled}>
                    {t("main.stop")}
                </PrimaryButton>
            </Stack>
        );
    };

    return (
        <>
            <ComponentWrapper
                title="Text To Speech"
                leftMainCol={
                    <TTSMessenger
                        sendMessage={sendMessage}
                        messages={messages}
                        audioData={audioResponses}
                        shouldDisableInputControls={shouldDisableInputControls}
                        handleDeleteHistory={handleDeleteHistory}
                        disableInputControlsTooltip={disableInputControlsTooltip}
                    />
                }
                rightMainCol={<TTSSpectrogramContainer audioUrl={audioUrl} />}
            >
                {getServicesRow()}
                {getButtonsRow()}
            </ComponentWrapper>
            {/* add a hidden container for TTS audio response element */}
            <div id="audioResponseContainer" hidden />
            <CustomSnackbar
                open={showErrorToast}
                message={errorToastMessage}
                handleClose={onHandleCloseSnackbar}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        services: {
            // get a dictionary of TTS services across all the envs
            production: state.production.ttsServices,
            staging: state.staging.ttsServices,
            development: state.development.ttsServices,
            collab: state.collab.ttsServices,
            platform: state.platform.ttsServices,
        },
        accessibleEnvs: state.accessibleEnvs,
    };
}

TTS.propTypes = {
    services: componentServiceProps.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
};

export default connect(mapStateToProps)(TTS);
