import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function LoadingAnimationProgress() {
    return (
        <Stack
            style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                height: "100vh",
            }}
        >
            <Stack direction="row" spacing={4}>
                <CircularProgress
                    size="2.5rem"
                    thickness={3.6}
                    disableShrink
                    variant="indeterminate"
                />
            </Stack>
        </Stack>
    );
}

export default LoadingAnimationProgress;
