import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import keycloak from "./keycloak";
import "./i18n";
import store from "./store/index";

const initOptions = {
    responseMode: "fragment",
    flow: "standard",
    onLoad: "login-required",
};

keycloak
    .init(initOptions)
    .then((auth) => {
        if (!auth) {
            window.location.reload();
        } else {
            /* eslint-disable no-console */
            console.info("Authenticated");
        }

        const root = createRoot(document.getElementById("root"));
        root.render(
            <Provider store={store}>
                <App />
            </Provider>
        );
    })
    .catch((e) => {
        /* eslint-disable no-console */
        console.error("Authentication Failed");
        /* eslint-disable no-console */
        console.log(e);

        const root = createRoot(document.getElementById("root"));
        root.render(
            <div className="center">
                <h2>Authentication Failed</h2>
                <p>Please clear your cache and try again.</p>
                <p>On Windows and Linux: Ctrl+Shift+R</p>
                <p>On MacOS: Cmd+Shift+R</p>
            </div>
        );
    });
