import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/material";

import "../../styles/appStyle.css";
import ConfirmAlert from "../../shared/components/ConfirmAlert";
import KWSConfigurationTable from "./KWSConfigurationTable";
import { setKWSConfigDoNotAskAgain } from "../../store/action-creators";
import KWSTabs from "./KWSTabs";
import { kwsConfigTableProps } from "../../shared/constants";
import ConfigurationDialog from "../../shared/components/ConfigurationDialog";
import TertiaryButton from "../../shared/components/TertiaryButton";

/**
 * React component to display the configurations that can be done in KWS.
 * The options to configure are:
 * 1. Enroll an audio file with a keyword
 * 2. Record an audio of a keyword
 * 3. Add the phonetic of a keyword
 * It also displays a table consisting of all the enrolled keywords.
 */
function KWSConfiguration(props) {
    const {
        onDelete,
        onEnroll,
        onImport,
        enrollEndpoint,
        configTable,
        onCancelClick,
        showKwsConfig,
    } = props;
    const { t } = useTranslation();

    // use useSelector and useDispatch for functional components for Redux
    // if there aren't too many props
    const isDoNotAskAgain = useSelector((state) => state.isDoNotAskAgain);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(-1);

    /**
     * Function to delete the keyword at the given index.
     * If isDontAskAgainChecked is true, then directly delete,
     * else get a confirmation from the user before deleting
     * @param {*} index - index of the table at which the keyword has to be deleted
     */
    const handleOnDelete = (index) => {
        // if isDontAskAgainChecked is true then directly delete the keyword
        if (isDoNotAskAgain) {
            onDelete(index);
        } else {
            // else get a confirmation from the user
            setIndexToDelete(index);
            setOpen(true);
        }
    };

    const getActions = () => {
        return <TertiaryButton text={t("buttons.close")} onClick={onCancelClick} />;
    };
    return (
        <ConfigurationDialog
            isConfigDialogOpen={showKwsConfig}
            setConfigDialogOpen={onCancelClick}
            actions={getActions()}
            title={t("kws.configuration")}
        >
            <Stack spacing={8}>
                <KWSTabs
                    configTable={configTable}
                    enrollEndpoint={enrollEndpoint}
                    onEnroll={onEnroll}
                    onImport={onImport}
                />

                <KWSConfigurationTable configTable={configTable} onDelete={handleOnDelete} />
            </Stack>
            <ConfirmAlert
                open={open}
                onYes={() => onDelete(indexToDelete)}
                text={t("kws.areYouSure")}
                onClose={() => setOpen(false)}
                isDontAskAgainChecked={isDoNotAskAgain}
                toggleDontAskAgain={() => dispatch(setKWSConfigDoNotAskAgain(!isDoNotAskAgain))}
            />
        </ConfigurationDialog>
    );
}

export default KWSConfiguration;

KWSConfiguration.propTypes = {
    enrollEndpoint: PropTypes.string.isRequired,
    onEnroll: PropTypes.func.isRequired,
    configTable: kwsConfigTableProps.isRequired,
    onImport: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    showKwsConfig: PropTypes.bool.isRequired,
};
