import { forwardRef } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import CustomTooltip from "./CustomTooltip";

// need to use forwardRef so the tooltip version can work properly
export const LabeledCheckbox = forwardRef(function LabeledCheckbox(props, ref) {
    const { label } = props;
    const checkboxProps = { ...props };
    delete checkboxProps.label;
    return <FormControlLabel control={<Checkbox {...checkboxProps} ref={ref} />} label={label} />;
});

LabeledCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
};

/**
 * Functional component to display a checkbox.
 * It also supports tooltip functionality for the checkbox, if 'tooltipSrc' and 'tooltipText' are provided.
 * 'tooltipSrc' is a unique id for the tooltip and 'tooltipText' is the message to be displayed as a tooltip.
 */
export function LabeledCheckboxWithTooltip(props) {
    const { tooltipText } = props;
    const labeledProps = { ...props };
    delete labeledProps.tooltipText;
    return (
        <CustomTooltip title={tooltipText}>
            <LabeledCheckbox {...labeledProps} />
        </CustomTooltip>
    );
}

LabeledCheckboxWithTooltip.propTypes = {
    tooltipText: PropTypes.string.isRequired,
};

export default LabeledCheckboxWithTooltip;
