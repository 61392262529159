import React from "react";
import PropTypes from "prop-types";
import "../../styles/appStyle.css";
import AudioVisualiser from "./AudioVisualizer";

/**
 * React Component to analyze the audio input and in-turn call AudioVisualiser
 */
class AudioAnalyser extends React.Component {
    constructor() {
        super();
        this.state = { audioData: new Uint8Array(0) };
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        const { audio } = this.props;
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.analyser = this.audioContext.createAnalyser();
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.source = this.audioContext.createMediaStreamSource(audio);
        this.source.connect(this.analyser);
        this.rafId = requestAnimationFrame(this.tick);
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.rafId);
        this.analyser.disconnect();
        this.source.disconnect();
    }

    tick() {
        this.analyser.getByteTimeDomainData(this.dataArray);
        this.setState({ audioData: this.dataArray });
        this.rafId = requestAnimationFrame(this.tick);
    }

    render() {
        const { audioData } = this.state;
        const { width, height } = this.props;
        return (
            <AudioVisualiser
                audioData={audioData}
                containerWidth={width}
                containerHeight={height}
            />
        );
    }
}

export default AudioAnalyser;

AudioAnalyser.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    audio: PropTypes.instanceOf(MediaStream).isRequired,
};

AudioAnalyser.defaultProps = {
    height: "100px",
    width: "200px",
};
