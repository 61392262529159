/**
 * Create a base-64 encoded ASCII string from an arrayBuffer
 * @param {*} buffer
 * @returns base64 string
 */
function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

function createWavFileFromAudioBlob(audioBlob, name) {
    return new File([audioBlob], `${name}.wav`, { type: "audio/wav" });
}

/**
 * Measures the duration of the audio file at the given URL by creating
 * a temporary Audio object and loading the audio file from the URL.
 * @param {*} url - url of the audio
 * @returns a Promise of the audio duration
 */
function getAudioDuration(url) {
    const player = new Audio(url);

    return new Promise((resolve, reject) => {
        player.addEventListener(
            "durationchange",
            /* eslint-disable func-names */
            function () {
                if (this.duration !== Infinity) {
                    const { duration } = this;
                    player.remove();
                    resolve(duration);
                }
            },
            false
        );
        player.onerror = reject;

        player.load();
        // current time and volume are updated again so that the event is triggered again
        // else the duration would be Infinity and remain stuck there, this would
        // in turn not allow the callback function to be triggered
        player.currentTime = 24 * 60 * 60; // fake time
        player.volume = 0;
    });
}

/**
 * Read a file and return the content as base-64 encoded ASCII string.
 * @param {*} file url of the file
 * @returns base64 string
 */
const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = (e) => {
            resolve(arrayBufferToBase64(e.target.result));
        };

        reader.onerror = () => {
            /* eslint-disable no-console */
            reject(console.log("The file could not be read"));
        };
    });
};

export { createWavFileFromAudioBlob, getAudioDuration, readFileAsBase64 };
