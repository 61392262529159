import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import m1 from "../assets/avatar_icons/m1.svg";
import m2 from "../assets/avatar_icons/m2.svg";
import w1 from "../assets/avatar_icons/w1.svg";
import w2 from "../assets/avatar_icons/w2.svg";
import w3 from "../assets/avatar_icons/w3.svg";
import { fetchUserPreferences, updateUserAvatarPreferences } from "../services/requests";

const styles = {
    iconButton: {
        color: "#333333",
        "&:hover": {
            color: "#4fc4a2",
        },
    },
    avatar: {
        backgroundColor: "white",
        width: "24px",
        height: "24px",
        "&:hover": {
            border: "1px solid #4fc4a2",
        },
    },
    menuItem: {
        color: "#333333",
        fontSize: "16px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 1,
        "&:hover": {
            color: "#4fc4a2",
        },
    },
    imageList: {
        width: 300,
        height: 35,
        paddingLeft: 3,
        margin: 3,
    },
    imageListHeadline: {
        marginLeft: 3,
        marginTop: 2,
    },
    imageListItem: {
        width: 35,
        height: 35,
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        margin: 1,
        marginBottom: 2,
        minWidth: "6rem",
    },
};

// Component to display the account options like username and logout button.
function AccountOptions({ username, onLogout }) {
    const { t } = useTranslation();
    const [userPreferences, setUserPreferences] = useState({});
    const [imageListOpen, setImageListOpen] = useState(false);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState("");

    useEffect(() => {
        async function setPreference() {
            const userPreference = await fetchUserPreferences();
            setUserPreferences(userPreference);
        }
        setPreference();
    }, []);

    const itemData = [
        {
            img: m1,
            title: "man 1",
        },
        {
            img: m2,
            title: "man 2",
        },
        {
            img: w1,
            title: "woman 1",
        },
        {
            img: w2,
            title: "woman 2",
        },
        {
            img: w3,
            title: "woman 3",
        },
    ];

    const [anchorElement, setAnchorElement] = useState(null);
    const open = Boolean(anchorElement);

    const handleClick = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const handleClickAvatar = () => {
        handleClose();
        setImageListOpen(true);
    };

    const handleAvatarSelection = (event) => {
        event.preventDefault();
        const { img } = itemData[Number(event.currentTarget.children[0].id)];
        setSelectedAvatarImage(img);
    };

    const handleClickCancelButton = () => {
        setSelectedAvatarImage("");
        setImageListOpen(false);
    };

    const handleClickSaveButton = async () => {
        userPreferences.avatar = selectedAvatarImage;
        await updateUserAvatarPreferences(userPreferences);
        setSelectedAvatarImage("");
        setImageListOpen(false);
        setUserPreferences(await fetchUserPreferences());
    };

    const getAvatar = () => {
        return userPreferences.avatar !== "" ? (
            <Avatar sx={styles.avatar} src={userPreferences.avatar} />
        ) : (
            <AccountCircleIcon />
        );
    };

    return (
        <div key="accountOptions">
            <IconButton
                sx={styles.iconButton}
                onClick={handleClick}
                aria-label="account"
                title={t("main.openSettings")}
            >
                {getAvatar()}
            </IconButton>
            <Menu
                id="account-menu"
                aria-labelledby="account-menu"
                anchorEl={anchorElement}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <MenuItem
                    aria-label="Avatar"
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClickAvatar}
                >
                    <Stack direction="row" gap={3}>
                        {getAvatar()}
                        <span data-testid="username">{username}</span>
                    </Stack>
                </MenuItem>
                <hr />
                <MenuItem
                    sx={styles.menuItem}
                    aria-label="Logout"
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onLogout}
                >
                    <Stack direction="row" gap={2}>
                        <LogoutIcon />
                        {t("main.logout")}
                    </Stack>
                </MenuItem>
            </Menu>

            <Dialog open={imageListOpen}>
                <Typography sx={styles.imageListHeadline} variant="h6">
                    {t("chooseIcon")}
                </Typography>
                <ImageList sx={styles.imageList} cols={5}>
                    {itemData.map((item, index) => (
                        <ImageListItem
                            sx={styles.imageListItem}
                            key={item.img}
                            onClick={handleAvatarSelection}
                        >
                            <img
                                src={`${item.img}`}
                                srcSet={`${item.img}`}
                                id={`${index}`}
                                alt={`${item.title}`}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
                <Typography sx={styles.buttons}>
                    <Button
                        sx={styles.button}
                        variant="outlined"
                        onClick={handleClickCancelButton}
                    >
                        {t("buttons.cancel")}
                    </Button>
                    <Button
                        sx={styles.button}
                        disabled={selectedAvatarImage === ""}
                        variant="contained"
                        onClick={handleClickSaveButton}
                    >
                        {t("buttons.save")}
                    </Button>
                </Typography>
            </Dialog>
        </div>
    );
}

AccountOptions.propTypes = {
    username: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired,
};

export default AccountOptions;
