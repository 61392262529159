import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material";

/**
 * React functional component to display a dropdown.
 * @param {*} props - consists of dropdown values and onChange function.
 * @returns JSX of a dropdown.
 */
function CustomSelect(props) {
    const { items, itemToOptionLabel, name, value, onSelected, label } = props;

    const StyledSelect = styled(Select)(() => ({
        "& .MuiSelect-select": {
            border: items.length > 1 ? "1px solid black" : "",
        },
    }));

    const handleChange = (event) => {
        onSelected(event.target.value);
    };

    return (
        <FormControl fullWidth disabled={items.length <= 1}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <StyledSelect
                labelId={`${name}-label`}
                label={label}
                value={value}
                onChange={handleChange}
                name={name}
            >
                {items.map((item) => {
                    const labelText = itemToOptionLabel(item);
                    return (
                        <MenuItem data-cy={labelText} key={labelText} value={item}>
                            {labelText}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </FormControl>
    );
}

export default CustomSelect;

CustomSelect.propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
    itemToOptionLabel: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    onSelected: PropTypes.func.isRequired,
    label: PropTypes.string,
};

CustomSelect.defaultProps = {
    itemToOptionLabel: (item) => item,
    label: "",
};
