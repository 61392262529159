import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 *  React component to display an error snackbar
 * Note: currently only used to show error message when enumerate return empty list (NMPLAT-1089).
 * It can be extended or reused for other error messages in the future (NMPLAT-1127).
 */
function CustomSnackbar({ open, handleClose, message, autoHideDuration, severity }) {
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}

CustomSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    autoHideDuration: PropTypes.number,
    severity: PropTypes.oneOf(["error", "info"]),
};

CustomSnackbar.defaultProps = {
    severity: "error",
    autoHideDuration: null,
};

export default CustomSnackbar;
