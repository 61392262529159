import StopIcon from "@mui/icons-material/Stop";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import { useTranslation } from "react-i18next";
import "../../styles/appStyle.css";
import PropTypes from "prop-types";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AudioAnalyser from "./AudioAnalyser";
import CustomTooltip from "./CustomTooltip";
import tooltipStyle from "../../styles/tooltipStyle";

/**
 * React component that displays the buttons, start and stop, and an audio wave when start
 * is clicked or when the audio elements are processing.
 */
function DisplayAudioInteractions(props) {
    const buttonStyle = {
        backgroundColor: "#26a69a",
        color: "white",
        "&:hover": {
            backgroundColor: "#bdbdbd",
        },
        "&:disabled": {
            backgroundColor: "#bdbdbd",
            color: "white",
        },
    };

    const { isStartDisabled, isRecording, localStream, handleStart, handleStop, disableControls } =
        props;
    const { t } = useTranslation();

    const disableControlsTooltip = disableControls ? t("main.selectService") : "";

    return (
        <Tooltip
            PopperProps={{
                sx: tooltipStyle,
            }}
            title={disableControlsTooltip}
        >
            <Grid container spacing={2} sx={{ height: "80px" }}>
                <Grid item xs={6} sm={9} sx={{ padding: "8px 0px 0px 12px !important" }}>
                    {isStartDisabled && isRecording
                        ? localStream && (
                              <div style={{ marginLeft: "10%" }}>
                                  <AudioAnalyser audio={localStream} width="80%" height="80px" />
                              </div>
                          )
                        : null}
                </Grid>
                <Grid item xs={3} sm={1.5} sx={{ padding: "24px 0px 0px 12px !important" }}>
                    <CustomTooltip title={disableControls ? "" : t("main.stop")}>
                        <IconButton
                            disabled={!isStartDisabled || disableControls}
                            sx={buttonStyle}
                            onClick={handleStop}
                            name="stop"
                        >
                            <StopIcon />
                        </IconButton>
                    </CustomTooltip>
                </Grid>
                <Grid item xs={3} sm={1.5} sx={{ padding: "24px 0px 0px 12px !important" }}>
                    <CustomTooltip title={disableControls ? "" : t("main.start")}>
                        <IconButton
                            disabled={isStartDisabled || disableControls}
                            sx={buttonStyle}
                            onClick={handleStart}
                            name="start"
                        >
                            <MicNoneOutlinedIcon />
                        </IconButton>
                    </CustomTooltip>
                </Grid>
            </Grid>
        </Tooltip>
    );
}

export default DisplayAudioInteractions;

DisplayAudioInteractions.propTypes = {
    isStartDisabled: PropTypes.bool.isRequired, // check if audio processing is going on
    isRecording: PropTypes.bool.isRequired, // check if ASR specifically is going on
    localStream: PropTypes.instanceOf(MediaStream),
    handleStart: PropTypes.func.isRequired,
    handleStop: PropTypes.func.isRequired,
    disableControls: PropTypes.bool.isRequired,
};

DisplayAudioInteractions.defaultProps = {
    localStream: null,
};
