import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import ComponentCardHeader from "./ComponentCardHeader";
import KWSConfiguration from "../../KeywordSpotter/KWSConfiguration";
import KeywordDropdown from "../../../shared/components/KeywordDropdown";
import EnvironmentDropdown from "../../../shared/components/EnvironmentDropdown";
import { envProps, keywordProps, kwsConfigTableProps } from "../../../shared/constants";
import EndpointSelector from "../../../shared/components/EndpointSelector";

// React component to show the KWS settings in Assistant configurations.
function KWSSettings(props) {
    const [open, setOpen] = useState(false);
    const {
        onEndpointSelected,
        onPortChanged,
        accessibleEnvs,
        selectedEnv,
        onEnvChange,
        enrollEndpoint,
        onEnroll,
        configTable,
        onImport,
        onDelete,
        onKeywordChange,
        selectedKeyword,
    } = props;
    const [isConfigPaneOpen, setIsConfigPaneOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <Card sx={{ border: "1px solid", boxShadow: "none" }}>
            <ComponentCardHeader title="Keyword Spotter" open={open} setOpen={setOpen} />
            {open ? (
                <CardContent>
                    <Grid container spacing={2}>
                        {accessibleEnvs.length > 1 ? (
                            <Grid item xs={12} sm={6}>
                                <EnvironmentDropdown
                                    envs={accessibleEnvs}
                                    name="KWS_environment"
                                    selectedEnv={selectedEnv}
                                    onEnvChange={onEnvChange}
                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6}>
                            <KeywordDropdown
                                onKeywordChange={onKeywordChange}
                                selectedKeyword={selectedKeyword}
                                configTable={configTable}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{ marginTop: "10px" }} container spacing={2}>
                        <EndpointSelector
                            selectName="assistant_tab_kws"
                            onPortChanged={onPortChanged}
                            onEndpointSelected={onEndpointSelected}
                        />
                    </Grid>
                    <Grid sx={{ marginTop: "10px" }} container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            {/* empty left grid in the 3rd row */}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <IconButton
                                name="kws_settings"
                                onClick={() => setIsConfigPaneOpen(true)}
                            >
                                <TuneIcon sx={{ color: "black" }} />
                                <Typography sx={{ margin: "5px", color: "black" }}>
                                    {t("kws.configuration")}
                                </Typography>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <KWSConfiguration
                        onEnroll={(enrollment) => onEnroll(enrollment)}
                        enrollEndpoint={enrollEndpoint}
                        onDelete={onDelete}
                        configTable={configTable}
                        onImport={onImport}
                        onCancelClick={() => setIsConfigPaneOpen(false)}
                        showKwsConfig={isConfigPaneOpen}
                    />
                </CardContent>
            ) : null}
        </Card>
    );
}

KWSSettings.propTypes = {
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
    selectedEnv: envProps.isRequired,
    onEnvChange: PropTypes.func.isRequired,
    onPortChanged: PropTypes.func.isRequired,
    onEndpointSelected: PropTypes.func.isRequired,
    enrollEndpoint: PropTypes.string.isRequired,
    onEnroll: PropTypes.func.isRequired,
    configTable: kwsConfigTableProps.isRequired,
    onImport: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onKeywordChange: PropTypes.func.isRequired,
    selectedKeyword: keywordProps.isRequired,
};

export default KWSSettings;
