import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ConfigurationDialog from "../../shared/components/ConfigurationDialog";
import PrimaryButton from "../../shared/components/PrimaryButton";
import { a11yProps, TabPanel } from "../../shared/components/TabHelpers";
import TertiaryButton from "../../shared/components/TertiaryButton";
import RecordAudio from "../../shared/components/RecordAudio";
import UploadJSONFile from "../../shared/components/UploadJSONFile";
import CustomSnackbar from "../../shared/components/CustomSnackbar";
import { classificationSampleProps, speakerProps } from "../../shared/constants";
import UploadAudioFile from "../../shared/components/UploadAudioFile";

function NewSpeaker({
    speakers,
    showNewSpeaker,
    onCancelClick,
    onRegisterRecordedAudio,
    onRegisterUploadAudio,
    onRegisterUploadJSON,
    isClassification,
}) {
    const [value, setValue] = useState(0);
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };
    const [uploadedJSONFile, setUploadedJSONFile] = useState(null);
    const [uploadedSpeakerName, setUploadedSpeakerName] = useState("");
    const [uploadedAudioFile, setUploadedAudioFile] = useState(null);
    const [recordedSpeakerName, setRecordedSpeakerName] = useState("");
    const [recordedAudioInfo, setRecordedAudioInfo] = useState({ audioUrl: "", blob: "" });

    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showInfoToast, setShowInfoToast] = useState(false);
    const [errToastMessage, setErrToastMessage] = useState("");

    const { t } = useTranslation();

    const checkNameValidations = (speakerName) => {
        if (speakerName === "") {
            setShowErrorToast(true);
            setErrToastMessage(t("toasts.enterSpeakerName"));
            return false;
        }

        if (speakers.some((item) => item.name.toLowerCase() === speakerName.toLowerCase())) {
            setShowErrorToast(true);
            setErrToastMessage(t("toasts.duplicateSpeaker"));
            return false;
        }
        return true;
    };

    const checkIfAudioIsRecorded = () => {
        if (recordedAudioInfo.audioUrl === "" || recordedAudioInfo.blob === "") {
            setShowErrorToast(true);
            setErrToastMessage(t("toasts.recordAudio"));
            return false;
        }
        return true;
    };

    const checkIfFileIsUploaded = (isAudio) => {
        if ((isAudio && uploadedAudioFile === null) || (!isAudio && uploadedJSONFile === null)) {
            setShowErrorToast(true);
            setErrToastMessage(t("toasts.uploadFile"));
            return false;
        }
        return true;
    };

    const handleOnRegisterSpeaker = () => {
        if (value === 0) {
            // it is the record audio tab
            if (checkNameValidations(recordedSpeakerName) && checkIfAudioIsRecorded()) {
                const result = {
                    audio: recordedAudioInfo.audioUrl,
                    name: recordedSpeakerName,
                    origin: "recording",
                    audioData: recordedAudioInfo.blob,
                };
                onRegisterRecordedAudio(result);
                onCancelClick();
            }
        } else if (value === 1) {
            // it is the upload audio tab
            if (checkNameValidations(uploadedSpeakerName) && checkIfFileIsUploaded(true)) {
                onRegisterUploadAudio(uploadedSpeakerName, uploadedAudioFile);
                onCancelClick();
            }
        } else if (checkIfFileIsUploaded()) {
            onRegisterUploadJSON(uploadedJSONFile);
            onCancelClick();
        }
    };

    const getActions = () => {
        return (
            <>
                <TertiaryButton text={t("buttons.cancel")} onClick={onCancelClick} />
                <PrimaryButton onClick={handleOnRegisterSpeaker}>
                    {isClassification ? t("sr.save") : t("sr.registerSpeaker")}
                </PrimaryButton>
            </>
        );
    };

    const initialState = {
        fileUploadName: "",
        filenameKey: Date.now(),
    };
    const [state, setState] = useState(initialState);
    const [showToast, setShowToast] = useState(false);

    /**
     * Reset the entered information
     */
    const resetState = () => {
        setState({
            ...state,
            fileUploadName: "",
            filenameKey: Date.now(),
        });
    };
    /**
     * Upload audio file
     * @param {*} e event of the file upload
     * @param {*} multipleFiles flag to determine if multiple files are allowed to be uploaded
     */
    const onUpload = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        const numFiles = 1;

        for (let i = 0; i < numFiles; i++) {
            if (!e.target.files[i].type.startsWith("audio/")) {
                setShowToast(true);
                resetState();
                return;
            }
        }

        const firstFileName = e.target.files[0].name;
        const updatedName =
            firstFileName.substr(0, firstFileName.lastIndexOf(".")) || firstFileName;

        // if name already given, use that else use the uploaded file's name
        setState({ ...state, fileUploadName: updatedName });
        setUploadedSpeakerName(updatedName);

        // send the file to the parent
        setUploadedAudioFile(e.target.files[0]);
    };
    const { filenameKey, fileUploadName } = state;

    return (
        <>
            <ConfigurationDialog
                isConfigDialogOpen={showNewSpeaker}
                setConfigDialogOpen={onCancelClick}
                actions={getActions()}
                title={isClassification ? t("sr.newClassification") : t("sr.newSpeaker")}
            >
                <Box sx={{ width: "100%" }}>
                    <Box>
                        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
                            <Tab label={t("sr.recordAudioFile")} {...a11yProps(0)} />
                            <Tab label={t("sr.uploadAudioFile")} {...a11yProps(1)} />
                            {!isClassification && (
                                <Tab label={t("sr.uploadJSONFile")} {...a11yProps(2)} />
                            )}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <RecordAudio
                            name={recordedSpeakerName}
                            onNameChange={setRecordedSpeakerName}
                            onRecordAudio={setRecordedAudioInfo}
                            label={
                                isClassification ? t("sr.addSampleName") : t("sr.addSpeakerName")
                            }
                            audioLengthHint={t("sr.audioLengthHint")}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UploadAudioFile
                            name={uploadedSpeakerName}
                            onNameChange={setUploadedSpeakerName}
                            filenameKey={filenameKey}
                            fileUploadName={fileUploadName}
                            showToast={showToast}
                            setShowToast={setShowToast}
                            onUpload={onUpload}
                            label={
                                isClassification ? t("sr.addSampleName") : t("sr.addSpeakerName")
                            }
                        />
                    </TabPanel>
                    {!isClassification && (
                        <TabPanel value={value} index={2}>
                            <UploadJSONFile onUploadJSONFile={setUploadedJSONFile} />
                        </TabPanel>
                    )}
                </Box>
            </ConfigurationDialog>
            <CustomSnackbar
                open={showErrorToast}
                message={errToastMessage}
                handleClose={() => setShowErrorToast(false)}
                severity="error"
            />
            <CustomSnackbar
                open={showInfoToast}
                message={t("toasts.recordAudio")}
                handleClose={() => setShowInfoToast(false)}
                severity="info"
            />
        </>
    );
}

NewSpeaker.propTypes = {
    speakers: PropTypes.oneOfType([
        PropTypes.arrayOf(speakerProps),
        PropTypes.arrayOf(classificationSampleProps),
    ]).isRequired,
    showNewSpeaker: PropTypes.bool.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onRegisterRecordedAudio: PropTypes.func.isRequired,
    onRegisterUploadAudio: PropTypes.func.isRequired,
    onRegisterUploadJSON: PropTypes.func,
    isClassification: PropTypes.bool,
};

NewSpeaker.defaultProps = {
    isClassification: false,
    onRegisterUploadJSON: null,
};

export default NewSpeaker;
