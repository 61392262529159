import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../styles/appStyle.css";
import { useTranslation } from "react-i18next";
import MessageList from "../../shared/components/MessageList";
import SendMessageForm from "../../shared/components/SendMessageForm";
import CardPanel from "../../shared/components/CardPanel";
import { downloadHistory } from "../../services/history";
import CustomSnackbar from "../../shared/components/CustomSnackbar";

/**
 * React component to display the complete messenger section for DM
 */
function DMMessenger(props) {
    const { messages, sendMessage, shouldDisableInputControls, handleDeleteHistory } = props;
    const { t } = useTranslation();
    const [shouldShowEvents, setShouldShowEvents] = useState(true);
    const [messagesToShow, setMessagesToShow] = useState([]);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if (shouldShowEvents) {
            // should show events
            setMessagesToShow(messages);
        } else {
            // should not show events
            const x = messages.filter((m) => m.sender !== "dm-event");
            setMessagesToShow(x);
        }
    }, [messages]);

    // Function to download chat messages if they exist
    const handleDownloadHistory = () => {
        if (messages?.length > 0) downloadHistory(messages);
        else setShowToast(true);
    };

    /**
     * Function to toggle the bool "shouldShowEvents" and update the messages
     * to show or not show events based on bool value after toggle.
     */
    const handleToggleEvents = () => {
        const flag = !shouldShowEvents; // bool value after toggle.
        if (flag) {
            // should show events
            setMessagesToShow(messages);
        } else {
            // should not show events
            const x = messages.filter((m) => m.sender !== "dm-event");
            setMessagesToShow(x);
        }
        setShouldShowEvents(flag);
    };

    return (
        <>
            <CardPanel
                title={t("main.conversationHistory")}
                shouldShowDownload
                shouldShowEventsToggle
                handleDownload={handleDownloadHistory}
                footer={
                    <SendMessageForm
                        sendMessage={sendMessage}
                        messages={messages}
                        disabled={shouldDisableInputControls}
                    />
                }
                toggleEvents={handleToggleEvents}
                shouldShowEvents={shouldShowEvents}
                handleDeleteHistory={handleDeleteHistory}
                shouldDisableDelete={messages.length === 0}
            >
                <MessageList messages={messagesToShow} />
            </CardPanel>
            <CustomSnackbar
                open={showToast}
                message={t("toasts.noHistory")}
                handleClose={() => setShowToast(false)}
                severity="info"
            />
        </>
    );
}
export default DMMessenger;

DMMessenger.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.shape({
                id: PropTypes.number,
                sender: PropTypes.string,
                text: PropTypes.string,
            }),
        })
    ).isRequired,
    sendMessage: PropTypes.func.isRequired,
    shouldDisableInputControls: PropTypes.bool.isRequired,
    handleDeleteHistory: PropTypes.func.isRequired,
};
