import { IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import slugify from "react-slugify";
import PropTypes from "prop-types";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import CustomTooltip from "./CustomTooltip";
import { downloadAsWAV } from "../../services/history";

// Component to display play/stop and download buttons for an audio file
function CustomAudioPlayerWithDownload({ audioUrls, name }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioPlayer, setAudioPlayer] = useState(null);
    const { t } = useTranslation();

    const playSpeakerAudio = () => {
        const player = new Audio(audioUrls);
        setAudioPlayer(player);
        player.onended = () => {
            setIsPlaying(false);
        };
        player.play();
        setIsPlaying(true);
    };

    const stopSpeakerAudio = () => {
        if (audioPlayer !== null) {
            audioPlayer.pause();
            setAudioPlayer(null);
            setIsPlaying(false);
        }
    };

    const downloadSpeakerAudio = () => {
        const fileName = slugify(name.substring(0, 20));
        downloadAsWAV(fileName, audioUrls);
    };

    return (
        <>
            <CustomTooltip title={t("sr.download")}>
                <IconButton onClick={downloadSpeakerAudio} sx={{ padding: "0px" }}>
                    <FileDownloadOutlinedIcon />
                </IconButton>
            </CustomTooltip>
            {!isPlaying ? (
                <CustomTooltip title={t("sr.play")}>
                    <IconButton onClick={playSpeakerAudio} sx={{ padding: "0px" }}>
                        <PlayCircleOutlineOutlinedIcon />
                    </IconButton>
                </CustomTooltip>
            ) : (
                <CustomTooltip title={t("main.stop")}>
                    <IconButton onClick={stopSpeakerAudio} sx={{ padding: "0px" }}>
                        <StopCircleOutlinedIcon />
                    </IconButton>
                </CustomTooltip>
            )}
        </>
    );
}

CustomAudioPlayerWithDownload.propTypes = {
    audioUrls: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default CustomAudioPlayerWithDownload;
