import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import ServiceDropdown from "../../../shared/components/ServiceDropdown";
import ComponentCardHeader from "./ComponentCardHeader";
import { newServiceProps, componentServiceProps, envProps } from "../../../shared/constants";
import SpeedSlider from "../../../shared/components/SpeedSlider";
import VoiceDropdown from "../../../shared/components/VoiceDropdown";
import EnvironmentDropdown from "../../../shared/components/EnvironmentDropdown";

// React component to show the TTS settings in Assistant configurations.
function TTSSettings(props) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const {
        accessibleEnvs,
        selectedEnv,
        onEnvChange,
        services,
        selectedService,
        onServiceChange,
        voices,
        selectedVoice,
        onVoiceChange,
        isStartDisabled,
        setSpeechRate,
    } = props;
    return (
        <Card sx={{ border: "1px solid", boxShadow: "none" }}>
            <ComponentCardHeader title="Text To Speech" open={open} setOpen={setOpen} />
            {open ? (
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {accessibleEnvs.length > 1 ? (
                                <EnvironmentDropdown
                                    envs={accessibleEnvs}
                                    name="TTS_environment"
                                    selectedEnv={selectedEnv}
                                    onEnvChange={onEnvChange}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ServiceDropdown
                                onServiceChange={onServiceChange}
                                services={services[selectedEnv?.name]}
                                selectedService={selectedService}
                                name="assistant_tts"
                                customLabel="Service"
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{ marginTop: "10px" }} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {selectedVoice ? (
                                <VoiceDropdown
                                    onVoiceChange={onVoiceChange}
                                    selectedVoice={selectedVoice}
                                    name="assistant_voices"
                                    voices={voices}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <Typography sx={{ marginTop: "6px" }} fontWeight="bold">
                                    {t("main.speechRate")}
                                </Typography>
                                <SpeedSlider
                                    disabled={isStartDisabled}
                                    onChange={(e) => setSpeechRate(e.target.value)}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            ) : null}
        </Card>
    );
}

TTSSettings.propTypes = {
    services: componentServiceProps.isRequired,
    selectedService: newServiceProps,
    onServiceChange: PropTypes.func.isRequired,
    accessibleEnvs: PropTypes.arrayOf(envProps).isRequired,
    selectedEnv: envProps.isRequired,
    onEnvChange: PropTypes.func.isRequired,
    voices: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedVoice: PropTypes.string,
    onVoiceChange: PropTypes.func.isRequired,
    isStartDisabled: PropTypes.bool.isRequired,
    setSpeechRate: PropTypes.func.isRequired,
};

TTSSettings.defaultProps = {
    selectedService: null,
    selectedVoice: null,
};

export default TTSSettings;
