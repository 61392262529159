import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../../styles/appStyle.css";

/**
 * React Component to display the audio input visually on a canvas.
 */
function AudioVisualiser({ audioData, containerHeight, containerWidth }) {
    const canvas = React.createRef();

    const draw = () => {
        const currentCanvas = canvas.current;
        const { height } = currentCanvas;
        const { width } = currentCanvas;
        const context = currentCanvas.getContext("2d");
        let x = 0;
        const sliceWidth = (width * 1.0) / audioData.length;

        context.lineWidth = 2;
        context.strokeStyle = "#1e88e5";
        context.clearRect(0, 0, width, height);

        context.beginPath();
        context.moveTo(0, height / 2);
        audioData.forEach((item) => {
            const y = (item / 255.0) * height;
            context.lineTo(x, y);
            x += sliceWidth;
        });
        context.lineTo(x, height / 2);
        context.stroke();
    };

    useEffect(() => {
        draw();
    });

    return (
        <canvas
            style={{
                width: containerWidth,
                height: containerHeight,
                display: "inline-block",
            }}
            ref={canvas}
        />
    );
}

export default AudioVisualiser;

AudioVisualiser.propTypes = {
    audioData: PropTypes.instanceOf(Uint8Array).isRequired,
    containerWidth: PropTypes.string.isRequired,
    containerHeight: PropTypes.string.isRequired,
};
