import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

/**
 * React functional component for accepting and displaying a name for the keyword
 * @param {*} props - consists of the keyword name
 * @returns JSX of an editable text field with keyword name
 */
function NameInput({ id, value, onChange, label, fullWidth }) {
    return (
        <TextField
            type="text"
            label={label}
            id={id}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            fullWidth={fullWidth}
        />
    );
}

NameInput.defaultProps = {
    fullWidth: false,
};

NameInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
};

export default NameInput;
