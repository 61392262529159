import { Dialog, DialogActions, DialogContentText, Stack, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TertiaryButton from "./TertiaryButton";
import PrimaryButton from "./PrimaryButton";

// Component to display a confirmation message before deleting the history from the message box.
function DeleteHistoryNotification({ onDeleteClick, onCloseClick }) {
    const { t } = useTranslation();
    const notificationStyles = {
        dialog: {
            width: { xs: "21rem", xl: "25rem" },
            margin: 0,
            padding: "1rem",
            boxShadow: "none",
            backgroundColor: "rgba(245, 245, 245, 1)",
            borderRadius: 0,
        },
        description: {
            padding: "8px",
        },
    };

    return (
        <Dialog
            id="deleteHistory"
            open
            PaperProps={{ sx: notificationStyles.dialog }}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <Stack>
                <Stack direction="row" p={1} spacing={1} alignItems="center">
                    <WarningIcon sx={{ color: "#ffa501", width: "35px", height: "35px" }} />
                    <Typography
                        sx={{ color: "rgba(51, 51, 51, 0.87)" }}
                        alignItems="center"
                        variant="h6"
                        fontWeight={500}
                    >
                        {t("main.deleteHistoryConfirmationHeader")}
                    </Typography>
                </Stack>
                <DialogContentText
                    sx={notificationStyles.description}
                    id="error-dialog-description"
                >
                    {t("main.deleteHistoryConfirmationText")}
                </DialogContentText>

                <DialogActions sx={{ padding: 0 }}>
                    <TertiaryButton text={t("buttons.cancel")} onClick={onCloseClick} />
                    <PrimaryButton onClick={onDeleteClick} isErrorButton>
                        {t("buttons.delete")}
                    </PrimaryButton>
                </DialogActions>
            </Stack>
        </Dialog>
    );
}

DeleteHistoryNotification.propTypes = {
    onDeleteClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

export default DeleteHistoryNotification;
